import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Image, Responsive, Button,
  Loader,
} from 'semantic-ui-react';
import Carousel from '../common/components/carousel';
import ElementsCategories from './components/ElementsCategorie';
import BannerElement from './components/BannerElement';
import ScrollableBar from '../common/components/scrollable-bar';
import MainHeader from '../common/components/MainHeader';
import { handleBannerAction } from '../common/utils';
import CardProduct from '../common/components/card-product';
import service from './services';
import { getSize } from './utils';
import './style.css';

const BANNERWIDTH = 1920;
const BANNERHEIGHT = 708;
const BIG_BANNER_ID = 2;
const BRANDS_ID = 4;
const CATEGORIES_ID = 5;
const MORE_PROMOTIONALS_ID = 6;

const stylePromotionals = {
  marginRight: '1em',
  marginLeft: '1em',
  cursor: 'pointer',
  padding: '0',
};

const bannerElements = ({ banners, history }) => banners.map((banner, index) => {
  const {
    id, promotionName, tablet, web, action,
  } = banner;
  return {
    render: () => (
      <BannerElement
        key={id}
        position={index + 1}
        promotionName={promotionName}
        tablet={tablet}
        web={web}
        action={action}
        history={history}
      />
    ),
  };
});

class HomeComercio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device: 'computer',
      banners: [],
      mostSelled: [],
      promotionals: [],
      brands: [],
      categories: [],
      loaderMostSelled: true,
      notMostSelled: false,
    };
  }

  componentDidMount() {
    this.handleFetchAssets();
    this.sellerProducts();
  }

  handleFetchAssets = async () => {
    await service.getHomeComercioAssets()
      .then(response => {
        const newElements = bannerElements({ ...this.props, banners: response[BIG_BANNER_ID] || [] });
        const brands = response[BRANDS_ID] || [];
        const categories = response[CATEGORIES_ID] || [];
        const promotionalsBanners = response[MORE_PROMOTIONALS_ID] || [];
        this.promotionalsCarrousel(promotionalsBanners);
        this.setState({
          banners: newElements, categories, brands,
        });
      })
      .catch(() => { // console.log(error););
      });
  }

  handleOnUpdate = (e, { width }) => {
    const device = getSize(width);
    this.setState({ device });
  }

  sellerProducts = () => {
    const { history } = this.props;
    service.getMostSelledProducts()
      .then(response => {
        const { mostSelledProducts } = response;
        const mostSelled = mostSelledProducts.map(eachproduct => (
          {
            render: customClassName => (
              <CardProduct
                price={eachproduct.price}
                currencyPrice={eachproduct.currency}
                image={eachproduct.image}
                name={eachproduct.name}
                sku={eachproduct.sku}
                promotionPrice={eachproduct.promotionPrice}
                customClass={customClassName}
                history={history}
              />
            ),
          }
        ));
        this.setState({ mostSelled, loaderMostSelled: false });
      })
      .catch(() => {
        // console.log(error);
        this.setState({ notMostSelled: true });
      });
  };

  promotionalsCarrousel = promotionalsBanners => {
    if (promotionalsBanners.length > 0) {
      const promotionals = promotionalsBanners.map(promotional => {
        const { promotionName, web, id } = promotional;
        const { history } = this.props;
        return (
          {
            render: customClassName => (
              <Image
                key={id}
                size="medium"
                style={stylePromotionals}
                alt={promotionName}
                src={web}
                className={customClassName}
                onClick={() => handleBannerAction(promotional, history)}
              />
            ),
          }
        );
      });
      this.setState({ promotionals });
    } else {
      this.setState({ promotionals: [] });
    }
  };

  render() {
    const {
      device, promotionals, banners, brands, categories, mostSelled, loaderMostSelled, notMostSelled,
    } = this.state;
    const { history } = this.props;
    return (
      <Responsive as={Grid} padded fireOnMount onUpdate={this.handleOnUpdate}>
        <Grid.Row className="no-padding-y" centered>
          <Grid.Column className="no-padding-x" largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
            {
              banners.length > 0 ? (
                <Carousel
                  elements={banners}
                  duration={10000}
                  aspectRatio={BANNERWIDTH / BANNERHEIGHT}
                  showNextPrev
                  stopOnClick
                />
              ) : null
            }
          </Grid.Column>
        </Grid.Row>
        <MainHeader title="MARCAS" colorText="blueText" />
        <ElementsCategories device={device} elements={brands} elementType="brand" />
        <Grid.Row centered className="padding-btw-text">
          <Grid.Column
            largeScreen={6}
            computer={6}
            tablet={15}
            mobile={15}
            textAlign="center"
          >
            <Button
              color="blue"
              className="whiteText"
              size="medium"
              onClick={() => history.push('/marcas')}
            >
              Ver todas las marcas
            </Button>
          </Grid.Column>
        </Grid.Row>
        { loaderMostSelled
          ? (
            <Grid.Row>
              <Grid.Column
                largeScreen={16}
                computer={16}
                tablet={16}
                mobile={16}
                textAlign="center"
                className="no-padding"
              >
                <Loader active={loaderMostSelled} inline />
              </Grid.Column>
            </Grid.Row>
          )
          : (
            <>
              {!notMostSelled
                ? (
                  <>
                    <MainHeader title="LOS MÁS VENDIDOS" colorText="blueText" />
                    <Grid.Row centered className="padding-btw-text general-padding-children">
                      <Grid.Column
                        largeScreen={16}
                        computer={16}
                        tablet={16}
                        mobile={16}
                        textAlign="center"
                        className="no-padding"
                      >
                        <ScrollableBar showNextPrev elements={mostSelled} duration={3000} />
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : ''}
            </>
          )}
        <MainHeader title="CATEGORÍAS" colorText="blueText" />
        <ElementsCategories device={device} elements={categories} elementType="search" />
        { promotionals.length > 1 ? (
          <>
            <MainHeader title="PROMOCIONES" colorText="blueText" />
            <Grid.Row centered className="padding-btw-text general-padding-children">
              <Grid.Column
                largeScreen={16}
                computer={16}
                tablet={16}
                mobile={16}
                textAlign="center"
                className="no-padding"
              >
                <ScrollableBar showNextPrev elements={promotionals} duration={3000} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered className="padding-btw-text">
              <Grid.Column
                largeScreen={6}
                computer={6}
                tablet={15}
                mobile={15}
                textAlign="center"
              >
                <Button
                  color="blue"
                  className="whiteText"
                  size="medium"
                  onClick={() => window.open('https://www.compusoluciones.com/promociones-todas', '_blank')}
                >
                  Ver todas las promociones
                </Button>
              </Grid.Column>
            </Grid.Row>
          </>
        ) : '' }
        {/* <Modal
          onClose={() => this.setState({ openSurvey: false })}
          onOpen={() => this.setState({ openSurvey: true })}
          open={openSurvey}
          onClick={() => window.open('https://bit.ly/encuestasiclik', '_blank')}
        >
          <Modal.Content image>
            <Image
              fluid
              src={assetSurvey}
              wrapped
              style={{ cursor: 'pointer' }}
            />
          </Modal.Content>
        </Modal> */}
      </Responsive>
    );
  }
}

export default translate('common', { wait: true })(HomeComercio);
