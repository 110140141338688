import React, { Component } from 'react';
import { differenceBy } from 'lodash';
import {
  Header, Grid, Divider, List, Loader, Button, Message, Icon, Popup,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import moment from 'moment';
import ProcessMonitoring from './components/process-monitoring';
import DeliveryTraking from './components/delivery-tracking';
import ArticleList from './components/article-list';
import Attachments from './components/attachments';
import { currencyFormat } from '../common/utils';
import Toast from '../common/components/toast';
import orderService from './services';

class OrdersDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: { attachments: [] },
      loading: true,
      statusDelivery: '',
      orderId: props.match.params.orderId,
      isProcessNow: false,
      parcelData: null,
      courierService: '',
      validParcelsToTracking: ['UPS Seguro', 'UPS SAVER', 'Ups Saver Seguro', 'UPS Estandar', 'UPS TRES GUERRAS'],
      upsUrlTracking: 'https://www.ups.com/mobile/track?trackingNumber=',
    };
  }

  componentDidMount = () => {
    this.handleGetOrderDetail();
    this.handleGetCoupons();
  }

  handleGetOrderDetail = () => {
    const { orderDetails, orderId } = this.state;
    let { isProcessNow } = this.state;
    const { t } = this.props;
    orderService.getOrderDetail(orderId)
      .then(response => {
        const stepCompleted = response.steps.filter(element => element.isConcluded === 1);
        let lastStepCompleted = '';
        if (stepCompleted && stepCompleted.length > 0) lastStepCompleted = stepCompleted.length;
        if (response.steps && response.steps.length > 0) {
          orderDetails.steps = response.steps.map(currentStep => {
            const step = { ...currentStep };
            step.key = step.id;
            step.title = step.step;
            step.description = step.date;
            switch (step.id) {
              case 0: step.icon = 'spinner'; break;
              case 1: step.icon = 'spinner'; break;
              case 2: step.icon = 'clipboard list'; break;
              case 3: step.icon = 'clipboard check'; break;
              case 4: step.icon = 'warehouse'; break;
              case 5: step.icon = 'handshake'; break;
              default: step.icon = ''; break;
            }
            if (step.id === 5 && response.status === 'Concluido') step.isConcluded = 1;
            if (lastStepCompleted && step.id < stepCompleted[lastStepCompleted - 1].id) {
              step.completed = true;
            } else {
              step.completed = (step.isConcluded === 1);
            }
            return step;
          });
        }
        orderDetails.id = response.id;
        orderDetails.uuid = response.uuid;
        orderDetails.total = response.total;
        orderDetails.subtotal = response.amount;
        orderDetails.IVA = response.taxes;
        orderDetails.quotationName = response.quotation;
        orderDetails.agent = response.agent;
        orderDetails.status = response.status;
        orderDetails.endUser = response.endUser;
        orderDetails.branchOffice = response.branchOffice;
        if (response.date) orderDetails.date = response.date;
        orderDetails.applicant = response.applicant;
        orderDetails.quotationId = response.quotationId;
        orderDetails.paymentShape = response.paymentMethod;
        orderDetails.paymentCurrency = response.paymentCurrency;
        orderDetails.orderedArticle = response.items;
        orderDetails.attachments = response.attaches;
        orderDetails.movId = response.intelisisId;
        orderDetails.invoices = response.invoices;
        if (orderDetails.status && orderDetails.status === 'enviado') this.handleStatusDeliveryTraking();
        const now = moment(response.date);
        const expiration = moment();
        const diff = expiration.diff(now);
        const diffDuration = moment.duration(diff);
        isProcessNow = diffDuration.minutes() < 5;
        this.handleGetAttachments(orderDetails);
        this.setState({
          orderDetails, isProcessNow, loading: false, parcelData: response.parcelData, courierService: response.courierService,
        });
        this.handleGetCoupons(orderDetails);
      }).catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ loading: false, orderId });
        Toast(messageError, 'error');
      });
  }

  reversePaths = files => (
    files.map(file => ({
      ...file,
      serverUrl: file.url,
      url: file.serverUrl,
    }))
  )

  getAttachesByOrder = async orderId => {
    try {
      const { attaches } = await orderService.getAttachesByOrder(orderId);
      return this.reversePaths(attaches);
    } catch (error) {
      return [];
    }
  }

  handleGetAttachments = async paramOrderDetails => {
    const orderDetails = { ...paramOrderDetails };
    const { orderId } = this.state;
    const siclikAttachments = await this.getAttachesByOrder(orderId);
    const { attachments: orderAttachments } = orderDetails;
    const differentsAttachments = differenceBy(orderAttachments, siclikAttachments, 'url');
    orderDetails.attachments = [
      ...this.reversePaths(siclikAttachments),
      ...differentsAttachments,
    ];
    this.handleAddIconAttachment(orderDetails);
  }

  handleAddIconAttachment = paramOrderDetails => {
    const orderDetails = { ...paramOrderDetails };
    const files = orderDetails.attachments.map(file => {
      let fileFormated = file;
      switch (file.type) {
        case 'jpg': case 'png': case 'jpeg':
          fileFormated = { ...file, icon: 'file image outline' };
          break;
        case 'pdf':
          fileFormated = { ...file, icon: 'file pdf outline' };
          break;
        case 'docx': case 'docm': case 'dotx': case 'dotm':
          fileFormated = { ...file, icon: 'file word outline' };
          break;
        default:
          fileFormated = { ...file, icon: 'file outline' };
          break;
      }
      return fileFormated;
    });
    orderDetails.attachments = files;
    this.setState({ orderDetails });
  }

  cleanState = () => {
    this.setState({
      orderDetails: { attachments: [] },
      loading: true,
      statusDelivery: '',
      isProcessNow: false,
    });
  }

  handleGetProcessMonitoring = () => {
    this.cleanState();
    this.handleGetOrderDetail();
  }

  handleStatusDeliveryTraking = () => {
    let { statusDelivery } = this.state;
    const { orderId } = this.state;
    const { t } = this.props;
    orderService.getStatusDeliveryTraking(orderId)
      .then(response => {
        statusDelivery = response.status;
        this.setState({ statusDelivery });
      }).catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleGetCoupons = async paramOrderDetails => {
    const orderDetails = { ...paramOrderDetails };
    const { orderId } = this.state;
    let coupons = [];
    let itemsWithCoupons = [];
    if (orderId) coupons = await orderService.getCuponsByOrder(orderId).then(response => response.coupons);
    if (orderDetails && orderDetails.orderedArticle) {
      itemsWithCoupons = orderDetails.orderedArticle;
      if (coupons && coupons.length > 0 && itemsWithCoupons && itemsWithCoupons.length > 0) {
        itemsWithCoupons = itemsWithCoupons.map(item => {
          const couponDetails = coupons.filter(coupon => coupon.sku === item.sku && coupon.endPrice === item.price);
          const itemWithCoupon = { ...item };
          if (couponDetails && couponDetails.length > 0) {
            return {
              ...itemWithCoupon,
              originalPrice: Number(couponDetails[0].originalPrice),
              discount: Number(couponDetails[0].originalPrice) - Number(couponDetails[0].endPrice),
              coupon: couponDetails,
            };
          }
          return {
            ...itemWithCoupon,
            originalPrice: Number(item.price),
            discount: 0,
            coupon: '',
          };
        });
      }
      orderDetails.orderedArticle = itemsWithCoupons;
      this.setState({ orderDetails });
    }
  }

  isValidParcel = () => {
    const { courierService, validParcelsToTracking } = this.state;
    const isValid = validParcelsToTracking.some(courier => courier === courierService);
    return isValid;
  }

  render() {
    const { t, history } = this.props;
    const {
      orderDetails, loading, statusDelivery, isProcessNow, parcelData, courierService, upsUrlTracking,
    } = this.state;
    let currency = '';
    if (orderDetails.paymentCurrency === 'Dolar MS') currency = 'USD';
    else if (orderDetails.paymentCurrency === 'Dolares' || orderDetails.paymentCurrency === 'Dólares') currency = 'USD';
    else currency = 'MXN';
    const getTrackingUrl = guide => (`${upsUrlTracking}${guide}`);
    return (
      <Grid container style={{ paddingTop: '1rem' }}>
        <Grid.Row>
          <Grid.Column><Header color="blue" as="h1">{t('orderDetail.order')}</Header></Grid.Column>
        </Grid.Row>
        {loading ? (
          <Loader active inline="centered" />
        ) : (
          <Grid.Row columns={3}>
            <Grid.Column largeScreen={5} computer={4} tablet={4} mobile={7}>
              <List relaxed="very" size="large">
                {orderDetails.id ? (
                  <List.Item>
                    <List.Content>
                      <List.Description>
                        <b>{t('orderDetail.orderNumber')}</b>
                        {'  '}
                        {orderDetails.id}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : null}
                {orderDetails.movId ? (
                  <List.Item>
                    <List.Content>
                      <List.Description>
                        <b>{t('orderDetail.mov')}</b>
                        {'  '}
                        {orderDetails.movId}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : null}
                {orderDetails.status ? (
                  <List.Item>
                    <List.Content>
                      <List.Description>
                        <b>{t('orderDetail.status')}</b>
                        {'  '}
                        {orderDetails.status}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : null}
                {orderDetails.date ? (
                  <List.Item>
                    <List.Content>
                      <List.Description>
                        <b>{t('orderDetail.date')}</b>
                        {'  '}
                        {orderDetails.date ? moment(orderDetails.date).format('DD/MM/YYYY HH:mm:ss') : null}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : null}
              </List>
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={7} tablet={7} mobile={9}>
              <List relaxed="very" size="large">
                {orderDetails.invoices.length ? (
                  <List.Item>
                    <List.Content>
                      <List.Description>
                        <b>{t('orderDetail.invoiceNumber')}</b>
                        {'  '}
                        {orderDetails.invoices.map(invoice => invoice.invoiceId).join(' / ')}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : null}
                {orderDetails.quotationName ? (
                  <List.Item>
                    <List.Content>
                      <List.Description>
                        <b>{t('orderDetail.quotationName')}</b>
                        {'  '}
                        {orderDetails.quotationName}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : null}
                {orderDetails.endUser ? (
                  <List.Item>
                    <List.Content>
                      <List.Description>
                        <b>{t('orderDetail.endUser')}</b>
                        {'  '}
                        {orderDetails.endUser}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : null}
                {orderDetails.applicant ? (
                  <List.Item>
                    <List.Content>
                      <List.Description>
                        <b>{t('orderDetail.applicant')}</b>
                        {'  '}
                        {orderDetails.applicant}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : null}
                {orderDetails.branchOffice ? (
                  <List.Item>
                    <List.Content>
                      <List.Description>
                        <b>{t('orderDetail.address')}</b>
                        {'  '}
                        {orderDetails.branchOffice}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : null}
              </List>
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={4} tablet={4} mobile={10}>
              <List relaxed="very" size="large">
                {orderDetails.agent ? (
                  <List.Item>
                    <List.Content>
                      <List.Description>
                        <b>{t('orderDetail.agent')}</b>
                        {'  '}
                        {orderDetails.agent}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : null}
                {orderDetails.quotationId ? (
                  <List.Item>
                    <List.Content>
                      <List.Description>
                        <b>{t('orderDetail.quotationId')}</b>
                        {'  '}
                        {orderDetails.quotationId}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : null}
              </List>
            </Grid.Column>
          </Grid.Row>
        )}
        {orderDetails.status === 'Procesando' && !isProcessNow ? (
          <Grid.Row>
            <Message warning>
              <Message.Header>{t('orderDetail.messageHeaderErrorStatus')}</Message.Header>
              <p>{t('orderDetail.messageErrorStatus')}</p>
            </Message>
          </Grid.Row>
        ) : null}
        {orderDetails.steps && orderDetails.status !== 'Cancelado' ? (
          <Grid.Row>
            <Grid.Column largeScreen={15} computer={15} tablet={14} mobile={13}>
              <Header as="h4" color="blue">{t('orderDetail.processMonitoring')}</Header>
            </Grid.Column>
            <Grid.Column largeScreen={1} computer={1} tablet={2} mobile={2}>
              <Button size="large" onClick={this.handleGetProcessMonitoring} icon="redo alternate" />
            </Grid.Column>
          </Grid.Row>
        ) : null}
        {orderDetails.steps && orderDetails.status !== 'Cancelado' ? (
          <ProcessMonitoring steps={orderDetails.steps} />
        ) : null}
        {(courierService !== 'No Requiere Envio') && (parcelData !== null) ? (
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={8} computer={8} tablet={5} mobile={8}>
              <Header as="h4" color="blue">{t('orderDetail.deliveryTracking')}</Header>
              {courierService}
            </Grid.Column>
            {courierService === 'Cliente Recoge' ? null : (
              <Grid.Column largeScreen={8} computer={8} tablet={5} mobile={8}>
                <Header as="h4">{t('orderDetail.guideNumber')}</Header>
                {parcelData.guides.length <= 3 ? (
                  <List bulleted>
                    {parcelData.guides.map(option => (
                      <List.Item>
                        {this.isValidParcel() ? (<a href={getTrackingUrl(option.guide)} target="_blank" rel="noopener noreferrer">{option.guide}</a>) : option.guide}
                      </List.Item>
                    ))}
                  </List>
                ) : (
                  <Popup
                    trigger={<Button color="green" size="Tiny">{t('orderDetail.show')}</Button>}
                    flowing
                    hoverable
                  >
                    <List bulleted>
                      {parcelData.guides.map(option => (
                        <List.Item>
                          {this.isValidParcel() ? (<a href={getTrackingUrl(option.guide)} target="_blank" rel="noopener noreferrer">{option.guide}</a>) : option.guide}
                        </List.Item>
                      ))}
                    </List>
                  </Popup>
                )}
              </Grid.Column>
            )}
          </Grid.Row>
        ) : null}
        {statusDelivery ? (
          <DeliveryTraking tr={t} statusDelivery={statusDelivery} />
        ) : null}
        <Grid.Row columns={2}>
          {orderDetails.paymentShape ? (
            <Grid.Column largeScreen={5} computer={5} tablet={8} mobile={8}>
              <Header as="h4">{t('orderDetail.paymentShape')}</Header>
              {orderDetails.paymentShape}
            </Grid.Column>
          ) : null}
          {orderDetails.paymentCurrency ? (
            <Grid.Column largeScreen={3} computer={3} tablet={8} mobile={8}>
              <Header as="h4">{t('orderDetail.paymentCurrency')}</Header>
              {orderDetails.paymentCurrency}
            </Grid.Column>
          ) : null}
        </Grid.Row>
        <Divider />
        {orderDetails.attachments && orderDetails.attachments.length > 0 ? (
          <Grid.Row style={{ paddingBottom: 50 }}>
            <Grid.Column largeScreen={14} computer={13} tablet={13} mobile={12}>
              <Attachments files={orderDetails.attachments} />
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Divider />
        {orderDetails.orderedArticle && orderDetails.orderedArticle.length > 0 ? (
          <Grid.Row style={{ overflowX: 'auto' }}>
            <ArticleList articleArray={orderDetails.orderedArticle} currency={currency} />
          </Grid.Row>
        ) : null}
        {orderDetails.subtotal && orderDetails.orderedArticle && orderDetails.orderedArticle.length > 0 ? (
          <Grid.Row>
            <Grid.Column largeScreen={12} computer={12} tablet={13} mobile={12}>
              <Header textAlign="right" as="h4">{t('orderDetail.subtotal')}</Header>
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={4} textAlign="right">
              {currencyFormat(orderDetails.subtotal, currency)}
            </Grid.Column>
          </Grid.Row>
        ) : null}
        {orderDetails.IVA && orderDetails.orderedArticle && orderDetails.orderedArticle.length > 0 ? (
          <Grid.Row>
            <Grid.Column largeScreen={12} computer={12} tablet={13} mobile={12}>
              <Header textAlign="right" as="h4">{t('orderDetail.IVA')}</Header>
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={4} textAlign="right">
              {currencyFormat(orderDetails.IVA, currency)}
            </Grid.Column>
          </Grid.Row>
        ) : null}
        {orderDetails.total && orderDetails.orderedArticle && orderDetails.orderedArticle.length > 0 ? (
          <Grid.Row>
            <Grid.Column largeScreen={12} computer={12} tablet={13} mobile={12}>
              <Header textAlign="right" as="h4">{t('orderDetail.total')}</Header>
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={4} textAlign="right">
              {currencyFormat(orderDetails.total, currency)}
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Divider />
        <Grid.Row>
          <Grid.Column largeScreen={14} computer={13} tablet={12} mobile={8} />
          <Grid.Column>
            <Button onClick={() => history.goBack()} icon labelPosition="left">
              {t('back')}
              <Icon name="left arrow" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(OrdersDetail);
