import {
  getBrands, getBranches, getTotals,
} from './special-validation';
import { cloneArrayNoRef, analyticsEvent } from '../common/utils';

const REQUIRED_USD_CURRENCY = ['Microsoft', 'VEEAM, VMware', 'VMWARE'];
const REQUIRED_FORM_BRANDS = ['Microsoft', 'IBM', 'Trellix', 'Red Hat', 'VEEAM', 'Autodesk', 'VMware', 'VMWARE'];
const REQUIRED_FORM_BRANCHES = ['HP-CAREPACK', 'HPE-CAREPACK', 'ARUBA-SERVICES', 'APPLE-APPLECARE', 'AUTODESK', 'MFG-RENOVACION', 'MFG-LICENCIA', 'SP-SERVICIOS', 'ARUBA-CLOUD'];

export const initialState = {
  CFDIArray: [],
  CFDIError: '',
  access: '',
  actionLoader: false,
  agentPriceModal: false,
  attachments: [],
  availableCouriers: [],
  branchOffices: [],
  buyToMe: false,
  cfdi: 'G01',
  comment: '',
  commentError: '',
  contactAgentMessage: '',
  couponsApplied: [],
  courierServiceId: 0,
  creditAvailable: false,
  currency: 'MXN',
  currencyError: '',
  currentBranchOfficeIndex: '',
  customerId: '',
  customerName: '',
  deliveryConfig: {},
  deliveryError: '',
  discount: 0,
  endUserId: '',
  endUsers: [],
  endUsersOriginal: [],
  errorCode: 1,
  errorExist: false,
  exchangeRate: {},
  existingBrands: [],
  fileSelected: [],
  finalUserError: '',
  formError: '',
  formId: '',
  hasAgentPrice: false,
  items: [],
  itemsError: '',
  iva: 0,
  loader: true,
  needForm: false,
  noDeliveryProducts: false,
  openAgentModal: false,
  openAttach: false,
  openChatModal: false,
  openConfirm: false,
  openMultiBrand: false,
  openNameModal: false,
  orderId: '',
  hpeIquoteId: 0,
  payment: 3,
  paymentError: '',
  pickWarehouse: '',
  purchaseOrder: '',
  purchaseOrderError: '',
  quantityError: '',
  quotationName: '',
  shippingCost: 0,
  singleDelivery: true,
  stockId: '',
  subtotal: 0,
  total: 0,
  userId: '',
  userName: '',
  needUsdCurrency: false,
  steppedPrices: {},
  initialLoader: true,
  cartError: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case 'orderLoadDone': {
      const { order, loader } = action.payload;
      const {
        currency, exchangeRate, payment, shippingCost, stockId, steppedPrices,
      } = state;
      const {
        iva, subtotal, total, discount,
      } = getTotals(order.items, {
        shippingCost, currency, exchangeRate, payment,
      }, steppedPrices);
      const existingBrands = getBrands(order.items);
      const existingBrachs = getBranches(order.items);
      const needUsdCurrency = existingBrands.some(brand => (REQUIRED_USD_CURRENCY.indexOf(brand.name) !== -1));
      const needForm = existingBrands.some(brand => (REQUIRED_FORM_BRANDS.indexOf(brand.name) !== -1))
        || existingBrachs.some(branch => (REQUIRED_FORM_BRANCHES.indexOf(branch.name) !== -1));
      return {
        ...state,
        ...order,
        orderId: order.id,
        hpeIquoteId: order.hpeQuoteId,
        buyToMe: !!(stockId && order.endUserId === stockId),
        iva,
        subtotal,
        total,
        discount,
        attachments: !order.attaches ? [] : order.attaches,
        cfdi: !order.cfdi ? 'G01' : order.cfdi,
        loader,
        needForm,
        existingBrands,
        needUsdCurrency,
      };
    }
    case 'exchangeRate': {
      const { exchangeRate } = action.payload;
      const {
        currency, payment, shippingCost, items,
      } = state;
      const {
        iva, subtotal, total, discount,
      } = getTotals(items, {
        shippingCost, currency, exchangeRate, payment,
      });
      return {
        ...state,
        iva,
        subtotal,
        total,
        discount,
        exchangeRate,
      };
    }
    case 'errors': {
      const { fields, errorExist } = action.payload;
      return {
        ...state,
        ...fields,
        errorExist,
      };
    }
    case 'getTotals': {
      const { steppedPrices: paramPrices } = action.payload;
      const {
        currency, shippingCost, items, steppedPrices, exchangeRate, payment,
      } = state;
      const steppedPricesToUse = paramPrices || steppedPrices;
      const {
        iva, subtotal, total, discount,
      } = getTotals(items, {
        shippingCost, currency, exchangeRate, payment,
      }, steppedPricesToUse);
      return {
        ...state,
        iva,
        subtotal,
        total,
        discount,
      };
    }
    case 'orderLoadFail': {
      const { errorCode, loader } = action.payload;
      return { ...state, errorCode, loader };
    }
    case 'orderItems': {
      const { itemsSkus } = action.payload;
      const {
        currency, exchangeRate, payment, shippingCost, steppedPrices,
      } = state;
      const {
        iva, subtotal, total, discount,
      } = getTotals(itemsSkus, {
        shippingCost, currency, exchangeRate, payment,
      }, steppedPrices);
      return {
        ...state, items: itemsSkus, iva, subtotal, total, discount,
      };
    }
    case 'shippingCost': {
      const { shippingConfig } = action.payload;
      const {
        currency, exchangeRate, payment, items, steppedPrices,
      } = state;
      const {
        iva, subtotal, total, discount,
      } = getTotals(items, {
        shippingCost: shippingConfig.parcelPrice, currency, exchangeRate, payment,
      }, steppedPrices);
      return {
        ...state,
        shippingCost: shippingConfig.parcelPrice,
        courierServiceId: shippingConfig.parcel,
        addressId: shippingConfig.addressId,
        UPSinsure: shippingConfig.UPSinsure,
        origins: shippingConfig.origins,
        pickWarehouse: shippingConfig.warehouseCs,
        deliveryConfig: shippingConfig,
        iva,
        subtotal,
        total,
        discount,
      };
    }
    case 'fieldChange': {
      const { value, field } = action.payload;
      const customFields = {};
      customFields[field] = value;
      return { ...state, ...customFields };
    }
    case 'attachedAdd': {
      const { value } = action.payload;
      const { attachments } = state;
      attachments.push(value);
      return { attachments, ...state };
    }
    case 'deleteAttach': {
      const { id } = action.payload;
      const attachesNoRef = cloneArrayNoRef(state.attachments);
      const attachments = attachesNoRef.filter(attach => (attach.id !== id));
      return {
        ...state, attachments, openConfirm: false, fileSelected: [],
      };
    }
    case 'currencyChange': {
      const { value } = action.payload;
      const {
        exchangeRate, payment, shippingCost, items,
      } = state;
      const currency = value;
      const {
        iva, subtotal, total, discount,
      } = getTotals(items, {
        shippingCost, currency, exchangeRate, payment,
      });
      return {
        ...state,
        currency,
        iva,
        subtotal,
        total,
        discount,
      };
    }
    case 'paymentChange': {
      const { value } = action.payload;
      const currency = value === 3 ? 'MXN' : state.currency;
      const payment = value;
      const { exchangeRate, shippingCost, items } = state;
      const {
        iva, subtotal, total, discount,
      } = getTotals(items, {
        shippingCost, currency, exchangeRate, payment,
      });
      return {
        ...state,
        payment,
        currency,
        iva,
        subtotal,
        total,
        discount,
      };
    }
    case 'deleteSku': {
      const { sku, outlet } = action.payload;
      const {
        exchangeRate, payment, shippingCost, steppedPrices,
      } = state;
      let { currency } = state;
      const itemsNoRef = cloneArrayNoRef(state.items);
      const itemDeleted = itemsNoRef.find(item => (item.sku === sku && item.outlet === outlet));
      const items = itemsNoRef.filter(item => !(item.sku === sku && item.outlet === outlet));
      const existingBrands = getBrands(items);
      const existingBrachs = getBranches(items);
      const needUsdCurrency = existingBrands.some(brand => (['Microsoft', 'VEEAM', 'VMware', 'VMWARE', 'Red Hat'].indexOf(brand.name) !== -1));
      currency = payment === 3 && !needUsdCurrency ? 'MXN' : 'USD';
      const {
        iva, subtotal, total, discount,
      } = getTotals(items, {
        shippingCost, currency, exchangeRate, payment, steppedPrices,
      });
      const needForm = existingBrands.some(brand => (['Microsoft', 'IBM', 'Trellix', 'Red Hat', 'VEEAM', 'Autodesk', 'VMware', 'VMWARE'].indexOf(brand.name) !== -1))
        || existingBrachs.some(branch => (['HP-CAREPACK', 'HPE-CAREPACK', 'ARUBA-SERVICES', 'APPLE-APPLECARE', 'AUTODESK', 'MFG-RENOVACION', 'MFG-LICENCIA', 'SP-SERVICIOS', 'ARUBA-CLOUD'].indexOf(branch.name) !== -1));
      analyticsEvent('Comercio', 'Eliminar del carrito', itemDeleted.sku);
      return {
        ...state, items, existingBrands, needForm, iva, subtotal, total, discount, currency, needUsdCurrency,
      };
    }
    case 'cleanState': {
      return { ...action.payload };
    }
    default:
      throw new Error();
  }
}
