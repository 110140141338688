import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Header, Divider, Image, Icon, Popup, Menu, Statistic, Grid,
} from 'semantic-ui-react';
import { getUserImage } from '../../../common/utils';
import routeNames from '../../../route-names';
// import getTokenSuscribe from '../services';
import * as session from '../../../common/sessions';
// import constants from '../../constants';
import isotipoCesa from './images/isotipoCesa.png';
import service from '../services';

const urlAWS = process.env.REACT_APP_URL_AWS;
const assetUrl = `${urlAWS}/imagenes/assets/siclik/`;
const assetUrlComercio = `${urlAWS}/imagenes/assets/comercio/`;
const iquoteImage = `${assetUrl}isotipoIquote.png`;
const commerceImage = `${assetUrl}isotipoComercio.png`;
const suscribeImage = `${assetUrl}isotipoSuscribe.png`;
const isotipoCs = `${assetUrl}isotipoCs.png`;
const isoPromociones = `${assetUrl}isotipo_promociones.png`;
const isoUniversidadCs = `${assetUrlComercio}isotipo_universidad.png`;
const isoDirectoryAgents = `${assetUrlComercio}Agentes.png`;
const isoEventos = `${assetUrlComercio}isotipo_eventos.png`;
const isoVendedor = `${assetUrlComercio}vendedor_consentido.png`;
const isoIntegrador = `${assetUrlComercio}integrador_consentido.png`;

class PopMenu extends Component {
  constructor(props) {
    super(props);
    const token = session.get();
    this.state = {
      token,
      urlImgProfile: `${urlAWS}/imagenes/defaultAvatar.png`,
      segment: '',
    };
  }

  componentDidMount() {
    this.getAvatarImage();
    this.getSegment();
  }

  getSegment = async () => {
    const { token } = this.state;
    const { segment } = await service.getSegmentByCustomer(token.customerId);
    return segment.length === 0 ? this.setState({ segment: 'Select' }) : this.setState({ segment });
  }

  getAvatarImage = () => {
    const { token } = this.state;

    getUserImage(null, 'user-profile/pictures', token.userId)
      .then(urlImg => this.setState({ urlImgProfile: urlImg }));
  };

  goDashboard = (params = '') => {
    const { history } = this.props;
    history.push(`${routeNames.dashboard.route}${params}`);
  };

  goCesa = () => {
    window.open('https://cesa.siclik.mx/');
  };

  closePopup = () => {
  };

  goProfile = () => {
    const { history } = this.props;
    history.push(routeNames.profile.route);
  };

  goUsers = () => {
    const { history } = this.props;
    history.push(routeNames.addUser.route);
  };

  goBranchOffice = () => {
    const { history } = this.props;
    history.push(routeNames.deliveryAddress.route);
  };

  goFinalUser = () => {
    const { history } = this.props;
    history.push(routeNames.finalUser.route);
  };

  cross = () => {
    const { history } = this.props;
    history.push(routeNames.crossViewer.route);
  };

  iquote = () => {
    const { history } = this.props;
    history.push(routeNames.iquote.route);
  };

  appleDep = () => {
    const { history } = this.props;
    history.push(routeNames.appleDep.route);
  };

  goCommerce = () => {
    const { history } = this.props;
    history.push(routeNames.homeComercio.route);
  };

  goQuotations = () => {
    const { history } = this.props;
    history.push(routeNames.quotations.route);
  };

  goOrders = () => {
    const { history } = this.props;
    history.push(routeNames.orders.route);
  };

  goWishList = () => {
    const { history } = this.props;
    history.push(routeNames.wishList.route);
  };

  goSuscribe = async () => {
    // const tokenSuscribe = await getTokenSuscribe().then(response => response.token).catch(() => '');
    // window.location.replace(`${constants.URLSUSCRIBE}${tokenSuscribe}`);
    window.open('https://clicksuscribe.compusoluciones.com/#/Login', '_blank');
  };

  goBenefits = () => {
    const { history } = this.props;
    history.push(routeNames.dashboard.route);
  };

  goAgentDirectory = () => {
    window.open('https://www.siclik.mx/directorio-agentes', '_blank');
  };

  goEvents = () => {
    window.open('https://www.compusoluciones.com/eventos/', '_blank');
  };

  goPromotions = () => {
    window.open('https://www.compusoluciones.com/promociones/', '_blank');
  };

  goUniversityCS = () => {
    window.open('https://www.compusoluciones.com/universidad/', '_blank');
  };

  goVendedor = () => {
    window.open('https://vendedorconsentido.compusoluciones.com/dashboard', '_blank');
  };

  goIntegrador = () => {
    window.open('https://appsd.compusoluciones.com/csProgramas/', '_blank');
  };

  renderMenu = () => {
    const { t, role } = this.props;
    const { token, companyId, segment } = this.state;
    return (
      <Menu vertical fluid text>
        {companyId === 'G000000' ? (
          <div>
            <Header textAlign="left" as="h4">
              {t('menu.hello')}
              {token.userName}
              !
            </Header>
            <Header textAlign="left" as="h5" style={{ marginTop: '0' }}>{role}</Header>
            <Header textAlign="left" as="h5" style={{ marginTop: '0' }}>{token.customerName}</Header>

            <Header textAlign="left" as="h5" color="blue" style={{ marginBottom: '-12px' }}>{t('menu.settings')}</Header>
            <Divider inverted />

            <Menu.Item onClick={this.goProfile}>
              {' '}
              <Icon name="user" color="blue" />
              {' '}
              {t('menu.profile')}
            </Menu.Item>
            <Divider inverted />

            <Menu.Item onClick={() => session.logout(false)}>
              {' '}
              <Icon name="sign-out" color="yellow" />
              {' '}
              {t('menu.logout')}
            </Menu.Item>
          </div>
        )
          : (
            <div>
              <Header textAlign="left" as="h4">
                {t('menu.hello')}
                {token.userName}
                !
              </Header>
              <Header textAlign="left" as="h5" style={{ marginTop: '0' }}>{role}</Header>
              <Header textAlign="left" as="h5" style={{ marginTop: '0' }}>
                {token.customerName}
                (
                {token.customerId}
                )
              </Header>
              <Menu.Item
                className="hov-blue"
                onClick={this.goBenefits}
                style={{
                  fontFamily: 'Exo, sans-serif',
                  fontWeight: 'bold',
                  margin: '0',
                  padding: '0',
                }}
                title="Conoce tus beneficios"
              >
                Nivel:
                &nbsp;
                {segment}
                <Icon name="angle right icon" color="gray" />
              </Menu.Item>
              <Divider inverted />
              <Grid padded>
                <Grid.Row className="no-padding-bottom">
                  <Grid.Column className="no-padding" largeScreen={4} computer={4} tablet={4} mobile={16} textAlign="left">
                    <Header textAlign="left" as="h5" color="blue" style={{ marginBottom: '-16px' }}>{t('menu.settings')}</Header>
                    <Divider inverted />
                    <Menu.Item onClick={this.goProfile}>
                      {' '}
                      <Icon name="user" color="blue" />
                      {' '}
                      {t('menu.profile')}
                    </Menu.Item>
                    <Menu.Item onClick={this.goUsers}>
                      {' '}
                      <Icon name="key" color="blue" />
                      {' '}
                      {t('menu.user')}
                    </Menu.Item>
                  </Grid.Column>
                  <Grid.Column className="no-padding" largeScreen={4} computer={4} tablet={4} mobile={16} textAlign="left">
                    <Divider inverted />
                    <Menu.Item onClick={this.goBranchOffice}>
                      {' '}
                      <Icon name="building" color="blue" />
                      {' '}
                      {t('menu.branchOffice')}
                    </Menu.Item>
                    <Menu.Item onClick={this.goFinalUser}>
                      {' '}
                      <Icon name="users" color="blue" />
                      {' '}
                      {t('menu.finalUser')}
                    </Menu.Item>
                  </Grid.Column>
                  <Grid.Column className="no-padding" largeScreen={4} computer={4} tablet={4} mobile={16} textAlign="left">
                    <Header textAlign="left" as="h5" color="blue" style={{ marginBottom: '-16px' }}>{t('menu.admon')}</Header>
                    <Divider inverted />
                    <Menu.Item onClick={this.goQuotations}>
                      {' '}
                      <Icon name="file alternate" color="blue" />
                      {' '}
                      {t('menu.quotations')}
                    </Menu.Item>
                    <Menu.Item onClick={this.goOrders}>
                      {' '}
                      <Icon name="box" color="blue" />
                      {' '}
                      {t('menu.orders')}
                    </Menu.Item>
                  </Grid.Column>
                  <Grid.Column className="no-padding" largeScreen={4} computer={4} tablet={4} mobile={16} textAlign="left">
                    <Divider inverted />
                    <Menu.Item onClick={this.goWishList}>
                      {' '}
                      <Icon name="favorite" color="blue" />
                      {' '}
                      {t('menu.whislist')}
                    </Menu.Item>
                    <Menu.Item onClick={this.cross}>
                      {' '}
                      <Icon name="money bill alternate outline" color="blue" />
                      {' '}
                      {t('menu.cxc')}
                    </Menu.Item>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="no-padding-bottom">
                  <Grid.Column className="no-padding" largeScreen={3} computer={3} tablet={3} mobile={16} textAlign="left">
                    <Header textAlign="left" as="h5" color="blue" style={{ marginBottom: '-16px' }}>{t('menu.links')}</Header>
                    <Divider inverted />
                    <Menu.Item onClick={() => this.goDashboard()}>
                      {' '}
                      <Image className="siclik-Comercio" src={isotipoCs} size="mini" style={{ display: 'inline', width: '1.3rem', height: '1.3rem' }} />
                      {' '}
                      siclik
                    </Menu.Item>
                    <Menu.Item onClick={this.goCesa}>
                      {' '}
                      <Image className="siclik-Comercio" src={isotipoCesa} size="mini" style={{ display: 'inline', width: '1.5rem', height: '1.5rem' }} />
                      {' '}
                      siclik CESA
                    </Menu.Item>
                  </Grid.Column>
                  <Grid.Column className="no-padding" largeScreen={5} computer={5} tablet={5} mobile={16} textAlign="left">
                    <Divider inverted />
                    <Menu.Item onClick={this.goCommerce}>
                      {' '}
                      <Image className="siclik-Comercio" src={commerceImage} size="mini" style={{ display: 'inline', width: '1.5rem', height: '1.5rem' }} />
                      {' '}
                      {t('siclik Comercio')}
                    </Menu.Item>
                    <Menu.Item onClick={this.goSuscribe}>
                      {' '}
                      <Image className="siclik-Suscribe" src={suscribeImage} size="mini" style={{ display: 'inline', width: '1.5rem', height: '1.5rem' }} />
                      {' '}
                      {t('siclik Suscribe')}
                    </Menu.Item>
                  </Grid.Column>
                  <Grid.Column className="no-padding" largeScreen={4} computer={4} tablet={4} mobile={16} textAlign="left">
                    <Header textAlign="left" as="h5" color="blue" style={{ marginBottom: '-16px' }}>{t('menu.tools')}</Header>
                    <Divider inverted />
                    <Menu.Item onClick={() => this.goDashboard('?section=Directorio')}>
                      {' '}
                      <Image className="siclik-Comercio" src={isoDirectoryAgents} size="mini" style={{ display: 'inline', width: '1.5rem', height: '1.5rem' }} />
                      {' '}
                      Directorio
                    </Menu.Item>
                    <Menu.Item onClick={this.goEvents}>
                      {' '}
                      <Image className="siclik-Comercio" src={isoEventos} size="mini" style={{ display: 'inline', width: '20px', height: '20px' }} />
                      {' '}
                      Eventos
                    </Menu.Item>
                  </Grid.Column>
                  <Grid.Column className="no-padding" largeScreen={4} computer={4} tablet={4} mobile={16} textAlign="left">
                    <Divider inverted />
                    <Menu.Item onClick={this.goPromotions}>
                      {' '}
                      <Image className="siclik-Comercio" src={isoPromociones} size="mini" style={{ display: 'inline', width: '1.5rem', height: '1.5rem' }} />
                      {' '}
                      Promociones
                    </Menu.Item>
                    <Menu.Item onClick={this.goUniversityCS}>
                      {' '}
                      <Image className="siclik-Comercio" src={isoUniversidadCs} size="mini" style={{ display: 'inline', width: '20px', height: '20px' }} />
                      {' '}
                      Universidad
                    </Menu.Item>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="no-padding-bottom">
                  <Grid.Column className="no-padding" largeScreen={8} computer={8} tablet={8} mobile={16} textAlign="left">
                    <Header textAlign="left" as="h5" color="blue" style={{ marginBottom: '-12px' }}>{t('menu.programs')}</Header>
                    <Divider inverted className="no-margin-bottom" />
                    <Menu.Item onClick={this.goVendedor} style={{ whiteSpace: 'nowrap' }}>
                      {' '}
                      <Image className="siclik-Comercio" src={isoVendedor} size="mini" style={{ display: 'inline', width: '20px', height: '20px' }} />
                      {' '}
                      Vendedor ConSentido
                    </Menu.Item>
                    <Menu.Item onClick={this.goIntegrador} style={{ whiteSpace: 'nowrap' }}>
                      {' '}
                      <Image className="siclik-Comercio" src={isoIntegrador} size="mini" style={{ display: 'inline', width: '20px', height: '20px' }} />
                      {' '}
                      Integrador ConSentido
                    </Menu.Item>
                  </Grid.Column>
                  <Grid.Column className="no-padding" largeScreen={8} computer={8} tablet={8} mobile={16} textAlign="left">
                    <Header textAlign="left" as="h5" color="blue" style={{ marginBottom: '-12px' }}>{t('menu.others')}</Header>
                    <Divider inverted className="no-margin-bottom" />
                    <Menu.Item onClick={this.iquote}>
                      {' '}
                      <Image className="imageIquote" src={iquoteImage} size="mini" style={{ display: 'inline', width: '20px', height: '10px' }} />
                      {' '}
                      {t('iQuote')}
                    </Menu.Item>
                    <Menu.Item onClick={this.appleDep}>
                      {' '}
                      <Icon name="apple" color="blue" style={{ display: 'inline', width: '20px', height: '20px' }} />
                      {' '}
                      {t('Apple DEP')}
                    </Menu.Item>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="no-padding">
                  <Grid.Column width={16} className="no-padding">
                    <Divider inverted className="no-margin-bottom" />
                    <Menu.Item onClick={token.isGuest ? session.guestlogout : session.logout}>
                      {' '}
                      <Icon name="sign out" color="red" />
                      {' '}
                      {t('menu.logout')}
                    </Menu.Item>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )}
      </Menu>

    );
  }

  render() {
    const { token, urlImgProfile } = this.state;
    const { isGuest } = token;
    return (
      <Popup
        trigger={(
          <Statistic>
            <Statistic.Value>
              <Image src={urlImgProfile} style={{ fontSize: '1.4em', cursor: 'pointer' }} inline circular />
            </Statistic.Value>
            <Statistic.Label
              style={{ fontSize: '12px', marginTop: '-10px' }}
              content={isGuest ? 'Invitado' : ''}
            />
          </Statistic>
        )}
        content={this.renderMenu()}
        on="click"
        positionFixed="bottom right"
        className="menu-container"
        // hoverable
      />
    );
  }
}

PopMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  role: PropTypes.string,
  t: PropTypes.func.isRequired,
};

PopMenu.defaultProps = {
  role: '',
};

export default translate('common', { wait: true })(PopMenu);
