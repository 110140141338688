import 'react-semantic-toasts/styles/react-semantic-alert.css';
import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { SemanticToastContainer } from 'react-semantic-toasts';
import { Switch, withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';
import ReactGA4 from 'react-ga4';
import Toast from './common/components/toast';
import { PublicRoute, PrivateRoute } from './access-routes';
import AgentsLayout from './layouts/agents';
import Layout from './layouts/comercio';
import LayoutIA from './layouts/comercio-ia';
import LoginLayout from './layouts/login';
import LoginLayoutComercio from './layouts/login-comercio';
import PublicLayout from './layouts/public';
import OnBoardingLayout from './layouts/onboarding';
import DashboardLayout from './layouts/siclik';
import BlankLayout from './layouts/blank';
import Login from './login';
import LoginCS from './login-cs';
import Reset from './password-reset';
import HomeComercio from './home-comercio';
import ProductCatalog from './product-catalog';
import ProductCatalogIa from './product-catalog-ia';
import BrandsComercio from './brands-comercio';
import Profile from './profile';
import Wellcome from './welcome';
import routes from './route-names';
import Dashboard from './dashboard';
import CrossViewer from './cross-viewer';
import PDFViewer from './pdf-viewer';
import HPEViewer from './HPE-iframe';
import ProductDetails from './product-details';
import ShoppingCart from './shopping-cart';
import AddUser from './user';
import AddBranchOffice from './delivery-address';
import DeliveryAddress from './add-delivery-address';
import FinalUser from './final-user';
import Discount from './discount-view';
import DiscountAndGroups from './discount-and-groups';
import Orders from './orders-main';
import OrdersAgent from './orders-main-agent';
import OrderDetails from './orders-detail';
import stepsService from './layouts/onboarding/services';
import Quotations from './quotations-main';
import QuotationsAgent from './quotations-main-agent';
import QuotationDetail from './quotation-detail';
import OpenPay from './openpay-form';
import Checkout from './checkout';
import Iquote from './iquote';
import LogisticsAgent from './logistics-agent';
import DownloadBilling from './download-billing';
import CustomerDirectory from './customer-directory';
import CustomerPowerBi from './customer-powerbi';
import AdminConfiguration from './admin-configuration';
import FodaForm from './cross-selling';
import FamilyAttributes from './family-attributes';
import FlexAttachList from './flex-attach-list';
import ClientPlan from './client-plan';
import BusinessPlan from './business-plan';
import Agents from './agents';
import AppleDep from './apple-dep';
import LeaseQuote from './lease-quote';
import WishList from './wish-list';
import AgentsDirectory from './agents-directory';
import Payment from './payment-redirect';
import CompareProducts from './compare-products';
import IntelligenceDashboard from './intelligence-dashboard';
import TestApisSiclik from './customer-directory/components/service-accounts/PageTestApis';
import * as session from './common/sessions';
// import { analyticsEvent } from './common/utils';

import fixClassComponent from './context/class-component';

export const initGA = () => {
  // const { userId } = session.get();
  ReactGA4.initialize(process.env.REACT_APP_GA4_TRACKINGID);
  // if (userId) {
  //   analyticsEvent('Authentication', 'user-id available', userId);
  // }
};

const getSteps = async customerId => {
  const filteredSteps = await stepsService()
    .then(({ onboardingSteps }) => {
      let steps = onboardingSteps.map(({
        id, step, url, isConcluded, icon,
      }) => ({
        key: id, title: step, href: url, completed: isConcluded, icon,
      }));
      if (customerId === 'G000000') {
        steps = steps.filter(step => step.key < 3);
        steps.push({
          key: 6, title: 'Iniciar experiencia', href: '/cotizaciones/agente', completed: false,
        });
      } else {
        steps.push({
          key: 6, title: 'Iniciar experiencia', href: '/tablero', completed: false,
        });
      }
      if (steps[0].completed === false || steps[1].completed === false) {
        steps = steps.map(step => {
          if (step.key > 1) return ({ ...step, disabled: true });
          return step;
        });
      }
      return steps;
    })
    .catch(() => ([]));
  return filteredSteps;
};

const routeMatch = (pathname, routePath) => matchPath(pathname, {
  path: routePath,
  exact: true,
  strict: false,
});

function getCurrentRouteDetails(pathname) {
  const currentRouteKey = Object.keys(routes).find(key => routeMatch(pathname, routes[key].route));
  return routes[currentRouteKey];
}

function validateToken(pathname) {
  const route = getCurrentRouteDetails(pathname);
  if ((route && route.isPublic) || session.get().isAuthenticated) return ({ isAuthenticated: true, verificationDone: true, route });
  return ({ isAuthenticated: false, verificationDone: true, route });
}

const goToQuestionPro = () => {
  const url = 'https://www.questionpro.com/t/AQSoBZnKGa';
  window.open(url, '_blanck', '_self');
};

function App({ t, location }) {
  const userSession = useRef(session.get());
  const [isAuthenticated, setIsAuthenticated] = useState(userSession.current.isAuthenticated);
  const [steps, setSteps] = useState([]);
  const [verificationDone, setVerificationDone] = useState(true);
  const [questionProTimerIsRunning, setQuestionProTimerIsRunning] = useState(false);
  const [currentRoute, setCurrentRoute] = useState(getCurrentRouteDetails(location.pathname));

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    setVerificationDone(false);
    const { pathname } = location;
    const { isAuthenticated: isAuth, verificationDone: verification, route } = validateToken(pathname);
    setIsAuthenticated(isAuth);
    setVerificationDone(verification);
    setCurrentRoute(route);
  }, [location]);

  useEffect(() => {
    if (isAuthenticated && !currentRoute?.isPublic) {
      const { customerId } = session.get();
      getSteps(customerId).then(stepsList => setSteps(stepsList));
    }
    setSteps([]);
  }, [isAuthenticated, currentRoute]);

  useEffect(() => {
    if (isAuthenticated && !questionProTimerIsRunning) {
      const icon = 'clipboard outline';
      const title = t('questionPro.csatSurveyTitle');
      const description = t('questionPro.csatSurveyDescription');
      setQuestionProTimerIsRunning(true);
      const timer = setTimeout(() => { Toast(description, 'info', icon, title, 25000, null, goToQuestionPro); }, 480000);
      return () => clearTimeout(timer);
    }
    return () => { };
  }, [questionProTimerIsRunning, isAuthenticated, t]);

  const updateSteps = stepToUpdate => {
    const newSteps = steps.map(step => {
      if (step.key === stepToUpdate.key) return ({ ...step, completed: stepToUpdate.completed });
      if (Number(stepToUpdate.key) === 2) return ({ ...step, disabled: false });
      return step;
    });
    setSteps(newSteps);
  };

  const renderBlankLayout = ({ component: ComponentToRender, props }) => {
    const { location: propLocation, history, match } = props;
    return (
      <BlankLayout match={match} location={propLocation} history={history} isAuthenticated={isAuthenticated} routes={routes}>
        <ComponentToRender match={match} location={propLocation} history={history} routes={routes} />
      </BlankLayout>
    );
  };

  const renderLayout = ({ component: ComponentToRender, props }) => {
    const { location: propLocation, history, match } = props;
    return (
      <Layout match={match} location={propLocation} history={history} isAuthenticated={isAuthenticated} routes={routes}>
        <ComponentToRender match={match} location={propLocation} history={history} routes={routes} />
      </Layout>
    );
  };
  const renderLayoutIA = ({ component: ComponentToRender, props }) => {
    const { location: propLocation, history, match } = props;
    return (
      <LayoutIA match={match} location={propLocation} history={history} isAuthenticated={isAuthenticated} routes={routes}>
        <ComponentToRender match={match} location={propLocation} history={history} routes={routes} />
      </LayoutIA>
    );
  };

  const renderAgentsLayout = ({ component: ComponentToRender, props }) => {
    const { location: propLocation, history, match } = props;
    return (
      <AgentsLayout match={match} location={propLocation} history={history} isAuthenticated={isAuthenticated} routes={routes}>
        <ComponentToRender match={match} location={propLocation} history={history} routes={routes} />
      </AgentsLayout>
    );
  };

  const renderLoginLayout = ({ component: ComponentToRender, props }) => {
    const { location: propLocation, history, match } = props;
    return (
      <LoginLayout match={match} location={propLocation} history={history} routes={routes}>
        <ComponentToRender match={match} location={propLocation} history={history} routes={routes} />
      </LoginLayout>
    );
  };

  const renderLoginLayoutComercio = ({ component: ComponentToRender, props }) => {
    const { location: propLocation, history, match } = props;
    return (
      <LoginLayoutComercio match={match} location={propLocation} history={history} routes={routes}>
        <ComponentToRender match={match} location={propLocation} history={history} routes={routes} />
      </LoginLayoutComercio>
    );
  };

  const renderPublicLayout = ({ component: ComponentToRender, props }) => {
    const {
      location: propLocation, history, match,
    } = props;
    return (
      <PublicLayout match={match} t={t} location={propLocation} history={history} isAuthenticated={isAuthenticated} routes={routes}>
        <ComponentToRender match={match} location={propLocation} history={history} routes={routes} />
      </PublicLayout>
    );
  };

  const renderOnBoardingLayout = ({ component: ComponentToRender, props }, path) => {
    const { location: propLocation, history, match } = props;
    return (
      <OnBoardingLayout match={match} location={propLocation} history={history} isAuthenticated={isAuthenticated} router={routes}>
        <ComponentToRender
          match={match}
          location={location}
          history={history}
          routes={routes}
          path={path}
          updateStep={updateSteps}
          steps={steps}
        />
      </OnBoardingLayout>
    );
  };

  const renderDashboardLayout = ({ component: ComponentToRender, props }, showApps = true) => {
    const { location: propLocation, history, match } = props;
    return (
      <DashboardLayout
        match={match}
        location={location}
        history={history}
        isAuthenticated={isAuthenticated}
        showApps={showApps}
        router={routes}
      >
        <ComponentToRender match={match} location={propLocation} history={history} routes={routes} />
      </DashboardLayout>
    );
  };

  return (
    verificationDone ? (
      <div>
        <Switch>
          <PublicRoute
            path={routes.loginCS.route}
            component={LoginCS}
            renderLayout={renderLoginLayout}
          />
          <PublicRoute
            path={routes.login.route}
            component={Login}
            renderLayout={renderLoginLayoutComercio}
          />
          <PublicRoute
            exact
            path={routes.reset.route}
            component={Reset}
            renderLayout={renderLoginLayoutComercio}
          />
          <PublicRoute
            exact
            path={routes.agentDirectory.route}
            component={AgentsDirectory}
            renderLayout={renderPublicLayout}
          />
          <PublicRoute
            path={routes.HPEViewer.route}
            component={HPEViewer}
            renderLayout={renderPublicLayout}
          />
          <PublicRoute
            path={routes.pdfViewer.route}
            component={PDFViewer}
            renderLayout={renderPublicLayout}
          />
          <PrivateRoute
            exact
            accessRole={routes.downloadBilling.permission}
            path={routes.downloadBilling.route}
            component={DownloadBilling}
            renderLayout={renderBlankLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.downloadBilling.redirect}
          />
          <PrivateRoute
            exact
            accessRole={routes.homeComercio.permission}
            path={routes.homeComercio.route}
            component={HomeComercio}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.homeComercio.redirect}
          />
          <PrivateRoute
            exact
            accessRole={routes.brandsComercio.permission}
            path={routes.brandsComercio.route}
            component={BrandsComercio}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.brandsComercio.redirect}
          />
          <PrivateRoute
            exact
            accessRole={routes.products.permission}
            path={routes.products.route}
            component={ProductCatalog}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.products.redirect}
          />
          <PrivateRoute
            exact
            accessRole={routes.productsIa.permission}
            path={routes.productsIa.route}
            component={ProductCatalogIa}
            renderLayout={renderLayoutIA}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.productsIa.redirect}
          />
          <PrivateRoute
            exact
            accessRole={routes.products.permission}
            path={routes.stockProducts.route}
            component={ProductCatalog}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.products.redirect}
          />
          <PrivateRoute
            accessRole={routes.compareProducts.permission}
            path={routes.compareProducts.route}
            component={fixClassComponent(CompareProducts)}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.compareProducts.redirect}
          />
          <PrivateRoute
            exact
            path={routes.shoppingCart.route}
            component={ShoppingCart}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.shoppingCart.redirect}
          />
          <PrivateRoute
            path={routes.discount.route}
            component={Discount}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.discount.redirect}
          />
          <PrivateRoute
            path={routes.discountDetails.route}
            component={DiscountAndGroups}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.discountDetails.redirect}
          />
          <PrivateRoute
            exact
            path={routes.quotations.route}
            component={fixClassComponent(Quotations)}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.quotations.redirect}
          />
          <PrivateRoute
            exact
            path={routes.orders.route}
            component={Orders}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.orders.redirect}
          />
          <PrivateRoute
            exact
            path={routes.orderDetails.route}
            component={OrderDetails}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.orders.redirect}
          />
          <PrivateRoute
            exact
            accessRole={routes.profile.permission}
            path={routes.profile.route}
            component={Profile}
            renderLayout={params => renderOnBoardingLayout(params, 2)}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.profile.redirect}
          />
          <PrivateRoute
            exact
            accessRole={routes.addUser.permission}
            path={routes.addUser.route}
            component={AddUser}
            renderLayout={params => renderOnBoardingLayout(params, 3)}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.addUser.redirect}
          />
          <PrivateRoute
            exact
            accessRole={routes.onBoarding.permission}
            path={routes.onBoarding.route}
            component={Wellcome}
            renderLayout={params => renderOnBoardingLayout(params, 1)}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.onBoarding.redirect}
          />
          <PrivateRoute
            exact
            accessRole={routes.addBranchOffice.permission}
            path={routes.addBranchOffice.route}
            component={AddBranchOffice}
            renderLayout={params => renderOnBoardingLayout(params, 5)}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.addBranchOffice.redirect}
          />
          <PrivateRoute
            exact
            accessRole={routes.deliveryAddress.permission}
            path={routes.deliveryAddress.route}
            component={DeliveryAddress}
            renderLayout={params => renderOnBoardingLayout(params, 5)}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.deliveryAddress.redirect}
          />
          <PrivateRoute
            accessRole={routes.finalUser.permission}
            path={routes.finalUser.route}
            component={FinalUser}
            renderLayout={params => renderOnBoardingLayout(params, 4)}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.finalUser.redirect}
          />
          <PrivateRoute
            accessRole={routes.dashboard.permission}
            path={routes.dashboard.route}
            component={Dashboard}
            renderLayout={renderDashboardLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.dashboard.redirect}
          />
          <PrivateRoute
            accessRole={routes.crossViewer.permission}
            path={routes.crossViewer.route}
            component={CrossViewer}
            renderLayout={params => renderDashboardLayout(params, false)}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.crossViewer.redirect}
          />
          <PrivateRoute
            accessRole={routes.iquote.permission}
            path={routes.iquote.route}
            component={Iquote}
            renderLayout={params => renderDashboardLayout(params, false)}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.iquote.redirect}
          />
          <PrivateRoute
            accessRole={routes.productDetails.permission}
            path={routes.productDetails.route}
            component={fixClassComponent(ProductDetails)}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.productDetails.redirect}
          />
          <PrivateRoute
            accessRole={routes.openPay.permission}
            path={routes.openPay.route}
            component={OpenPay}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.openPay.redirect}
          />
          <PrivateRoute
            accessRole={routes.checkout.permission}
            path={routes.checkout.route}
            component={fixClassComponent(Checkout)}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.checkout.redirect}
          />
          <PrivateRoute
            accessRole={routes.payment.permission}
            path={routes.payment.route}
            component={Payment}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.payment.redirect}
          />
          <PrivateRoute
            accessRole={routes.leaseOptions.permission}
            path={routes.leaseOptions.route}
            component={fixClassComponent(LeaseQuote)}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.leaseOptions.redirect}
          />
          <PrivateRoute
            accessRole={routes.logisticsAgent.permission}
            path={routes.logisticsAgent.route}
            component={LogisticsAgent}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.logisticsAgent.redirect}
          />
          <PrivateRoute
            exact
            path={routes.quotationsAgent.route}
            component={QuotationsAgent}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.quotationsAgent.redirect}
          />
          <PrivateRoute
            exact
            path={routes.orderDetailsAgent.route}
            component={OrderDetails}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.quotationsAgent.redirect}
          />
          <PrivateRoute
            exact
            path={routes.ordersAgent.route}
            component={OrdersAgent}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.ordersAgent.redirect}
          />
          <PrivateRoute
            exact
            path={routes.quotationDetailAgent.route}
            component={QuotationDetail}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.quotationsAgent.redirect}
          />
          <PrivateRoute
            exact
            path={routes.quotationDetail.route}
            component={QuotationDetail}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.quotations.redirect}
          />
          <PrivateRoute
            exact
            path={routes.customerDirectory.route}
            component={CustomerDirectory}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.customerDirectory.redirect}
          />
          <PrivateRoute
            exact
            path={routes.powerBiDirectory.route}
            component={CustomerPowerBi}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.powerBiDirectory.redirect}
          />
          <PrivateRoute
            exact
            path={routes.intelligenceDashboard.route}
            component={IntelligenceDashboard}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.intelligenceDashboard.redirect}
          />
          <PrivateRoute
            exact
            path={routes.adminConfiguration.route}
            component={AdminConfiguration}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.adminConfiguration.redirect}
          />
          <PrivateRoute
            exact
            path={routes.agentBusinessPlan.route}
            component={BusinessPlan}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.agentBusinessPlan.redirect}
          />
          <PrivateRoute
            exact
            path={routes.fodaForm.route}
            component={FodaForm}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.fodaForm.redirect}
          />
          <PrivateRoute
            exact
            path={routes.familyAttributes.route}
            component={FamilyAttributes}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.familyAttributes.redirect}
          />
          <PrivateRoute
            exact
            path={routes.agents.route}
            component={Agents}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.agents.redirect}
          />
          <PrivateRoute
            exact
            path={routes.flexAttachList.route}
            component={FlexAttachList}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.flexAttachList.redirect}
          />
          <PrivateRoute
            exact
            path={routes.clientPlan.route}
            component={ClientPlan}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.clientPlan.redirect}
          />
          <PrivateRoute
            exact
            path={routes.appleDep.route}
            component={AppleDep}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.appleDep.redirect}
          />
          <PrivateRoute
            exact
            path={routes.wishList.route}
            component={WishList}
            renderLayout={renderLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.wishList.redirect}
          />
          <PrivateRoute
            exact
            path={routes.testApisSiclik.route}
            component={TestApisSiclik}
            renderLayout={renderAgentsLayout}
            isAuthenticated={isAuthenticated}
            redirectTo={routes.testApisSiclik.redirect}
          />
          <PublicRoute
            component={LoginCS}
            renderLayout={renderPublicLayout}
          />
        </Switch>
        <SemanticToastContainer position="top-left" />
      </div>
    )
      : null
  );
}

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default translate('common', { wait: true })(withRouter(App));
