/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { hasAccess } from '../common/permission';
import * as session from '../common/sessions';
import routes from '../route-names';

export const PrivateRoute = ({
  component, accessRole, renderLayout, redirectTo, ...rest
}) => {
  const [userSession, setUserSession] = useState(false);

  async function validateUserSession() {
    if (session.get().isAuthenticated) {
      setUserSession(session.get());
    } else setUserSession({ isAuthenticated: false });
  }

  function hasValidSession() {
    return userSession && userSession.isAuthenticated;
  }

  function isFetchingSession() {
    return !userSession;
  }

  function loadScreen() {
    return '';
  }

  function goLoginScreen(location) {
    return <Redirect to={{ pathname: redirectTo, state: { from: location } }} />;
  }

  function goHome(location) {
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }

  useEffect(() => {
    validateUserSession();
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        switch (true) {
          case hasValidSession():
            if (hasAccess(accessRole)) {
              return renderLayout({ component, props, isAuthenticated: true });
            }
            return goHome(props.location);
          case isFetchingSession():
            return loadScreen();
          default:
            return goLoginScreen(props.location);
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  accessRole: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  renderLayout: PropTypes.func.isRequired,
  location: PropTypes.shape({}),
  redirectTo: PropTypes.string,
};

PrivateRoute.defaultProps = {
  accessRole: '',
  location: null,
  redirectTo: '/',
};

export const PublicRoute = ({ component, renderLayout, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (props.location.pathname === '/auth/siclik/callback') {
        const isDevMode = window.location.hostname === 'localhost';
        const success = session.replaceSiclikLoginCookies(isDevMode);
        if (!success) {
          return <Redirect to={{ pathname: `${routes.loginCS.route}?error=invalid_ck` }} />;
        }
      }
      const currentSession = session.get();
      if (currentSession.isAuthenticated) {
        if (currentSession.customerId === 'G000000') {
          return <Redirect to={{ pathname: routes.quotationsAgent.route }} />;
        }
        if (!currentSession.acceptedTerms) {
          return <Redirect to={{ pathname: routes.onBoarding.route }} />;
        }
        if (!currentSession.profileComplete) {
          return <Redirect to={{ pathname: routes.profile.route }} />;
        }
        return <Redirect to={{ pathname: routes.homeComercio.route }} />;
      }
      return renderLayout({ component, props });
    }}
  />
);

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  renderLayout: PropTypes.func.isRequired,
};
