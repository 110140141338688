import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import cookie from 'js-cookie';
import { translate } from 'react-i18next';
import {
  Grid, Button, Dimmer, Loader,
} from 'semantic-ui-react';
import validator from 'validator';
import { callError } from '@compusoluciones-pdigitales/status-codes';
import discountService from './services';
import Toast from '../common/components/toast';
import Description from './components/description';
import Scope from './components/scope';
import Conditions from './components/conditions';
import * as session from '../common/sessions';

const invalidRebate = errorCode => errorCode === callError.products.INVALID_REBATE || errorCode === callError.products.INSUFFICIENT_REBATE_FOUNDS;

class DiscountDetail extends Component {
  constructor(props) {
    const { id } = props;
    super(props);
    this.state = {
      id,
      code: '',
      codeError: '',
      description: '',
      descriptionError: '',
      disabledDate: false,
      startDate: '',
      endDate: '',
      startDateError: '',
      endDateError: '',
      type: 1,
      quantity: '',
      quantityError: '',
      behavior: 2,
      checkedCombinied: false,
      limit: '',
      limitError: '',
      scopeOptions: [],
      placeholderScope: '',
      clientId: '',
      clientError: '',
      arrayConditions: [],
      loadingSave: false,
      idScope: '',
      arrayValues: [],
      nameHeaders: [
        { key: 'id', isKey: true },
        { key: 'conditionId', isKey: true },
        { key: 'condicion', name: 'Campo' },
        {
          key: 'value',
          name: 'Valor',
          format: (cell, row) => {
            if (row.conditionId === 11) {
              const { arrayGroups } = this.state;
              const nameGroup = arrayGroups.filter(groupItem => Number(groupItem.key) === Number(cell))[0];
              return nameGroup && nameGroup.text ? nameGroup.text : cell;
            }
            return cell;
          },
        },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button color="blue" icon="pencil alternate" onClick={() => this.handleClickUpdate(row)} />
              <Button color="blue" icon="trash alternate" onClick={() => this.handleClickDelete(row)} />
            </Grid.Row>
          ),
        },
      ],
      arrayTypes: [
        { key: 4, value: 4, text: 'SKU' },
        { key: 5, value: 5, text: 'Familia' },
        { key: 6, value: 6, text: 'Rama' },
        { key: 7, value: 7, text: 'Línea' },
        { key: 11, value: 11, text: 'Grupo de Articulos' },
      ],
      optionsCurrency: [
        { key: 1, value: 1, text: 'Pesos (MXN)' },
        { key: 2, value: 2, text: 'Dolares (USD)' },
      ],
      condition: '',
      conditionError: '',
      conditionText: '',
      groupsList: [],
      open: false,
      allowAdd: false,
      listValues: {
        branch: [], family: [], group: [], line: [], product: [],
      },
      value: '',
      valueError: '',
      ruleId: '',
      arrayScope: [{}],
      disabledLimit: true,
      behaviorTypes: [],
      discountTypes: [],
      optionDays: [],
      comment: '',
      commentError: '',
      scope: '',
      currency: 1,
      currencyError: '',
      checkedStock: true,
      maker: '',
      makerId: '',
      makerError: '',
      loading: false,
      loadingDetail: false,
      optionsMaker: [],
      creditCustomersList: [],
      arrayGroups: [],
      minimumAmount: '',
      minimumAmountError: '',
      minimumAmountId: '',
      loadingField: false,
      makerConditionId: '',
      isAdmin: false,
    };
  }

  componentDidMount() {
    const { id } = this.state;
    this.handleGetUsersCouponsAdmins();
    this.handleGetDiscountProperties();
    this.handleGetClients();
    this.handleGetGroups();
    this.handleGetListApplyOn();
    this.handleGetDiscountDays();
    this.handleGetCreditCustomers();
    this.handleGetGroupsItems();
    if (!id) this.handleGetMakers();
    if (id) this.handleGetDiscountConditions();
  }

  handleGetUsersCouponsAdmins = async () => {
    const { t } = this.props;
    const { userId } = session.get();
    let messageError = '';
    const { users } = await discountService.getUsersCoupons()
      .catch(error => {
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (users) {
      const filterUsers = users.filter(user => user.id === userId);
      const isAdmin = filterUsers && filterUsers.length > 0;
      this.setState({ isAdmin });
    }
  }

  handleGetDiscountProperties = async () => {
    const { id } = this.state;
    const behaviorTypes = await this.handleGetBehaviorTypes();
    const discountTypes = await this.handleGetTypes();
    if (id) this.handleGetDiscount(behaviorTypes, discountTypes);
    this.setState({ behaviorTypes, discountTypes });
  }

  handleGetBehaviorTypes = async () => {
    const { t } = this.props;
    let segmentDiscount = [];
    let messageError = '';

    const arrayBehavior = await discountService.getBehaviorTypes()
      .catch(error => {
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (arrayBehavior && arrayBehavior.segmentDiscount) {
      segmentDiscount = arrayBehavior.segmentDiscount;
      segmentDiscount.map(currentSegment => {
        const segment = { ...currentSegment };
        if (Number(segment.id === 2)) segment.checked = true;
        else segment.checked = false;
        return segment;
      });
      segmentDiscount = segmentDiscount.filter(segmentType => segmentType.id < 3);
    }
    return segmentDiscount;
  }

  handleGetTypes = async () => {
    const { t } = this.props;
    const { isAdmin } = this.state;
    let messageError = '';
    let discountMode = [];
    const discountTypes = await discountService.getDiscountTypes()
      .catch(error => {
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (discountTypes && discountTypes.discountMode) {
      discountMode = discountTypes.discountMode.map(currentMode => {
        const mode = { ...currentMode };
        if (Number(mode.id === 1)) mode.checked = true;
        else mode.checked = false;
        return mode;
      });
    }
    if (!isAdmin) discountMode = discountMode.filter(mode => mode.id !== 4);
    return discountMode;
  }

  handleGetDiscountDays = async () => {
    const { t } = this.props;
    let messageError = '';
    let discountDays = [];
    const discountDaysObj = await discountService.getDiscountDays()
      .catch(error => {
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (discountDaysObj && discountDaysObj.discountDays) {
      discountDays = discountDaysObj.discountDays.map(currentOptionDay => {
        const optionDay = { ...currentOptionDay };
        optionDay.value = optionDay.id;
        optionDay.text = optionDay.description;
        delete optionDay.description;
        return optionDay;
      });
    }
    this.setState({ optionDays: discountDays });
    return discountDays;
  }

  handleGetMakers = async () => {
    const { t } = this.props;
    let messageError = '';
    let optionsMaker = [];
    const objectMaker = await discountService.getAllMakers()
      .catch(error => {
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (objectMaker && objectMaker.makers) {
      optionsMaker = objectMaker.makers.map(maker => {
        const formatedMaker = { key: maker.id, value: maker.id, text: maker.name };
        return formatedMaker;
      });
      optionsMaker.sort((a, b) => a.text.localeCompare(b.text));
      optionsMaker.unshift({ key: 'whitoutMaker', value: 'whitoutMaker', text: t('discounts.whitoutMaker') });
    }
    this.setState({ optionsMaker });
    return optionsMaker;
  }

  handleGetDiscount = (behaviorTypesParam, discountTypesParam) => {
    const { id } = this.state;
    let discountTypes = [...discountTypesParam];
    let behaviorTypes = [...behaviorTypesParam];
    let {
      checkedCombinied, startDate, endDate, activeStartDate, activeEndDate, disabledDate,
    } = this.state;
    this.setState({ loading: true });
    discountService.getDiscountById(id).then(response => {
      discountTypes = discountTypes.map(currentDiscountType => {
        const discountType = { ...currentDiscountType };
        if (Number(discountType.id) === Number(response.idType)) discountType.checked = true;
        else discountType.checked = false;
        return discountType;
      });
      if (response.cumulative === 1) checkedCombinied = true;
      else checkedCombinied = false;
      if (response.startValidity) {
        startDate = moment(response.startValidity).format('YYYY-MM-DD');
        activeStartDate = true;
      }
      if (response.endValidity) {
        endDate = moment(response.endValidity).format('YYYY-MM-DD');
        activeEndDate = true;
      }
      if (response.idType && (response.idType === 3 || response.idType === 4)) {
        behaviorTypes = behaviorTypes.map(currentBehaviorType => {
          const behaviorType = { ...currentBehaviorType };
          if (Number(behaviorType.id) === Number(response.idSegment)) behaviorType.checked = true;
          else behaviorType.checked = false;
          behaviorType.disabled = true;
          return behaviorType;
        });
        disabledDate = true;
      } else {
        behaviorTypes = behaviorTypes.map(currentBehaviorType => {
          const behaviorType = { ...currentBehaviorType };
          if (Number(behaviorType.id) === Number(response.idSegment)) behaviorType.checked = true;
          else behaviorType.checked = false;
          return behaviorType;
        });
        disabledDate = false;
      }
      let limit = '';
      if (response.applicable && (Number(response.applicable) !== 0)) limit = response.applicable;
      else limit = '';
      this.setState({
        code: response.coupon,
        currency: response.currency,
        rpCode: response.rp,
        disabledDate,
        description: response.description,
        comment: response.comments,
        startDate,
        endDate,
        type: response.idType,
        discountTypes,
        behavior: response.idSegment,
        behaviorTypes,
        quantity: Number(response.quantity),
        checkedCombinied,
        checked: limit,
        limit,
        activeStartDate,
        activeEndDate,
        id,
        loading: false,
        checkedStock: response.stockRequire ? response.stockRequire : 0,
      });
    })
      .catch(error => {
        const { t } = this.props;
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
        this.setState({ loading: false });
      });
  }

  handleGetGroups = async () => {
    const { t } = this.props;
    let messageError = '';
    let groupsList = await discountService.getGroups()
      .catch(error => {
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (groupsList && groupsList.customersGroups) {
      groupsList = groupsList.customersGroups.map(currentGroup => {
        const group = { ...currentGroup };
        group.value = group.id;
        group.text = group.name;
        return group;
      });
    }
    this.setState({ groupsList });
    return groupsList;
  }

  handleGetClients = async () => {
    const { t } = this.props;
    let messageError = '';
    cookie.set('element', 'Descuento', { expires: 1 });
    let customersList = await discountService.getCustomers()
      .catch(error => {
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (customersList && customersList.customers) {
      customersList = customersList.customers.map(currentCustomer => {
        const customer = { ...currentCustomer };
        customer.value = (customer.id).trim();
        customer.text = `${customer.id} - ${customer.name}`;
        return customer;
      });
    }
    this.setState({ customersList });
    return customersList;
  }

  handleGetCreditCustomers = async () => {
    const { t } = this.props;
    let messageError = '';
    let creditCustomersList = await discountService.getCustomersWithCredit()
      .catch(error => {
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (creditCustomersList && creditCustomersList.customers) {
      creditCustomersList = creditCustomersList.customers.map(customer => {
        const customerFormated = { value: customer.id.trim(), text: `${customer.id.trim()} - ${customer.name}` };
        return customerFormated;
      });
    }
    this.setState({ creditCustomersList });
  }

  handleGetDiscountConditions = async () => {
    const { t } = this.props;
    const { id } = this.state;
    let messageError = '';
    const optionsMaker = await this.handleGetMakers();
    this.setState({ loadingDetail: true });
    try {
      let {
        arrayConditions, scope, clientId, idScope, scopeOptions,
      } = this.state;
      let arrayScope = [{}];
      const response = await discountService.getDiscountDetail(id)
        .catch(error => {
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        });
      arrayScope = response.couponsD.filter(conditionElement => conditionElement.conditionId < 4);
      const arrayMaker = response.couponsD.filter(conditionElement => conditionElement.conditionId === 8)[0];
      const arrayAmount = response.couponsD.filter(conditionElement => conditionElement.conditionId === 13)[0];
      arrayConditions = response.couponsD.filter(conditionElement => conditionElement.conditionId > 3 && conditionElement.conditionId !== 8 && conditionElement.conditionId !== 13);
      if (arrayScope && arrayScope.length > 0) {
        if (Number(arrayScope[0].conditionId) === 1) {
          scopeOptions = await this.handleGetClients();
          scope = arrayScope[0].conditionId;
          clientId = arrayScope[0].value.trim();
          idScope = arrayScope[0].id;
        }
        if (Number(arrayScope[0].conditionId) === 2) {
          scopeOptions = await this.handleGetGroups();
          scope = arrayScope[0].conditionId;
          clientId = arrayScope[0].value;
          idScope = arrayScope[0].id;
        }
        if (Number(arrayScope[0].conditionId) === 3) {
          scope = arrayScope[0].conditionId;
          idScope = arrayScope[0].id;
        }
      } else { arrayScope = [{ conditionId: '', value: '' }]; }
      let makerId = '';
      let makerData = {};
      const maker = arrayMaker ? arrayMaker.value : '';
      const makerConditionId = arrayMaker ? arrayMaker.id : '';
      if (optionsMaker && maker) [makerData] = optionsMaker.filter(makerItem => makerItem.text === maker);
      if (makerData) makerId = makerData.key;
      this.setState({
        loadingDetail: false,
        arrayConditions,
        scope,
        clientId,
        arrayScope,
        scopeOptions,
        idScope,
        maker,
        makerConditionId,
        makerId,
        minimumAmount: arrayAmount ? arrayAmount.value : '',
        minimumAmountId: arrayAmount ? arrayAmount.id : '',
      });
      this.handleValidateExistConditions(arrayConditions);
    } catch (error) {
      Toast('Error al procesar la información', 'error');
      this.setState({ loadingDetail: false, optionsMaker });
    }
  }

  handleGetListApplyOn = async () => {
    const { listValues } = this.state;
    const { t } = this.props;
    const list = await discountService.getAllListApply()
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('discounts.error-codes.default');
        else messageError = t(`discounts.error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (list && list.family && list.group && list.branch && list.line && list.product) {
      list.family.map(value => {
        if (value.name) {
          if (value.name.trim().length >= 1 && value.name !== 'N/A' && value.name !== 'NA') {
            listValues.family.push({
              key: value.name, text: value.name, value: value.name, makerId: value.makerId,
            });
          }
        }
        return value;
      });
      list.group.map(value => {
        if (value.name) {
          if (value.name.trim().length >= 1 && value.name !== 'N/A' && value.name !== 'NA') {
            listValues.group.push({
              key: value.name, text: value.name, value: value.name, makerId: value.makerId,
            });
          }
        }
        return value;
      });
      list.branch.map(value => {
        if (value.name) {
          if (value.name.trim().length >= 1 && value.name !== 'N/A' && value.name !== 'NA') {
            listValues.branch.push({
              key: value.name, text: value.name, value: value.name, makerId: value.makerId,
            });
          }
        }
        return value;
      });
      list.line.map(value => {
        if (value.name) {
          if (value.name.trim().length >= 1 && value.name !== 'N/A' && value.name !== 'NA') {
            listValues.line.push({
              key: value.name, text: value.name, value: value.name, makerId: value.makerId,
            });
          }
        }
        return value;
      });
      list.product.map(product => {
        listValues.product.push({
          key: product.sku, text: `${product.sku}-${product.name}`, value: product.sku, makerId: product.makerId,
        });
        return product;
      });
    }
    this.setState({ listValues });
    return listValues;
  }

  handleValidateExistConditions = arrayConditions => {
    const { arrayTypes } = this.state;
    if (arrayConditions && arrayConditions.length > 0) {
      arrayConditions.map(data => {
        arrayTypes.map((typeCondition, index) => {
          if (Number(typeCondition.key) === Number(data.conditionId)) arrayTypes.splice(index, 1);
          return typeCondition;
        });
        return data;
      });
      this.setState({ arrayConditions, arrayTypes });
    }
  }

  randmonCode = () => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghijklmnopqrstuvwxyz';
    let randomLetters = '';
    let rnum;
    for (let i = 0; i < 2; i++) {
      rnum = Math.floor(Math.random() * letters.length);
      randomLetters += letters.substring(rnum, rnum + 1);
    }
    randomLetters += Math.floor((Math.random() * 9999) + 1000);
    this.setState({ code: randomLetters, codeError: '' });
  }

  handleChangeDescription = event => {
    const { t } = this.props;
    const maxLength = 250;
    const { value: description } = event.target;
    if (description.length > maxLength) {
      this.setState({ description, descriptionError: t('discounts.errors.toLong') });
      return;
    }
    this.setState({ description, descriptionError: '' });
  }

  handleChangeStartDate = () => this.setState(prevState => ({ activeStartDate: !prevState.activeStartDate }))

  handleChangeEndtDate = () => this.setState(prevState => ({ activeEndDate: !prevState.activeEndDate }))

  handleStartDate = event => {
    const { t } = this.props;
    const { endDate, activeEndDate } = this.state;
    const { value } = event.target;
    this.setState({ startDate: value });
    if (activeEndDate && endDate && (value > endDate)) this.setState({ startDateError: t('discounts.startDateError') });
    else this.setState({ startDateError: '', endDateError: '' });
  };

  handleEndDate = event => {
    const { t } = this.props;
    const { startDate, activeStartDate } = this.state;
    const { value } = event.target;
    this.setState({ endDate: value });
    if (activeStartDate && startDate && (value < startDate)) this.setState({ endDateError: t('discounts.endDateError') });
    else this.setState({ endDateError: '', startDateError: '' });
  };

  handleChangeBehavior = (e, { value }) => {
    let { behaviorTypes } = this.state;
    behaviorTypes = behaviorTypes.map(currentBehaviorType => {
      const behaviorType = { ...currentBehaviorType };
      if (Number(behaviorType.id) === Number(value)) behaviorType.checked = true;
      else behaviorType.checked = false;
      return behaviorType;
    });
    this.setState({ behaviorTypes, behavior: value });
  }

  handleChangeQuantity = event => {
    const { t } = this.props;
    const { type } = this.state;
    const maxLength = 6;
    const { value } = event.target;
    const quantity = Number(value);
    if (quantity && quantity.length > 0) {
      if ((Number(type) === 1) && !validator.isNumeric(quantity)) {
        this.setState({ quantity, quantityError: t('discounts.errors.notNumeric') });
        return;
      }
      if ((Number(type) === 2) && !validator.isDecimal(quantity)) {
        this.setState({ quantity, quantityError: t('discounts.errors.notNumeric') });
        return;
      }
      if ((Number(type) === 2) && Number(quantity) > 90) {
        this.setState({ quantity, quantityError: t('El descuento no debe rebasar el 90%') });
        return;
      }
      if (Number(quantity) <= 0 && (Number(type) !== 5)) {
        this.setState({ quantity: 1, quantityError: '' });
        return;
      }
      if (quantity.length > maxLength) {
        this.setState({ quantity, quantityError: t('discounts.errors.toLong') });
        return;
      }
      if ((Number(type) === 5) && !validator.isNumeric(quantity)) {
        this.setState({ quantity, quantityError: t('discounts.errors.notNumeric') });
        return;
      }
    }
    this.setState({ quantity, quantityError: '' });
  }

  handleChangeType = (e, { value }) => {
    let {
      discountTypes, behaviorTypes, activeStartDate, activeEndDate, disabledDate,
    } = this.state;
    discountTypes = discountTypes.map(currentDiscountType => {
      const discountType = { ...currentDiscountType };
      if (Number(discountType.id) === Number(value)) discountType.checked = true;
      else discountType.checked = false;
      return discountType;
    });
    switch (value) {
      case 3:
        behaviorTypes = behaviorTypes.map(currentBehaviorType => {
          const behaviorType = { ...currentBehaviorType };
          if (Number(behaviorType.id) === 2) behaviorType.checked = true;
          behaviorType.disabled = true;
          return behaviorType;
        });
        activeStartDate = true;
        activeEndDate = true;
        disabledDate = true;
        break;
      case 4:
        behaviorTypes = behaviorTypes.map(currentBehaviorType => {
          const behaviorType = { ...currentBehaviorType };
          if (Number(behaviorType.id) === 2) behaviorType.checked = true;
          behaviorType.disabled = true;
          return behaviorType;
        });
        activeStartDate = true;
        activeEndDate = true;
        disabledDate = true;
        break;
      case 5: behaviorTypes = behaviorTypes.map(currentBehaviorType => {
        const behaviorType = { ...currentBehaviorType };
        if (Number(behaviorType.id) === 2) behaviorType.checked = true;
        behaviorType.disabled = true;
        return behaviorType;
      });
        disabledDate = false;
        activeStartDate = true;
        activeEndDate = true;
        break;
      default: behaviorTypes = behaviorTypes.map(currentBehaviorType => {
        const behaviorType = { ...currentBehaviorType };
        behaviorType.disabled = false;
        return behaviorType;
      });
        disabledDate = false; break;
    }
    this.setState({
      discountTypes, type: value, quantity: '', activeStartDate, activeEndDate, behaviorTypes, disabledDate,
    });
  }

  handleCheckedStock = () => {
    let { checkedStock } = this.state;
    checkedStock = !checkedStock;
    this.setState({ checkedStock });
  }

  handleCheckedCombinied = () => {
    let { checkedCombinied } = this.state;
    checkedCombinied = !checkedCombinied;
    this.setState({ checkedCombinied });
  }

  handleValidateEmptyData = () => {
    const { t } = this.props;
    const {
      behavior, id, code, currency, description, startDate, endDate, quantity, limit, activeStartDate, activeEndDate, checked,
      type, scope, maker, isAdmin,
    } = this.state;
    let {
      codeError, descriptionError, startDateError, endDateError, quantityError, limitError, makerError,
    } = this.state;
    let mistakes = false;
    if (!code) { codeError = t('discounts.errors.empty'); mistakes = true; }
    if (!description) { descriptionError = t('discounts.errors.empty'); mistakes = true; }
    if (activeStartDate === true && !startDate) { startDateError = t('discounts.errors.empty'); mistakes = true; }
    if (activeEndDate === true && !endDate) { endDateError = t('discounts.errors.empty'); mistakes = true; }
    if (!quantity && type !== 5) { quantityError = t('discounts.errors.empty'); mistakes = true; }
    if (type === 5 && typeof quantity !== 'number') { quantityError = t('discounts.errors.empty'); mistakes = true; }
    if (checked && !limit) { limitError = t('discounts.errors.empty'); mistakes = true; }
    if (id && !scope) { Toast('Es necesario definir un alcance', 'warning'); mistakes = true; }
    if (id && (behavior === 1) && !maker) { makerError = t('discounts.errors.empty'); mistakes = true; }
    if (id && (behavior === 2) && !isAdmin && !maker) { makerError = t('discounts.errors.empty'); mistakes = true; }
    if (id && (type === 3) && !maker) { makerError = t('discounts.errors.empty'); mistakes = true; }
    if (type === 1 && !currency) { Toast('Es necesario elegir un tipo de moneda', 'warning'); mistakes = true; }

    if (!mistakes) {
      this.handleGetAllData();
    } else {
      Toast(t('discounts.errors.incompleteData'), 'error');
      this.setState({
        codeError, descriptionError, startDateError, endDateError, quantityError, limitError, makerError,
      });
    }
  }

  handleGetAllData = () => {
    const { id } = this.state;
    const {
      code, description, startDate, endDate, quantity, checkedCombinied, type, comment, rpCode, currency, checkedStock, behavior,
    } = this.state;
    let { limit } = this.state;
    let cumulative = 0;
    if (checkedCombinied) cumulative = 1;
    else cumulative = 0;
    if (!limit || Number(limit) === 0) limit = '';
    const discount = {
      coupon: code,
      rp: rpCode,
      description,
      startValidity: startDate,
      endValidity: endDate ? `${endDate} 23:59:00` : endDate,
      idSegment: behavior,
      idType: type,
      quantity: Number(quantity),
      cumulative,
      applicable: limit,
      limitFlag: (limit ? 1 : 0),
      comments: comment,
      currency: Number(currency),
      stockRequire: Number(checkedStock),
    };
    if (!limit) delete discount.applicable;
    if (!rpCode) delete discount.rp;
    if (!startDate) delete discount.startValidity;
    if (!endDate) delete discount.endValidity;
    if (!currency) delete discount.currency;
    if (!comment || comment.length < 1) delete discount.comments;
    if (!id) this.handleSaveDiscount(discount);
    else this.handleUpdateDiscount(discount);
  }

  handleSaveDiscount = discountData => {
    const { t } = this.props;
    this.setState({ loadingSave: true });
    discountService.addDiscount(discountData)
      .then(response => {
        if (response.id) {
          this.setState({ id: response.id, rpCodeError: '', loadingSave: false });
          Toast(t('discounts.discountAdd'), 'success');
        }
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (invalidRebate(error.code)) this.setState({ rpCodeError: t('discounts.errors.invalidRebate'), loadingSave: false });
        this.setState({ loadingSave: false });
        Toast(messageError, 'error');
      });
  }

  handleUpdateDiscount = discountData => {
    const { t } = this.props;
    const {
      idScope, arrayScope, maker, id, minimumAmountId, minimumAmount, makerConditionId,
    } = this.state;
    this.setState({ loadingSave: true });
    discountService.updateDiscount({ ...discountData, id })
      .then(() => {
        this.setState({ rpCodeError: '', loadingSave: false });
        if (!idScope) this.handleSaveScope(arrayScope);
        else this.handleUpdateScope(arrayScope);
      })
      .then(() => {
        if (!makerConditionId && maker) this.handleSaveMaker(maker);
        if (makerConditionId && maker) this.handleUpdateMaker(maker);
        if (!minimumAmountId && minimumAmount) this.handleSaveAmount(minimumAmount);
        if (minimumAmountId && minimumAmount) this.handleUpdateAmount(minimumAmount);
      })
      .then(() => Toast(t('discounts.discountUpdated'), 'success'))
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (invalidRebate(error.code)) this.setState({ rpCodeError: t('discounts.errors.invalidRebate'), loadingSave: false });
        Toast(messageError, 'error');
      });
  }

  handleChangeScope = (e, { value }) => {
    const { t } = this.props;
    const {
      arrayScope, customersList, groupsList,
    } = this.state;
    let { placeholderScope, scopeOptions } = this.state;
    if (value === 1) {
      arrayScope[0].conditionId = 1;
      scopeOptions = customersList;
      placeholderScope = t('discounts.placeholderScopeClient');
    }
    if (value === 2) {
      arrayScope[0].conditionId = 2;
      scopeOptions = groupsList;
      placeholderScope = t('discounts.placeholderScopeGroup');
    }
    if (value === 3) {
      arrayScope[0].conditionId = 3;
      arrayScope[0].value = 'General';
      this.setState({ arrayScope, scopeOptions: [] });
    }
    this.setState({
      scope: value, placeholderScope, arrayScope, scopeOptions,
    });
  }

  handleChangeClients = (e, { value }) => {
    const { arrayScope } = this.state;
    arrayScope[0].value = value;
    this.setState({ clientId: value, clientError: '', arrayScope });
  }

  handleChangeCondition = (e, { value }) => {
    const { listValues, arrayGroups } = this.state;
    let { allowAdd, arrayValues } = this.state;
    switch (Number(value)) {
      case 4: arrayValues = listValues.product; allowAdd = true; break;
      case 5: arrayValues = listValues.family; allowAdd = false; break;
      case 6: arrayValues = listValues.branch; allowAdd = false; break;
      case 7: arrayValues = listValues.line; allowAdd = false; break;
      case 11: arrayValues = arrayGroups; allowAdd = false; break;
      default: arrayValues = []; allowAdd = false; break;
    }
    this.setState({
      condition: value, conditionError: '', arrayValues, allowAdd, value: '',
    });
  }

  handleChangeConditionDays = (e, { value }) => this.setState({ quantity: value, quantityError: '' });

  handleChangeMaker = (e, { value }) => {
    const { optionsMaker } = this.state;
    let maker = '';
    if (value) maker = optionsMaker.filter(currentMaker => currentMaker.value === value)[0].text;
    if (value === 'whitoutMaker') maker = value;
    this.setState({ makerId: value, makerError: '', maker });
  }

  handleChangeCurrency = (e, { value }) => this.setState({ currency: value, currencyError: '' });

  handleChangeValue = (e, { value }) => this.setState({ value, valueError: '' });

  handleChangeMinimumAmount = event => {
    const { t } = this.props;
    const maxLength = 15;
    let mistakes = false;
    const { value: minimumAmount } = event.target;
    if (minimumAmount && minimumAmount.length > 0) {
      if ((minimumAmount.trim()).length > maxLength) { this.setState({ minimumAmount, minimumAmountError: t('discounts.errors.toLong') }); mistakes = true; return; }
      if (!validator.isDecimal(minimumAmount)) { this.setState({ minimumAmount, minimumAmountError: t('discounts.errors.notAlpha') }); mistakes = true; return; }
    }
    if (mistakes !== true) this.setState({ minimumAmount, minimumAmountError: '' });
  }

  handleClickDelete = row => {
    this.setState({
      ruleId: row.id,
      condition: row.conditionId,
      conditionText: row.condicion,
      value: row.value,
      open: true,
    });
  }

  handleClickUpdate = row => {
    const { listValues, arrayTypes, arrayGroups } = this.state;
    const conditionExist = arrayTypes.filter(condition => condition.key === row.conditionId);
    if (conditionExist && conditionExist.length < 1) arrayTypes.push({ key: row.conditionId, value: row.conditionId, text: row.condicion });
    let { allowAdd, arrayValues } = this.state;
    switch (Number(row.conditionId)) {
      case 4: arrayValues = listValues.product; allowAdd = true; break;
      case 5: arrayValues = listValues.family; allowAdd = false; break;
      case 6: arrayValues = listValues.branch; allowAdd = false; break;
      case 7: arrayValues = listValues.line; allowAdd = false; break;
      case 11: arrayValues = arrayGroups; allowAdd = false; break;
      default: arrayValues = []; allowAdd = false; break;
    }
    this.setState({
      arrayTypes,
      ruleId: row.id,
      condition: row.conditionId,
      arrayValues,
      value: Number(row.conditionId) === 11 ? Number(row.value) : row.value,
      allowAdd,
      conditionError: '',
      valueError: '',
    });
  }

  handleConfirmDelete = () => {
    const { t } = this.props;
    const {
      ruleId, condition, value, arrayConditions, arrayTypes,
    } = this.state;
    const discountDetail = {
      id: ruleId, conditionId: condition, value: value.toString(), active: 0,
    };
    discountService.updateDiscountDetail(discountDetail)
      .then(response => {
        if (Number(response.id) === 1) {
          arrayConditions.map((fieldToApply, index) => {
            if (Number(fieldToApply.conditionId) === Number(condition)) {
              const conditionExist = arrayTypes.filter(conditionElement => conditionElement.key === condition);
              if (!conditionExist || conditionExist.length <= 0) {
                arrayTypes.push({ key: fieldToApply.conditionId, value: fieldToApply.conditionId, text: fieldToApply.condicion });
              }
              arrayConditions.splice(index, 1);
              Toast(t('discounts.successFieldDeleted'), 'success');
              this.setState({
                arrayConditions, arrayTypes, open: false, condition: '', value: '', arrayValues: [], ruleId: '',
              });
            }
            return fieldToApply;
          });
        }
      }).catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
        this.setState({
          open: false, condition: '', value: '', arrayValues: [], ruleId: '',
        });
      });
  }

  hanldeAddField = () => {
    const { t } = this.props;
    const {
      arrayConditions, condition, value, arrayTypes, id,
    } = this.state;
    let { conditionError, valueError } = this.state;
    let fieldExist = '';
    this.setState({ loadingField: true });
    if (condition && value) {
      let fieldToAdd = '';
      if (Number(condition) === 4) fieldToAdd = 'SKU';
      if (Number(condition) === 5) fieldToAdd = 'Familia';
      if (Number(condition) === 6) fieldToAdd = 'Rama';
      if (Number(condition) === 7) fieldToAdd = 'Línea';
      if (Number(condition) === 8) fieldToAdd = 'Fabricante';
      if (Number(condition) === 11) fieldToAdd = 'Grupo de Articulos';

      const discountDetail = {
        couponId: id,
        cuponsDetail: [{ conditionId: condition, value: `${value}` }],
      };

      if (arrayConditions) fieldExist = arrayConditions.filter(fieldObject => fieldObject.condicion === fieldToAdd);

      if (fieldExist.length > 0) {
        Toast(t('discounts.warningSameType'), 'warning');
        this.setState({ loadingField: false });
      } else {
        discountService.addDiscountDetail(discountDetail)
          .then(response => {
            arrayConditions.push({
              id: response.id, condicion: fieldToAdd, conditionId: condition, value, valueError: '', fieldError: '',
            });
            Toast(t('discounts.successFielAdd'), 'success');
            arrayTypes.map((typeObject, index) => {
              if (typeObject.key === condition) { arrayTypes.splice(index, 1); }
              return value;
            });
            this.setState({
              arrayConditions, arrayTypes, arrayValues: [], condition: '', value: '', loadingField: false,
            });
          })
          .catch(error => {
            let messageError = '';
            if (!error.code) messageError = t('error-codes.default');
            else messageError = t(`error-codes.${error.code}`);
            Toast(messageError, 'error');
            this.setState({ loadingField: false });
          });
      }
    } else {
      if (!value) valueError = t('user.errors.empty');
      if (!condition) conditionError = t('user.errors.empty');
      this.setState({ valueError, conditionError, loadingField: false });
    }
  }

  hanldeUpdateField = () => {
    const { t } = this.props;
    const {
      ruleId, value, condition, arrayConditions,
    } = this.state;
    let conditionName = '';
    this.setState({ loadingField: true });
    if (Number(condition) === 4) conditionName = 'SKU';
    if (Number(condition) === 5) conditionName = 'Familia';
    if (Number(condition) === 6) conditionName = 'Rama';
    if (Number(condition) === 7) conditionName = 'Línea';
    if (Number(condition) === 8) conditionName = 'Fabricante';
    if (Number(condition) === 11) conditionName = 'Grupo de Articulos';

    const discountDetail = {
      id: ruleId, conditionId: condition, value: `${value}`, active: 1,
    };
    const conditionUpdate = arrayConditions.findIndex(field => Number(field.id) === Number(ruleId));
    discountService.updateDiscountDetail(discountDetail)
      .then(response => {
        if (response.id) {
          arrayConditions[conditionUpdate].conditionId = condition;
          arrayConditions[conditionUpdate].condicion = conditionName;
          arrayConditions[conditionUpdate].value = value;
          this.handleValidateExistConditions(arrayConditions);
          this.setState({
            arrayConditions, condition: '', value: '', arrayValues: [], ruleId: '', loadingField: false,
          });
          Toast(t('discounts.fieldUpdated'), 'success');
        }
      }).catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ loadingField: false });
        Toast(messageError, 'error');
      });
  }

  handleAddition = (e, { value }) => {
    const { valueError } = this.state;
    if (!valueError) this.setState(prevState => ({ arrayValues: [{ text: value, value }, ...prevState.arrayValues] }));
  }

  handleChangeComment = (e, { value }) => this.setState({ comment: value });

  handleChecked = () => {
    let { checked, disabledLimit, limit } = this.state;
    checked = !checked;
    if (checked === true) disabledLimit = false;
    else {
      disabledLimit = true;
      limit = 0;
    }
    this.setState({
      checked, disabledLimit, limitError: '', limit,
    });
  }

  handleChangeLimit = event => {
    const { t } = this.props;
    const maxLength = 4;
    let mistakes = false;
    const { value: limit } = event.target;
    if (limit && limit.length > 0) {
      if ((limit.trim()).length > maxLength) { this.setState({ limit, limitError: t('discounts.errors.toLong') }); mistakes = true; return; }
      if (!validator.isNumeric(limit)) { this.setState({ limit, limitError: t('discounts.errors.notAlpha') }); mistakes = true; return; }
      if (Number(limit) <= 0) { this.setState({ limit: 1, limitError: '' }); return; }
    }
    if (mistakes !== true) this.setState({ limit, limitError: '' });
  }

  handleSaveScope = arrayScope => {
    const { t } = this.props;
    const { id } = this.state;
    const discountDetail = { couponId: Number(id), cuponsDetail: arrayScope };
    return discountService.addDiscountDetail(discountDetail)
      .then(response => {
        if (response.id) {
          this.setState({ arrayScope, idScope: response.id });
        }
      }).catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleUpdateScope = arrayScope => {
    const { t } = this.props;
    const { idScope } = this.state;
    const discountDetail = {
      id: idScope, conditionId: arrayScope[0].conditionId, value: arrayScope[0].value, active: 1,
    };
    return discountService.updateDiscountDetail(discountDetail)
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleSaveMaker = maker => {
    const { t } = this.props;
    const { id } = this.state;
    const discountDetail = { couponId: Number(id), cuponsDetail: [{ conditionId: 8, value: maker }] };
    if (maker !== 'whitoutMaker') {
      discountService.addDiscountDetail(discountDetail)
        .then(response => {
          if (response.id) {
            this.setState({ makerConditionId: response.id });
          }
        }).catch(error => {
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        });
    }
  }

  handleUpdateMaker = maker => {
    const { t } = this.props;
    const { makerConditionId } = this.state;
    let discountDetail = {
      id: makerConditionId, conditionId: 8, value: maker, active: 1,
    };
    if (maker === 'whitoutMaker') {
      discountDetail = {
        id: makerConditionId, conditionId: 8, value: maker, active: 0,
      };
    }
    discountService.updateDiscountDetail(discountDetail).catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    });
  }

  handleCancelUpdateField = () => {
    const { condition } = this.state;
    const { arrayTypes } = this.state;
    const typeToDelete = arrayTypes.indexOf(condition);
    arrayTypes.splice(typeToDelete, 1);
    this.setState({
      condition: '', value: '', arrayValues: [], ruleId: '', arrayTypes, loadingField: false,
    });
  }

  handleCodeChange = event => {
    const { t } = this.props;
    const maxLength = 15;
    const { value: code } = event.target;
    if (code && code.length > 0) {
      if (!validator.isAlphanumeric(code, ['es-ES'])) { this.setState({ code, codeError: t('discounts.errors.notSymbol') }); return; }
      if (code.length > maxLength) { this.setState({ code, codeError: t('discounts.errors.toLong') }); return; }
    }
    this.setState({ code, codeError: '' });
  }

  handleRpCodeChange = event => {
    const { t } = this.props;
    const maxLength = 20;
    const { value: rpCode } = event.target;
    if (rpCode && rpCode.length > 0) {
      if (rpCode.length > maxLength) { this.setState({ rpCode, rpCodeError: t('discounts.errors.toLong') }); return; }
    }
    this.setState({ rpCode, rpCodeError: '' });
  }

  handleBack = () => {
    const { changeView } = this.props;
    const param = false;
    changeView(param);
  }

  handleGetGroupsItems = () => {
    let { arrayGroups } = this.state;
    discountService.getGroupsItems(2).then(response => {
      arrayGroups = response.groups.map(group => {
        const groupFormated = { key: group.id, value: group.id, text: group.group };
        return groupFormated;
      });
      this.setState({ arrayGroups });
    })
      .catch(error => {
        const { t } = this.props;
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        if (error.code !== 1005 && error.code !== 1005) Toast(messageError, 'error');
      });
  }

  handleSaveAmount = minimumAmount => {
    const { t } = this.props;
    const { id } = this.state;
    const discountDetail = { couponId: Number(id), cuponsDetail: [{ conditionId: 13, value: Number(minimumAmount) === 0 ? '0' : minimumAmount }] };
    discountService.addDiscountDetail(discountDetail)
      .then(response => {
        if (response.id) {
          this.setState({ minimumAmountId: response.id });
        }
      }).catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleUpdateAmount = minimumAmount => {
    const { t } = this.props;
    const { minimumAmountId } = this.state;
    const discountDetail = {
      id: minimumAmountId, conditionId: 13, value: Number(minimumAmount) === 0 ? '0' : minimumAmount, active: 1,
    };
    discountService.updateDiscountDetail(discountDetail).catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    });
  }

  render() {
    const {
      id, code, codeError, description, descriptionError, activeStartDate, activeEndDate, startDate, endDate, startDateError,
      endDateError, arrayTypes, quantity, quantityError, checkedCombinied, behavior, scope, placeholderScope, loadingSave,
      clientId, clientError, arrayConditions, nameHeaders, condition, conditionError, conditionText, makerId,
      open, value, valueError, allowAdd, type, checked, disabledLimit, limitError, limit, ruleId, behaviorTypes, discountTypes,
      comment, commentError, rpCode, rpCodeError, optionDays, optionsCurrency, currency, currencyError, checkedStock, loadingField,
      disabledDate, optionsMaker, makerError, loading, loadingDetail, creditCustomersList, minimumAmount, minimumAmountError,
    } = this.state;
    let { scopeOptions, arrayValues } = this.state;
    const { t } = this.props;
    if (makerId && condition !== 11) arrayValues = arrayValues.filter(currentValue => currentValue.makerId === makerId);
    if (type === 3 && scope === 1) scopeOptions = creditCustomersList;
    return (
      <Grid container>
        {!loading && !loadingDetail ? (
          <Description
            code={{ value: code, error: codeError }}
            rpCode={{ value: rpCode, error: rpCodeError }}
            description={{ value: description, error: descriptionError }}
            comment={{ value: comment, error: commentError }}
            startDate={{
              value: startDate, active: activeStartDate, disabled: disabledDate, error: startDateError,
            }}
            endDate={{
              value: endDate, active: activeEndDate, disabled: disabledDate, error: endDateError,
            }}
            type={type}
            optionDays={optionDays}
            discountTypes={discountTypes}
            quantity={{ value: quantity, error: quantityError }}
            behavior={behavior}
            behaviorTypes={behaviorTypes}
            checkedCombinied={checkedCombinied}
            randmonCode={this.randmonCode}
            handleChangeComment={this.handleChangeComment}
            handleChangeDescription={this.handleChangeDescription}
            handleChangeStartDate={this.handleChangeStartDate}
            handleChangeEndtDate={this.handleChangeEndtDate}
            handleStartDate={this.handleStartDate}
            handleEndDate={this.handleEndDate}
            handleChangeType={this.handleChangeType}
            handleChangeQuantity={this.handleChangeQuantity}
            handleChangeBehavior={this.handleChangeBehavior}
            handleCheckedCombinied={this.handleCheckedCombinied}
            handleCodeChange={this.handleCodeChange}
            handleRpCodeChange={this.handleRpCodeChange}
            handleChangeConditionDays={this.handleChangeConditionDays}
            optionCurrency={optionsCurrency}
            currency={{ value: currency, error: currencyError }}
            handleChangeCurrency={this.handleChangeCurrency}
            handleCheckedStock={this.handleCheckedStock}
            checkedStock={checkedStock}
            fluid
            selection
            search
          />
        ) : (
          <Grid.Row>
            <Dimmer active inverted><Loader active size="big" /></Dimmer>
          </Grid.Row>
        )}
        {id && !loading && !loadingDetail
          ? (
            <Scope
              scope={scope}
              type={type}
              behavior={behavior}
              handleChangeScope={this.handleChangeScope}
              scopeOptions={scopeOptions}
              placeholderScope={placeholderScope}
              handleChangeClients={this.handleChangeClients}
              client={{ value: clientId, error: clientError }}
            />
          )
          : null}
        {id && !loading && !loadingDetail
          ? (
            <Conditions
              ruleId={ruleId}
              nameHeaders={nameHeaders}
              arrayConditions={arrayConditions}
              arrayTypes={arrayTypes}
              condition={{
                value: condition, error: conditionError, text: conditionText, allowAdd,
              }}
              handleChangeCondition={this.handleChangeCondition}
              arrayValues={arrayValues}
              open={open}
              handleCancelDelete={() => this.setState({ open: !open })}
              handleConfirmDelete={this.handleConfirmDelete}
              handleChangeValue={this.handleChangeValue}
              conditionValue={{ value, error: valueError }}
              hanldeAddField={this.hanldeAddField}
              hanldeUpdateField={this.hanldeUpdateField}
              handleAddition={this.handleAddition}
              handleChecked={this.handleChecked}
              limit={{
                value: limit, checked, disabled: disabledLimit, error: limitError,
              }}
              handleChangeLimit={this.handleChangeLimit}
              handleCancelUpdateField={this.handleCancelUpdateField}
              type={type}
              optionsMaker={optionsMaker}
              maker={{ value: makerId, error: makerError }}
              handleChangeMaker={this.handleChangeMaker}
              minimumAmount={{ value: minimumAmount, error: minimumAmountError }}
              handleChangeMinimumAmount={this.handleChangeMinimumAmount}
              currency={currency}
              optionCurrency={optionsCurrency}
              loadingField={loadingField}
            />
          )
          : null}
        <Grid.Row centered>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={1} />
          {!loading && !loadingDetail ? (
            <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={7}>
              <Button color="blue" disabled={loadingSave} loading={loadingSave} onClick={this.handleValidateEmptyData}>{t('save')}</Button>
            </Grid.Column>
          ) : null}
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={7}>
            <Button onClick={this.handleBack}>{t('back')}</Button>
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={1} />
        </Grid.Row>
      </Grid>
    );
  }
}

DiscountDetail.propTypes = {
  changeView: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default translate('common', { wait: true })(DiscountDetail);
