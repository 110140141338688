import { Responsive } from 'semantic-ui-react';
import { analyticsEvent } from '../common/utils';

export function getSize(width, modifier = '') {
  let mobileLimit;
  let tabletLimit;
  switch (modifier) {
    case 'dualBanner':
      mobileLimit = Responsive.onlyTablet.minWidth - 150;
      tabletLimit = Responsive.onlyComputer.minWidth + 300;
      break;
    default:
      mobileLimit = Responsive.onlyTablet.minWidth;
      tabletLimit = Responsive.onlyComputer.minWidth;
      break;
  }
  if (width < mobileLimit) return 'mobile';
  if (width < tabletLimit) return 'tablet';
  return 'computer';
}

export function promotionClickGA(promotion) {
  const { name } = promotion;
  analyticsEvent('Promocionales', 'click', name);
}
