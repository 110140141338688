/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import cookie from 'js-cookie';
import { callError } from '@compusoluciones-pdigitales/status-codes';

import * as session from './sessions';
import apiRoutes from '../apiRoutes';
import { ACCESS_TOKEN } from './sessions/constants';

let isRefreshing = false;
let failedQueue = [];

function processQueue(error, token = null) {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
}

axios.interceptors.request.use(config => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const token = cookie.get(ACCESS_TOKEN);
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return { ...config, headers };
});

axios.interceptors.response.use(response => response.data, async error => {
  const { config: originalRequest } = error;
  const errorResponse = error?.response?.data?.code;
  if ((errorResponse === callError.general.TOKEN_MISSING || errorResponse === callError.general.TOKEN_EXPIRED) && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      }).then(token => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axios(originalRequest);
      }).catch(err => Promise.reject(err));
    }

    originalRequest._retry = true;
    isRefreshing = true;

    try {
      const userSession = session.get();
      const { accessToken } = await axios.request({
        url: apiRoutes.refreshToken(),
        method: 'POST',
        data: {
          userId: userSession.userId,
          customerId: userSession.customerId,
          refreshToken: userSession.refreshToken,
        },
      });
      session.setCookies(accessToken, userSession.refreshToken, userSession.isGuest);
      processQueue(null, accessToken);
      originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      return axios(originalRequest);
    } catch (err) {
      processQueue(err, null);
      session.logout();
      return Promise.reject(err);
    } finally {
      isRefreshing = false;
    }
  }

  const bodyResponse = error?.response && error.response.data;
  throw bodyResponse;
});

export function request({
  route, method = 'GET', headers, body,
}) {
  return axios.request({
    url: route,
    method,
    headers,
    data: body && JSON.stringify(body),
  });
}
