import apiRoutes from '../apiRoutes';
import { request } from '../common/fetch-utils';

const products = {};

products.getProductBySku = (productId, isVirtual) => (
  request({
    route: apiRoutes.getProductBySku(productId, isVirtual),
    method: 'GET',
  })
);

products.getOutletById = productId => (
  request({
    route: apiRoutes.getOutletById(productId),
    method: 'GET',
  })
);

products.getRelatedProducts = productId => (
  request({
    route: apiRoutes.getRelatedProducts(productId),
    method: 'GET',
  })
);

products.addItems = body => (
  request({
    route: apiRoutes.addItems(),
    method: 'POST',
    body,
  })
);

products.getAllImages = sku => (
  request({
    route: apiRoutes.getAllImages(sku),
    method: 'GET',
  })
);

products.getWishList = () => (
  request({
    route: apiRoutes.getWishLists(),
    method: 'GET',
  })
);

products.addItemToWishList = (wishListId, items) => (
  request({
    route: apiRoutes.itemsWishList(wishListId),
    method: 'POST',
    body: {
      items,
    },
  })
);

products.createWishList = (wishListName, items) => (
  request({
    route: apiRoutes.getWishLists(),
    method: 'POST',
    body: {
      name: wishListName,
      items,
    },
  })
);

products.getAllImages = sku => (
  request({
    route: apiRoutes.getAllImages(sku),
    method: 'GET',
  })
);

products.getAllMakers = () => (
  request({
    route: apiRoutes.getAllMakers(),
    method: 'GET',
  })
);

products.getBundleOptionsBySku = bundle => (
  request({
    route: apiRoutes.getBundleOptionsBySku(bundle),
    method: 'GET',
  })
);

products.getRequiredProductsBySku = sku => (
  request({
    route: apiRoutes.getRequiredProductsBySku(sku),
    method: 'GET',
  })
);

products.getPromotionalMessage = productId => (
  request({
    route: apiRoutes.getPromotionalMessage(productId),
    method: 'GET',
  })
);

products.getSubstituteProducts = productId => (
  request({
    route: apiRoutes.getSubstituteProducts(productId),
    method: 'GET',
  })
);

products.getConfigBySku = productId => (
  request({
    route: apiRoutes.getVirtualProductsConfig(productId),
    method: 'GET',
  })
);

products.getProductInformation = (sku, allowVariants) => (
  request({
    route: apiRoutes.getProductInformation(sku, allowVariants),
    method: 'GET',
  })
);

products.getProductDataSheet = sku => (
  request({
    route: apiRoutes.getProductDataSheet(sku),
    method: 'GET',
  })
);

products.getAvailableRedhatByCustomer = customerId => (
  request({
    route: apiRoutes.getAvailableRedhatByCustomer(customerId),
    method: 'GET',
  })
);

products.getAutodeskPrices = body => (
  request({
    route: apiRoutes.getAutodeskPrices(body),
    method: 'POST',
    body,
  })
);

export default products;
