import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

function fetchProducts(queryParams, queryOptions) {
  function sanitizeQueryParams(params) {
    return {
      ...params,
      searchType: params.searchType === 'search' ? 'keyword' : params.searchType,
    };
  }

  const stringifiedQueryParams = new URLSearchParams(sanitizeQueryParams(queryParams)).toString();
  return request({
    method: 'POST',
    route: `${apiRoutes.fetchProductCatalog()}?${stringifiedQueryParams}`,
    body: { filters: queryOptions },
  });
}

function getAllMakers() {
  return request({
    route: apiRoutes.getAllMakers(),
    method: 'GET',
  });
}

function addItems(body) {
  return request({
    route: apiRoutes.addItems(),
    method: 'POST',
    body,
  });
}

function getAvailableRedhatByCustomer(customerId) {
  return request({
    route: apiRoutes.getAvailableRedhatByCustomer(customerId),
    method: 'GET',
  });
}

function getStockBySku(sku) {
  return request({
    route: apiRoutes.getStockById(sku),
    method: 'GET',
  });
}

function deleteShoppingCart() {
  return request({
    route: apiRoutes.deleteShoppingCart(),
    method: 'DELETE',
  });
}

function getProductInformation(sku) {
  return request({
    route: apiRoutes.getProductInformation(sku),
    method: 'GET',
  });
}

function addPricesMaker(body) {
  return request({
    route: apiRoutes.addPricesMaker(),
    method: 'POST',
    body,
  });
}

function getOutletById(sku) {
  return request({
    route: apiRoutes.getOutletById(sku),
    method: 'GET',
  });
}

function magicSearch(body) {
  return request({
    route: apiRoutes.magicSearch(),
    method: 'POST',
    body,
  });
}

function getAgents(customerId) {
  return request({
    route: apiRoutes.getPublicAgents(customerId),
    method: 'GET',
  });
}

export default {
  fetchProducts,
  getAllMakers,
  addItems,
  getAvailableRedhatByCustomer,
  getStockBySku,
  deleteShoppingCart,
  getProductInformation,
  addPricesMaker,
  getOutletById,
  magicSearch,
  getAgents,
};
