import React, { useState, useContext } from 'react';
import { translate } from 'react-i18next';
import * as XLSX from 'xlsx';

import {
  Button,
  Modal,
  Grid,
  Header,
  Label,
} from 'semantic-ui-react';

import { CartContext } from '../../context/cart-context';

import Toast from '../../common/components/toast';
import { analyticsEvent } from '../../common/utils';
import routeNames from '../../route-names';
import products from '../services';

function OcVeeam({ t, history }) {
  const { updateCartCount } = useContext(CartContext);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [pricesVeeam, setPricesVeeam] = useState([]);
  const [showButtonSave, setShowButtonSave] = useState(true);
  const [showButtonGoCart, setShowButtonGoCart] = useState(false);
  const [errorSkus, setErrorSkus] = useState([]);

  const handleClickCancel = () => {
    setOpen(false);
  };

  const handleInputChange = ({ target }) => {
    const { name } = target;
    setShowButtonGoCart(false);
    setShowButtonSave(true);
    setDisabledButton(true);
    setErrorSkus([]);
    setLoader(false);
    const sheets = [];
    if (name === 'file') {
      const reader = new window.FileReader();
      if (target.files[0]) {
        reader.readAsArrayBuffer(target.files[0]);
        reader.onloadend = e => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          workbook.SheetNames.forEach(sheetName => {
            const xlRowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            sheets.push({ data: xlRowObject, sheetName });
          });

          const sheetPrices = sheets ? sheets.filter(hoja => hoja.sheetName === 'Matriz de Precios') : null;
          if (sheetPrices && sheetPrices.length > 0) {
            const hasPrices = sheetPrices[0].data
              .filter(item => item.SKU && item['QUOTE UNIT PRICE'] > 0
              && typeof (item['QUOTE UNIT PRICE']) === 'number'
              && item.QTY > 0 && typeof (item.QTY) === 'number');
            if (hasPrices) {
              setPricesVeeam(hasPrices);
              setDisabledButton(false);
            } else {
              Toast(t('uploadFiles.incorrectFormat'), 'error');
            }
          } else {
            Toast(t('uploadFiles.notLoadedData'), 'error');
          }
        };
      } else {
        Toast(t('uploadFiles.notLoadedData'), 'error');
      }
    } else {
      Toast(t('uploadFiles.notLoadedData'), 'error');
    }
  };

  const handleClickSave = async () => {
    await products.deleteShoppingCart()
      .then(async () => {
        await updateCartCount();
        Toast(t('uploadFiles.deleteCart'), 'info');
      })
      .catch(error => {
        if (error) {
          Toast(t('uploadFiles.dontHaveCart'), 'info');
        }
      });

    const body = [];
    const pricesSku = [];
    const errors = [];
    await Promise.all(pricesVeeam.map(async element => {
      let product = await products.getProductInformation(decodeURIComponent(element.SKU))
        .catch(error => {
          if (error) errors.push(decodeURIComponent(element.SKU));
        });

      if (product) {
        if (product.brand === 'Veeam') {
          const item = {
            sku: decodeURIComponent(element.SKU),
            quantity: element.QTY,
            hpMapId: '',
          };
          body.push(item);

          const priceSku = {
            sku: decodeURIComponent(element.SKU),
            price: element['QUOTE UNIT PRICE'],
          };

          pricesSku.push(priceSku);

          analyticsEvent('Comercio', 'Agregar al carrito', decodeURIComponent(element.SKU));
        } else {
          product = null;
        }
      }

      return product;
    }));

    const bodyObject = { items: body };

    if (body.length > 0) {
      await products.addItems(bodyObject)
        .then(async response => {
          const id = response.orderId;
          if (id.length > 0) {
            const bodyAdd = {
              orderId: id,
              markerId: 'f83cdb11-3b42-4671-8b8d-b146b44f5fb3',
              items: pricesSku,
            };
            await products.addPricesMaker(bodyAdd).catch(error => {
              let messageError = '';
              if (!error) messageError = t('error-codes.default');
              else messageError = t(`error-codes.${error.code}`);
              Toast(messageError, 'error');
              setLoader(false);
            });
          }
        })
        .then(async () => {
          await updateCartCount();
          Toast(t('uploadFiles.messageAddCart'), 'success');
          setLoader(false);
          setShowButtonGoCart(true);
          setShowButtonSave(false);
        })
        .catch(error => {
          let messageError = '';
          if (!error) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
    setErrorSkus(errors);
  };

  const goShoppingCart = () => {
    history.push(routeNames.shoppingCart.route);
  };

  return (
    <Modal
      open={open}
      trigger={(
        <Button
          icon="upload"
          primary
          fluid
          content={t('uploadFiles.importPrices')}
          onClick={() => {
            setOpen(true);
          }}
        />
      )}
    >
      <Modal.Header content={<Header color="primary" textAlign="center" as="h4">{t('agents.uploadFile')}</Header>} />
      <Modal.Content>
        <Grid container>
          <Grid.Row>
            <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={15}>
              <Grid>
                <Grid.Row>
                  <input
                    required
                    type="file"
                    name="file"
                    id="file"
                    onChange={handleInputChange}
                    placeholder="Archivo de excel"
                    accept=".xlsx"
                  />
                </Grid.Row>
                <Grid.Row>
                  <p>
                    {t('uploadFiles.messageReplaceCart')}
                  </p>
                </Grid.Row>
                {
                  errorSkus.map(errorSku => (
                    <Grid.Row>
                      <Label basic color="red">
                        El sku&nbsp;
                        {errorSku}
                        &nbsp;es incorrecto.
                      </Label>
                    </Grid.Row>
                  ))
                }
                <Grid.Row>
                  <Grid.Column computer={8} tablet={8} mobile={8} floated="left">
                    { showButtonSave ? (
                      <Button
                        primary
                        loading={loader}
                        disabled={disabledButton}
                        onClick={() => {
                          handleClickSave();
                          setLoader(true);
                          setDisabledButton(true);
                        }}
                      >
                        Continuar
                      </Button>
                    ) : ''}
                  </Grid.Column>
                  <Grid.Column computer={8} tablet={8} mobile={8} floated="right">
                    { showButtonGoCart ? (
                      <Button
                        primary
                        loading={loader}
                        onClick={goShoppingCart}
                      >
                        Ir al carrito
                      </Button>
                    ) : ''}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClickCancel}>{t('adminConfiguration.cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default translate('common', { wait: true })(OcVeeam);
