const OUTLET_WAREHOUSES = [
  'MI03',
  'GI03',
  'YI03',
];

export const validateFieldValueExist = (fieldName, value, catalog = []) => (
  catalog.some(element => element[fieldName] === value)
);

export const getFieldNameHashObject = (fieldName, catalog = []) => {
  const hashTable = {};
  catalog.map(currentElement => {
    const fieldValue = currentElement[fieldName];
    if (!hashTable[fieldValue]) hashTable[fieldValue] = 1;
    else hashTable[fieldValue] += 1;
    return null;
  });
  return hashTable;
};

export const getFieldNameKeys = (fieldName, catalog = []) => {
  const hashTable = getFieldNameHashObject(fieldName, catalog);
  return Object.keys(hashTable);
};

export const getBrands = (catalog = []) => (
  getFieldNameKeys('maker', catalog).map(current => ({ name: current }))
);

export const getBranches = (catalog = []) => (
  getFieldNameKeys('branch', catalog).map(current => ({ name: current }))
);

export const currencyConverter = (
  amount = 0, fromCurrency = 'MXN',
  toCurrency = 'USD',
  exchangeRate = {
    cash: 1, credit: 1, microsoft: 1, time: Date().toString(),
  },
  payment = 'cash',
) => {
  let currentExchange = exchangeRate[payment];
  currentExchange = currentExchange <= 0 ? 1 : currentExchange;
  const formatedFromCurrency = fromCurrency === 'MXN' ? fromCurrency : 'USD';
  if (formatedFromCurrency === toCurrency) return amount;
  switch (toCurrency) {
    case 'USD':
      return amount / currentExchange;
    case 'MXN':
      return amount * currentExchange;
    default:
      return amount;
  }
};

export const getTotals = (catalog = [], state, stepped = {}) => {
  const {
    shippingCost, currency, exchangeRate, payment: paymentType,
  } = state;
  const result = catalog.reduce((previousCounters, currentProduct) => {
    let { subtotal, discount } = previousCounters;
    const {
      price, promotionPrice, quantity, mcAfeeQty, currency: itemCurrency, maker, sku, hpMapId, price7, category,
    } = currentProduct;
    let promotionToUse = promotionPrice;
    if (hpMapId && hpMapId !== '') {
      const volumeDiscount = stepped[sku]
        ? stepped[sku].find(stepPrice => quantity >= stepPrice.min && quantity <= stepPrice.max)
        : null;
      promotionToUse = volumeDiscount ? volumeDiscount.price : promotionPrice;
    }
    const realPrice = !promotionToUse ? price : promotionToUse;
    let payment = '';
    if (paymentType === 1) payment = 'credit';
    else payment = 'cash';
    const convertedPrice = currencyConverter(realPrice, itemCurrency, currency, exchangeRate, payment);
    const convertedPriceBase = currencyConverter(price, itemCurrency, currency, exchangeRate, payment);
    const PriceAutodeskCourse = Number(convertedPrice) + (quantity - 1) * price7;
    if (maker === 'Trellix' && mcAfeeQty > 0) {
      subtotal = (convertedPrice * (quantity * mcAfeeQty)) + subtotal;
      discount = ((convertedPriceBase - convertedPrice) * (quantity * mcAfeeQty)) + discount;
    }
    if (maker === 'CS-Autodesk' && category === 'Curso') {
      subtotal = PriceAutodeskCourse + subtotal;
      discount = ((convertedPriceBase - convertedPrice) * (quantity)) + discount;
    } else {
      subtotal = (convertedPrice * quantity) + subtotal;
      discount = ((convertedPriceBase - convertedPrice) * (quantity)) + discount;
    }
    return ({ subtotal, discount });
  }, { subtotal: 0, discount: 0 });
  result.iva = (result.subtotal + shippingCost) * 0.16;
  result.total = result.iva + (result.subtotal + shippingCost);
  return result;
};

export const isSingleDelivery = items => {
  let deliveryConfig = {};
  let singleDelivery = false;
  const allConfigurations = items.map(item => (item.deliveryConfig)).flat();
  const branchOfficeKeys = getFieldNameKeys('branchOfficeId', allConfigurations);
  const pickWarehouseKeys = getFieldNameKeys('pickWarehouse', allConfigurations);
  const hasAgentPrice = allConfigurations.some(config => config.AgentPrice);
  const insureKeys = getFieldNameKeys('UPSinsure', allConfigurations);
  const courierServiceKeys = getFieldNameKeys('courierServiceId', allConfigurations);
  if (branchOfficeKeys.length === 1 && insureKeys.length === 1 && courierServiceKeys.length === 1 && !hasAgentPrice) {
    deliveryConfig = {
      branchOfficeId: branchOfficeKeys[0],
      UPSinsure: insureKeys[0],
      pickWarehouse: pickWarehouseKeys.length > 0 ? pickWarehouseKeys[0] : '',
      courierServiceId: Number(courierServiceKeys[0]),
    };
    singleDelivery = true;
  } else deliveryConfig = { items };
  return { singleDelivery, deliveryConfig, hasAgentPrice };
};

function warehouseType(item, origin) {
  const isOutletWarehouse = OUTLET_WAREHOUSES.find(id => id === origin.warehouse);
  if (origin.sku !== item.sku) return false;
  return item.outlet
    ? isOutletWarehouse
    : !isOutletWarehouse;
}

export const formatDeliveryConfig = (
  courierServiceId = 0,
  UPSinsure = false,
  branchOfficeId = '',
  items = [],
  origins = [],
  pickWarehouse = '',
) => {
  const onlyDeliveryItems = items.filter(item => item.hasDelivery);
  const validatedOrigin = !origins ? [] : origins;
  const deliveryConfig = onlyDeliveryItems.map(item => {
    const skuConfig = {
      UPSinsure, branchOfficeId, courierServiceId, sku: item.sku, pickWarehouse,
    };
    skuConfig.source = validatedOrigin
      .filter(origin => warehouseType(item, origin))
      .map(origin => ({ quantity: origin.quantity, warehouse: origin.warehouse }));
    return skuConfig;
  });
  return deliveryConfig;
};
