/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import {
  Loader,
  Grid,
  Icon,
  Header,
  Input,
  Segment,
} from 'semantic-ui-react';
import Toast from '../common/components/toast';
import ProductList from './components/product-list';
import NotFound from './components/not-found';

import './style.css';

import * as session from '../common/sessions';
import { analyticsEvent } from '../common/utils';
import fetchService from './services';

function ProductCatalogIa(props) {
  const { customerId, userName } = session.get();

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [infoSearch, setInfoSearch] = useState('');
  const [valueSearch, setValue] = useState('');
  const [dataAgents, setDataAgents] = useState([]);
  const [notFound, setNotFound] = useState(false);

  const handleInput = (e, { value }) => {
    setValue(value);
  };

  useEffect(() => {
    const { t } = props;

    async function handleGetAgents() {
      await fetchService.getAgents(customerId).then(agentsResponse => {
        setDataAgents(agentsResponse.agents);
      }).catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = error.message;
        return messageError;
      });
    }
    handleGetAgents();
  }, [props, customerId]);

  async function showResultMagicSearch() {
    const body = {
      searchAI: valueSearch,
    };
    setLoading(true);
    analyticsEvent('ProductCatalogIA', 'Search', valueSearch);
    await fetchService.magicSearch(body)
      .then(response => {
        if (response.items.length <= 0 || response.resultText.length <= 0) {
          setNotFound(true);
          setLoading(false);
        } else {
          setNotFound(false);
          setLoading(false);
          setItems(response.items);
          setInfoSearch(response.resultText);
        }
      })
      .catch(error => {
        setNotFound(true);
        setLoading(false);
        Toast(error, 'error');
      });
  }

  const handleKeyPress = e => {
    if (e.which === 13) {
      showResultMagicSearch();
    }
  };
  return (
    <Grid container>
      <Grid.Row style={{ paddingTop: '3rem' }}>
        <Grid.Column width={16}>
          <Header className="welcome">
            ¡Hola
            {' '}
            {userName}
            !
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: '.2rem' }} className="animatedBorderBoxGlow">
        <Grid.Column verticalAlign="middle" className="animatedBorderBox no-padding">
          <Input
            icon={<Icon name="search" inverted circular link color="blue" onClick={() => showResultMagicSearch()} />}
            placeholder="Search..."
            fluid
            className="inputIa"
            value={valueSearch}
            onChange={handleInput}
            onKeyPress={handleKeyPress}
          />
        </Grid.Column>
      </Grid.Row>
      {loading
        ? (
          <Grid.Row className="padding-top-big">
            <Loader
              size="large"
              style={{ display: 'inline-block' }}
              active
              inline="centered"
            >
              Preparando los mejores resultados
            </Loader>
          </Grid.Row>
        ) : (
          <Grid>
            {notFound ? (
              <Grid.Row className="padding-top-big">
                <Grid.Column computer={16}>
                  <NotFound dataAgents={dataAgents} />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row className="padding-top-big">
                <Grid.Column mobile={16} tablet={16} computer={7} largeScreen={7} widescreen={7} textAlign="left">
                  <p className="fontSizeInfo" style={{ whiteSpace: 'pre-line' }}>
                    {infoSearch}
                  </p>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={9} largeScreen={9} widescreen={9} textAlign="right">
                  {items.length > 0 && (
                    <Segment className="diseno-scroll">
                      <ProductList
                        items={items}
                        dataAgents={dataAgents}
                      />
                    </Segment>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        )}
    </Grid>
  );
}

export default ProductCatalogIa;
