import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Header, Icon, Label, Image,
} from 'semantic-ui-react';
import assets from '../images';
import '../style.css';

const { agentsDummy } = assets;

function NotFound({ dataAgents }) {
  const arrayAgents = dataAgents.length > 0 ? dataAgents : agentsDummy;
  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Header as="h4" icon>
            <Icon name="search" />
            ¿No encontraste lo que buscabas?
          </Header>
        </Grid.Column>
        <Grid.Column width={16} textAlign="center">
          <p className="fontSizePrice">No te preocupes, ponte en contacto con alguno de los agentes para una búsqueda personalizada:</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>

        {arrayAgents.map(agent => (
          <Grid.Column mobile={16} tablet={8} computer={4} largeScreen={4} widescreen={4} textAlign="center" className="margin-bottom">
            <Label as="a" style={{ width: '100%' }}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={6} textAlign="right" className="no-padding">
                    <a href={`mailto:${agent.mail}`} label="button">
                      <Icon name="mail outline" color="blue" size="large" />
                    </a>
                    <a href={`https://wa.me/${agent.whatsapp}?text=${encodeURIComponent(`¡Hola! Quisiera que me dieran mayor información de articulos de la marca ${agent.maker}`)}`} target="_blank" rel="noopener noreferrer" label="button">
                      <Icon name="whatsapp" color="green" size="large" />
                    </a>
                    <Image avatar spaced="right" src={agent.image} />
                  </Grid.Column>
                  <Grid.Column width={10} textAlign="center">
                    <p className="no-margin">
                      Marca
                      {' '}
                      {agent.maker}
                    </p>
                    <p>{agent.agent}</p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Label>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
    // <div
    //   style={{
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     height: '100%',
    //     width: '90%',
    //     border: 'none',
    //     flexDirection: 'column',
    //   }}
    // >

  //   <div>
  //   </div>
  // </div>
  );
}

NotFound.defaultProps = {
  dataAgents: [],
};

NotFound.propTypes = {
  dataAgents: PropTypes.arrayOf(PropTypes.shape({})),
};

export default NotFound;
