import React, { Component } from 'react';
import {
  Image, Grid, Container, Header, Input, Icon, Divider, Modal, Button, Responsive, Popup, Loader, Message, FormField,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import services from '../services';
import { currencyFormat } from '../../common/utils';
import { currencyConverter } from '../special-validation';
import Availability from '../../product-catalog/components/availability';

const baseUrl = process.env.REACT_APP_ENDPOINT;

const Details = ({ name, sku, sku2 }) => {
  const encodedSku = encodeURIComponent(sku);
  const detailPath = `${baseUrl}/producto/detalle/${encodedSku}`;
  return (
    <Container className="no-margin">
      <Header as="h5" className="no-margin" color="blue" textAlign="justified" style={{ cursor: 'pointer' }} onClick={() => window.open(detailPath, '_blank')}>{name}</Header>
      <Header as="h5" className="no-margin">
        SKU:
        {' '}
        {sku}
      </Header>
      <Header hidden={!sku2 || sku2 === ''} as="h6" className="no-margin">
        SKU2:
        {' '}
        {sku2}
      </Header>
    </Container>
  );
};

const ImageOutlet = ({ validatedImage, sku, t }) => {
  const encodedSku = encodeURIComponent(sku);
  const detailPath = `${baseUrl}/producto/detalle/${encodedSku}`;
  return (
    <Image
      src={validatedImage.replace('http://', 'https://')}
      size="small"
      as="a"
      href={null}
      alt=""
      label={{
        color: 'red',
        ribbon: true,
        content: t('products.outlet'),
      }}
      style={{
        cursor: 'pointer',
        objectFit: 'contain',
        backgroundColor: '#ffffff',
      }}
      onClick={() => window.open(detailPath, '_blank')}
    />
  );
};

const ImageDetail = ({ validatedImage, sku }) => {
  const encodedSku = encodeURIComponent(sku);
  const detailPath = `${baseUrl}/producto/detalle/${encodedSku}`;
  return (
    <Image
      src={validatedImage.replace('http://', 'https://')}
      size="small"
      as="a"
      href={null}
      alt=""
      style={{
        cursor: 'pointer',
        objectFit: 'contain',
        backgroundColor: '#ffffff',
      }}
      onClick={() => window.open(detailPath, '_blank')}
    />
  );
};

const FROMCHANGE = 'change';
const FROMDELETE = 'delete';

class ItemContent extends Component {
  constructor(props) {
    super(props);
    const { item } = this.props;
    const mcAfCount = item.mcAfeeQty || 1;
    this.state = {
      count: item.quantity,
      tempCount: item.quantity,
      agentPriceModal: false,
      modalOrigin: '',
      warehouses: [],
      error: '',
      totalStock: 0,
      activateDeleteProduct: true,
      mcAfCount,
      showUpdate: false,
    };
  }

  componentDidMount() {
    this.getProductAvailability();
  }

  componentDidUpdate(prevProps) {
    const { quantity } = prevProps.item;
    const { item } = this.props;
    if (quantity !== item.quantity) {
      this.updatePropsQuantity(item.quantity);
    }
  }

  updatePropsQuantity = count => this.setState({ count });

  decrementCount = () => {
    const { count } = this.state;
    const newcount = count - 1;
    this.handleQuantityMob(newcount);
  };

  incrementCount = () => {
    const { count } = this.state;
    const newcount = count + 1;
    this.handleQuantityMob(newcount);
  };

  handleQuantityMob = value => {
    const { agentPriceModal, mcAfCount } = this.state;
    const { item, hasAgentPrice, onChange } = this.props;
    if (hasAgentPrice) {
      this.setState({
        tempCount: value,
        modalOrigin: FROMCHANGE,
        agentPriceModal: true,
      });
      return;
    }
    this.setState({ count: value, showUpdate: true });
    if (agentPriceModal) return;
    const mcValue = !mcAfCount ? 1 : mcAfCount;
    const roundedQuantity = Math.round(value);
    const roundedMcQty = Math.round(mcValue);
    const validNumber = this.validateQuantity(roundedQuantity);
    const validMcNumber = this.validateQuantity(roundedMcQty);
    this.setState({ count: validNumber, mcAfCount: validMcNumber, showUpdate: false });
    onChange({
      sku: item.sku,
      quantity: validNumber,
      outlet: item.outlet,
      mcAfeeQty: validMcNumber,
    }, true);
  }

  validateQuantity = value => {
    if (value > 0) return value;
    if (value === 0 || value < 0) return 1;
    return value;
  }

  handleQuantity = (e, { value }) => {
    const { item, hasAgentPrice, onChange } = this.props;
    if (hasAgentPrice) {
      this.setState({
        tempCount: value,
        modalOrigin: FROMCHANGE,
        agentPriceModal: true,
      });
      return;
    }
    const roundedQuantity = (typeof value === 'string') ? value : Math.round(value);
    const validNumber = this.validateQuantity(Number(roundedQuantity));
    this.setState({ count: validNumber, showUpdate: true });
    onChange({
      sku: item.sku,
      quantity: validNumber,
      outlet: item.outlet,
    });
  }

  handleMcAfeeQuantity = (e, { value }) => {
    const {
      item, hasAgentPrice, quantity, onChange,
    } = this.props;
    if (hasAgentPrice) {
      this.setState({
        tempCount: value,
        modalOrigin: FROMCHANGE,
        agentPriceModal: true,
      });
      return;
    }
    const roundedQuantity = (typeof value === 'string') ? value : Math.round(value);
    const validNumber = this.validateQuantity(roundedQuantity);
    this.setState({ mcAfCount: validNumber });
    onChange({
      sku: item.sku,
      mcAfeeQty: validNumber,
      quantity,
      outlet: item.outlet,
    });
  }

  handleDelete = () => {
    const {
      item, hasAgentPrice, deleteShoppingCartProduct, actionLoader,
    } = this.props;
    if (actionLoader) {
      this.setState({
        activateDeleteProduct: true,
      });
    } else {
      this.setState({
        activateDeleteProduct: false,
      });
    }
    if (hasAgentPrice) {
      this.setState({
        modalOrigin: FROMDELETE,
        agentPriceModal: true,
      });
      return;
    }
    deleteShoppingCartProduct(item.sku, item.outlet);
  }

  handleModalConfirm = () => {
    const { modalOrigin, tempCount } = this.state;
    const {
      item, handleBoolField, deleteShoppingCartProduct, onChange,
    } = this.props;
    let roundedQuantity;
    let validNumber;
    let value;
    switch (modalOrigin) {
      case FROMCHANGE:
        value = !tempCount ? 1 : tempCount;
        roundedQuantity = (typeof value === 'string') ? value : Math.round(value);
        validNumber = this.validateQuantity(roundedQuantity);
        this.setState({
          count: validNumber,
          agentPriceModal: false,
        });
        onChange({
          sku: item.sku,
          quantity: validNumber,
          outlet: item.outlet,
        }, true);
        handleBoolField('hasAgentPrice', false);
        break;
      case FROMDELETE:
        this.setState({ agentPriceModal: false });
        deleteShoppingCartProduct(item.sku, item.outlet);
        handleBoolField('hasAgentPrice', false);
        break;
      default:
        break;
    }
  }

  handleBlur = () => {
    const { count, agentPriceModal, mcAfCount } = this.state;
    if (agentPriceModal) return;
    const { item, onChange } = this.props;
    const value = !count ? 1 : count;
    const mcValue = !mcAfCount ? 1 : mcAfCount;
    const roundedQuantity = Math.round(value);
    const roundedMcQty = Math.round(mcValue);
    const validNumber = this.validateQuantity(roundedQuantity);
    const validMcNumber = this.validateQuantity(roundedMcQty);
    this.setState({ count: validNumber, mcAfCount: validMcNumber, showUpdate: false });
    onChange({
      sku: item.sku,
      quantity: validNumber,
      outlet: item.outlet,
      mcAfeeQty: validMcNumber,
    }, true);
  }

  isValidQuantity = (requestedQuantity, isOutlet, { minQuantity, maxQuantity }) => {
    if (!isOutlet
      && ((minQuantity > 0 && requestedQuantity < minQuantity)
        || (maxQuantity > 0 && requestedQuantity > maxQuantity))) {
      return false;
    }
    return true;
  }

  getQuantityLimitText = (currentQuantity, { minQuantity, maxQuantity }) => {
    const { t } = this.props;
    if ((minQuantity > 0 && minQuantity > currentQuantity) && (maxQuantity > 0 && maxQuantity < currentQuantity)) {
      return t('products.minAndMaxQuantity', { max: maxQuantity, min: minQuantity });
    }
    if (currentQuantity < minQuantity) {
      return t('products.minQuantity', { min: minQuantity });
    }
    return t('products.maxQuantity', { max: maxQuantity });
  };

  getProductAvailability = async () => {
    const { tr: t, item } = this.props;
    this.setState({ activateDeleteProduct: true });
    const sku = encodeURIComponent(item.sku);
    if (item.outlet) {
      const { stock } = await services.getOutletById(sku);
      const total = stock.reduce((accumulator, itemStock) => accumulator + itemStock.stock, 0);
      this.setState({ warehouses: stock, totalStock: total });
    } else {
      services.getStockBySku(sku)
        .then(response => {
          const total = response.stock.reduce((accumulator, availability) => accumulator + availability.stock, 0);
          this.setState({ warehouses: response.stock, totalStock: total });
        })
        .catch(error => {
          const errorString = error.code === 1005 ? 'products.error.1005' : `error-code.${error.code}`;
          this.setState({ error: t(errorString) });
        });
    }
  };

  mobileContent = props => {
    const { activateDeleteProduct, showUpdate } = this.state;
    const { tr: t, hpeIquoteId } = this.props;
    const {
      sku, sku2, image, name, count, mcAfCount, strikePrice, currency, convertedPrice, total, outlet, maker, category, hpMapId, branch, price7,
      previousPriceO, currentPriceO, priceChanged, currentPromotionPriceO, previousPromotionPriceO, priceChangedPromotion, validDifferenceDate, totalAutodesk,
    } = props;
    const validatedImage = image || '';
    const isArubaSupport = branch === 'ARUBA-SERVICES' || branch === 'ARUBA-SOFTWARE';
    return (
      <Grid key={sku}>
        <Grid.Row className="no-padding-bottom">
          <Grid.Column verticalAlign="middle" mobile={5} className="no-padding">
            {
              outlet
                ? <ImageOutlet validatedImage={validatedImage} sku={sku} t={t} />
                : <ImageDetail validatedImage={validatedImage} sku={sku} />
            }
          </Grid.Column>
          <Grid.Column verticalAlign="middle" mobile={11}>
            <Details name={name} sku={sku} sku2={sku2} />
            <br />
            <Grid.Row>
              {this.renderDropdownAvailability({ sku, isOutlet: outlet })}
            </Grid.Row>
            {(maker === 'Trellix' && category === 'Ciberseguridad Suscripcion')
              ? (
                <Grid>
                  <Grid.Row style={{ margin: '1rem 0' }}>
                    <Grid.Column className="no-padding-right" verticalAlign="middle" textAlign="left" tablet={9} computer={9} largeScreen={9} widescreen={9}>
                      <Header as="h5"> Años de soporte: </Header>
                    </Grid.Column>
                    <Grid.Column onBlur={this.handleBlur} verticalAlign="middle" textAlign="center" tablet={7} computer={7} largeScreen={7} widescreen={7}>
                      <Input
                        style={{ width: '70px' }}
                        type="number"
                        value={mcAfCount}
                        onChange={this.handleMcAfeeQuantity}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )
              : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding-bottom">
          <Grid.Column verticalAlign="middle" mobile={5} className="no-padding" />
          <Grid.Column verticalAlign="middle" mobile={11}>
            <Grid>
              <Grid.Row>
                <Grid.Column verticalAlign="top" textAlign="left" mobile={7} className="no-padding-right">
                  <Header as="h5">{t('shoppingCart.itemHeaders.unitPrice')}</Header>
                </Grid.Column>
                <Grid.Column verticalAlign="middle" textAlign="right" mobile={9}>
                  { strikePrice > 0 ? (
                    <strike>
                      <Header as="h5">
                        {currencyFormat(strikePrice, currency)}
                      </Header>
                    </strike>
                  ) : null}
                  {(category === 'Curso' && maker === 'CS-Autodesk')
                    ? (
                      <>
                        <Header as="h5" color="green">
                          Un Usuario:
                          <br />
                          {currencyFormat(convertedPrice, currency)}
                          {' '}
                        </Header>
                        <Header as="h5" color="green">
                          Usuario adicional:
                          <br />
                          {currencyFormat(price7, currency)}
                          {' '}
                        </Header>
                      </>
                    )
                    : (
                      <Header as="h5" color="green">
                        {' '}
                        {currencyFormat(convertedPrice, currency)}
                        {' '}
                      </Header>
                    ) }
                  { (priceChanged && validDifferenceDate)
                    ? (
                      <Header as="h5" color="yellow">
                        <Icon name="exclamation circle icon" />
                        {`Atención, el precio de tu producto ha cambiado de ${currencyFormat(previousPriceO)} a ${currencyFormat(currentPriceO)}`}
                      </Header>
                    )
                    : ''}
                  { (priceChangedPromotion && validDifferenceDate)
                    ? (
                      <Header as="h5" color="yellow">
                        <Icon name="exclamation circle icon" />
                        {`Atención, el precio de tu producto ha cambiado de ${currencyFormat(previousPromotionPriceO)} a ${currencyFormat(currentPromotionPriceO)}`}
                      </Header>
                    )
                    : ''}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding-bottom">
          <Grid.Column verticalAlign="middle" mobile={5} className="no-padding" />
          <Grid.Column verticalAlign="middle" mobile={11}>
            <Grid>
              <Grid.Row>
                <Grid.Column verticalAlign="middle" textAlign="center" mobile={4}>
                  { (activateDeleteProduct)
                    ? (
                      <Icon
                        name="trash alternate"
                        disabled={Boolean(hpeIquoteId)}
                        size="large"
                        style={{ cursor: 'pointer' }}
                        onClick={this.handleDelete}
                      />
                    )
                    : <Loader active inline />}
                </Grid.Column>
                <Grid.Column verticalAlign="middle" textAlign="center" mobile={4} className="no-padding">
                  <Button
                    disabled={!(count > 1) || isArubaSupport}
                    type="button"
                    basic
                    color="grey"
                    size="tiny"
                    onClick={this.decrementCount}
                  >
                    <Icon
                      name="minus"
                      color="black"
                    />
                  </Button>
                </Grid.Column>
                <Grid.Column verticalAlign="middle" textAlign="center" mobile={4}>
                  <p>{count}</p>
                </Grid.Column>
                <Grid.Column verticalAlign="middle" textAlign="center" mobile={4} className="no-padding">
                  <Button type="button" basic color="grey" size="tiny" onClick={this.incrementCount} disabled={isArubaSupport}>
                    <Icon
                      name="plus"
                      color="black"
                    />
                  </Button>
                </Grid.Column>
              </Grid.Row>
              {
                showUpdate
                  ? (
                    <Grid.Row centered className="no-padding-top">
                      <Grid.Column verticalAlign="middle" textAlign="center" mobile={6}>
                        <Button
                          primary
                          size="mini"
                          fluid
                        >
                          {t('Actualizar')}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  )
                  : null
              }
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding-bottom">
          <Grid.Column verticalAlign="middle" mobile={5} className="no-padding" />
          <Grid.Column verticalAlign="middle" mobile={11}>
            <Grid>
              <Grid.Row>
                <Grid.Column verticalAlign="middle" textAlign="left" mobile={6} className="no-padding-right">
                  <Header as="h5">{t('shoppingCart.itemHeaders.total')}</Header>
                </Grid.Column>
                <Grid.Column verticalAlign="middle" textAlign="right" mobile={10}>
                  {(category === 'Curso' && maker === 'CS-Autodesk')
                    ? (
                      <Header as="h5" color="green">
                        {' '}
                        {currencyFormat(totalAutodesk, currency)}
                        {' '}
                      </Header>
                    )
                    : (
                      <Header as="h5" color="green">
                        {' '}
                        {currencyFormat(total, currency)}
                        {' '}
                      </Header>
                    ) }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        {hpMapId && (
          <Grid.Row>
            <Grid.Column tablet={16} computer={16} largeScreen={16} widescreen={16}>
              <Message size="tiny" color="green">
                *En caso de aplicar, el precio unitario de los productos por volumen cambiará en el checkout
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
            <Divider />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  webContent = props => {
    const { activateDeleteProduct, showUpdate } = this.state;
    const { tr: t, hpeIquoteId } = this.props;
    const {
      sku, sku2, image, name, count, mcAfCount, strikePrice, currency, convertedPrice, total, outlet, maker, category, hpMapId, branch, price7,
      previousPriceO, currentPriceO, priceChanged, currentPromotionPriceO, previousPromotionPriceO, priceChangedPromotion, validDifferenceDate,
      minQuantity, maxQuantity, totalAutodesk,
    } = props;
    const validatedImage = image || '';
    const isArubaSupport = branch === 'ARUBA-SERVICES' || branch === 'ARUBA-SOFTWARE';
    return (
      <Grid key={sku}>
        <Grid.Row>
          <Grid.Column verticalAlign="middle" tablet={2} computer={2} largeScreen={2} widescreen={2}>
            {
              outlet
                ? <ImageOutlet validatedImage={validatedImage} sku={sku} t={t} />
                : <ImageDetail validatedImage={validatedImage} sku={sku} />
            }
          </Grid.Column>
          <Grid.Column verticalAlign="middle" tablet={4} computer={4} largeScreen={4} widescreen={4}>
            <Details name={name} sku={sku} sku2={sku2} />
            <br />
            <Grid.Row>
              {this.renderDropdownAvailability({ sku, isOutlet: outlet })}
            </Grid.Row>
            {(maker === 'Trellix' && category === 'Ciberseguridad Suscripcion')
              ? (
                <Grid>
                  <Grid.Row style={{ margin: '1rem 0' }}>
                    <Grid.Column className="no-padding-right" verticalAlign="middle" textAlign="left" tablet={9} computer={9} largeScreen={9} widescreen={9}>
                      <Header as="h5"> Años de soporte: </Header>
                    </Grid.Column>
                    <Grid.Column onBlur={this.handleBlur} verticalAlign="middle" textAlign="center" tablet={7} computer={7} largeScreen={7} widescreen={7}>
                      <Input
                        style={{ width: '70px' }}
                        type="number"
                        value={mcAfCount}
                        onChange={this.handleMcAfeeQuantity}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )
              : null}
          </Grid.Column>
          <Grid.Column onBlur={this.handleBlur} verticalAlign="middle" tablet={3} computer={3} largeScreen={3} widescreen={3} floated="right">
            <FormField
              fluid
              type="number"
              control={Input}
              value={count}
              onChange={this.handleQuantity}
              disabled={isArubaSupport}
              error={!this.isValidQuantity(count, outlet, { minQuantity, maxQuantity }) ? {
                content: `* ${this.getQuantityLimitText(count, { minQuantity, maxQuantity })}`,
                pointing: 'below',
                color: 'yellow',
              } : false}
            />
            {
              showUpdate
                ? (
                  <Button
                    primary
                    size="mini"
                    fluid
                  >
                    {t('Actualizar')}
                  </Button>
                )
                : null
            }
          </Grid.Column>
          <Grid.Column
            verticalAlign="middle"
            textAlign="right"
            tablet={3}
            computer={3}
            largeScreen={3}
            widescreen={3}
          >
            <Grid>
              {
                strikePrice > 0
                  ? (
                    <Grid.Row>
                      <Grid.Column>
                        <strike>
                          <Header as="h5">
                            {' '}
                            {currencyFormat(strikePrice, currency)}
                          </Header>
                        </strike>
                      </Grid.Column>
                    </Grid.Row>
                  )
                  : null
              }
              <Grid.Row>
                <Grid.Column textAlign="right">
                  {(category === 'Curso' && maker === 'CS-Autodesk')
                    ? (
                      <>
                        <Header as="h5" color="green">
                          Un Usuario:
                          <br />
                          {currencyFormat(convertedPrice, currency)}
                          {' '}
                        </Header>
                        <Header as="h5" color="green">
                          Usuario adicional:
                          <br />
                          {currencyFormat(price7, currency)}
                          {' '}
                        </Header>
                      </>
                    )
                    : (
                      <Header as="h5" color="green">
                        {' '}
                        {currencyFormat(convertedPrice, currency)}
                        {' '}
                      </Header>
                    ) }
                  { (priceChanged && validDifferenceDate)
                    ? (
                      <Header as="h5" color="yellow">
                        <Icon name="exclamation circle icon" />
                        {`Atención, el precio de tu producto ha cambiado de ${currencyFormat(previousPriceO)} a ${currencyFormat(currentPriceO)}`}
                      </Header>
                    )
                    : ''}
                  { (priceChangedPromotion && validDifferenceDate)
                    ? (
                      <Header as="h5" color="yellow">
                        <Icon name="exclamation circle icon" />
                        {`Atención, el precio de tu producto ha cambiado de ${currencyFormat(previousPromotionPriceO)} a ${currencyFormat(currentPromotionPriceO)}`}
                      </Header>
                    )
                    : ''}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" textAlign="right" tablet={3} computer={3} largeScreen={3} widescreen={3}>
            {(category === 'Curso' && maker === 'CS-Autodesk')
              ? (
                <Header as="h5" color="green">
                  {' '}
                  {currencyFormat(totalAutodesk, currency)}
                  {' '}
                </Header>
              )
              : (
                <Header as="h5" color="green">
                  {' '}
                  {currencyFormat(total, currency)}
                  {' '}
                </Header>
              ) }
          </Grid.Column>
          <Grid.Column verticalAlign="middle" tablet={1} computer={1} largeScreen={1} widescreen={1}>
            { (activateDeleteProduct)
              ? (
                <Icon
                  name="trash alternate"
                  disabled={Boolean(hpeIquoteId)}
                  size="large"
                  style={{ cursor: 'pointer' }}
                  onClick={this.handleDelete}
                />
              )
              : <Loader active inline />}
          </Grid.Column>
        </Grid.Row>

        {hpMapId && (
          <Grid.Row>
            <Grid.Column tablet={16} computer={16} largeScreen={16} widescreen={16}>
              <Message size="small" color="green">
                *En caso de aplicar, el precio unitario de los productos por volumen cambiará en el checkout
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} largeScreen={16} widescreen={16}>
            <Divider />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderWarehouses = () => {
    const { error, warehouses } = this.state;
    const { tr: t } = this.props;
    return !error
      ? (
        <Grid padded style={{ width: '18rem' }}>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header color="blue" size="small">{t('availability.branchOffice')}</Header>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header color="blue" size="small">{t('availability.quantity')}</Header>
            </Grid.Column>
          </Grid.Row>
          {warehouses.map(warehouse => (
            <Grid.Row>
              <Grid.Column width={8}>
                <Header size="small">{t(`availability.warehouses.${warehouse.warehouse}`)}</Header>
              </Grid.Column>
              <Grid.Column width={8}>
                <Header size="small">{warehouse.stock}</Header>
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      )
      : (
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <Header size="small">{error}</Header>
          </Grid.Column>
        </Grid.Row>
      );
  };

  renderDropdownAvailability = ({ sku, isOutlet }) => {
    const { totalStock } = this.state;
    const { tr: t } = this.props;
    return (
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
          <Header as="h5">
            {`${t('products.available')} `}
            <Popup
              on="click"
              position="bottom left"
              content={<Availability sku={sku} isOutlet={isOutlet} />}
              trigger={(
                <Header
                  as="h5"
                  style={{ cursor: 'pointer', textDecoration: 'underline', display: 'inline' }}
                  color="blue"
                  onClick={() => (this.setState({}))}
                >
                  {totalStock || t('products.notAvailable')}
                </Header>
              )}
            />
          </Header>
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    const {
      count, agentPriceModal, mcAfCount,
    } = this.state;
    const {
      currency, exchangeRate, paymentType, item, tr: t, actionLoader,
    } = this.props;
    const {
      image, name, sku, sku2, price, currency: itemCurrency, promotionPrice, outlet, maker, category, hpMapId, branch, price7, previousPriceO, currentPriceO, previousPromotionPriceO, currentPromotionPriceO, dateLogO, dateCreated,
      minQuantity, maxQuantity,
    } = item;
    const dateLogPrice = moment(dateLogO).utc().format('YYYY-MM-DD HH:mm:ss');
    const priceChanged = previousPriceO !== currentPriceO && !promotionPrice;
    const priceChangedPromotion = previousPromotionPriceO !== currentPromotionPriceO && promotionPrice;
    const validDifferenceDate = dateLogPrice > dateCreated;
    const validPrice = !promotionPrice ? price : promotionPrice;
    let payment = '';
    if (itemCurrency === 'MS-USD') payment = 'microsoft';
    else if (paymentType === 1) payment = 'credit';
    else payment = 'cash';
    const convertedPrice = currencyConverter(validPrice, itemCurrency, currency, exchangeRate, payment);
    const strikePrice = !promotionPrice ? '' : currencyConverter(price, itemCurrency, currency, exchangeRate, payment);
    const total = (Number(count) * Number(mcAfCount)) * Number(convertedPrice);
    const totalAutodesk = Number(convertedPrice) + (item.quantity - 1) * item.price7;
    return (
      <Grid key={sku}>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
              {
                this.webContent({
                  sku,
                  sku2,
                  image,
                  name,
                  count,
                  mcAfCount,
                  strikePrice,
                  currency,
                  convertedPrice,
                  total,
                  outlet,
                  maker,
                  category,
                  actionLoader,
                  hpMapId,
                  branch,
                  priceChanged,
                  priceChangedPromotion,
                  previousPriceO,
                  currentPriceO,
                  previousPromotionPriceO,
                  currentPromotionPriceO,
                  validDifferenceDate,
                  minQuantity,
                  maxQuantity,
                  price7,
                  totalAutodesk,
                })
              }
            </Responsive>
            <Responsive minWidth={Responsive.onlyMobile.minWidth} maxWidth={Responsive.onlyMobile.maxWidth}>
              {
                this.mobileContent({
                  sku,
                  sku2,
                  image,
                  name,
                  count,
                  mcAfCount,
                  strikePrice,
                  currency,
                  convertedPrice,
                  total,
                  outlet,
                  maker,
                  category,
                  actionLoader,
                  hpMapId,
                  branch,
                  priceChanged,
                  priceChangedPromotion,
                  previousPriceO,
                  currentPriceO,
                  previousPromotionPriceO,
                  currentPromotionPriceO,
                  validDifferenceDate,
                  minQuantity,
                  maxQuantity,
                  price7,
                  totalAutodesk,
                })
              }
            </Responsive>
          </Grid.Column>
        </Grid.Row>
        <Modal size="mini" open={agentPriceModal} onClose={() => this.setState({ agentPriceModal: false })}>
          <Modal.Header as="header" color="blue">{t('caution')}</Modal.Header>
          <Modal.Content>
            <p>{t('shoppingCart.shippinCostConfirmationMessage')}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={() => this.handleModalConfirm()}>
              {t('continue')}
            </Button>
            <Button color="grey" onClick={() => this.setState({ agentPriceModal: false })}>
              {t('cancel')}
            </Button>
          </Modal.Actions>
        </Modal>
      </Grid>
    );
  }
}

ItemContent.propTypes = {
  currency: PropTypes.string.isRequired,
  deleteShoppingCartProduct: PropTypes.func.isRequired,
  exchangeRate: PropTypes.string.isRequired,
  handleBoolField: PropTypes.func.isRequired,
  hasAgentPrice: PropTypes.bool.isRequired,
  hpeIquoteId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    maker: PropTypes.string,
    category: PropTypes.string,
    mcAfeeQty: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    outlet: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
    promotionPrice: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    sku: PropTypes.string.isRequired,
    sku2: PropTypes.string.isRequired,
    hpMapId: PropTypes.string.isRequired,
    branch: PropTypes.string,
    previousPriceO: PropTypes.number.isRequired,
    currentPriceO: PropTypes.number.isRequired,
    previousPromotionPriceO: PropTypes.number.isRequired,
    currentPromotionPriceO: PropTypes.number.isRequired,
    dateLogO: PropTypes.string.isRequired,
    dateCreated: PropTypes.string.isRequired,
    minQuantity: PropTypes.number.isRequired,
    maxQuantity: PropTypes.number.isRequired,
    price7: PropTypes.number.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  paymentType: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  tr: PropTypes.func.isRequired,
  actionLoader: PropTypes.bool.isRequired,
};

Details.propTypes = {
  name: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  sku2: PropTypes.string.isRequired,
};

ImageOutlet.propTypes = {
  validatedImage: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

ImageDetail.propTypes = {
  validatedImage: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(ItemContent);
