import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { callError } from '@compusoluciones-pdigitales/status-codes';
import {
  Grid, Header, Button, Segment, Checkbox, Modal, Icon, Label, Loader, Dimmer, Popup, Message, List, Transition, Responsive, Confirm, Sticky,
} from 'semantic-ui-react';
import validator from 'validator';
import { currencyFormat, getBrands } from '../common/utils';
import FinalUser from './components/finalUser';
import Openpay from '../openpay-form';
import Payment from './components/payment';
import Orders from './components/orders';
import LeaseOptions from './components/lease-options';
import Authorization from '../quotation-detail/components/authorization';
import services from './services';
import Coupons from './components/coupons-v3';
import routeNames from '../route-names';
import * as session from '../common/sessions';
import Toast from '../common/components/toast';
import SiclikTableMobile from '../common/components/SiclikTableMobile';
import { TextField } from '../common/components/materials';
import couponTypes from '../common/discount-types';
import * as paymentMethod from '../common/payment-methods';
import './styless.css';

const CURRENCY_OPTIONS = [
  { key: 1, text: 'MXN', value: 1 },
  { key: 2, text: 'USD', value: 2 },
];

const MXN_OPTION = [{ key: 1, text: 'MXN', value: 1 }];
const USD_OPTION = [{ key: 2, text: 'USD', value: 2 }];

const getPricesAutodesk = async sku => {
  const { items } = await services.getAutodeskPrices({ skus: [sku] });
  const { price7 } = items[0] || '';
  return price7;
};

const PriceCell = ({
  price, currency, brand, quantity, sku, // eslint-disable-line react/prop-types
}) => {
  const [price7, setPrice7] = useState('');

  useEffect(() => {
    const fetchPrice = async () => {
      const priceResult = await getPricesAutodesk(sku);
      setPrice7(priceResult);
    };
    fetchPrice();
  }, [sku]);

  if (brand === 'Autodesk' && quantity > 1) {
    return (
      <div>
        <div>
          {currencyFormat(price, currency)}
          &nbsp;
          &nbsp;
          /1 usuario
        </div>
        <br />
        <div>
          {currencyFormat(price7, currency)}
          &nbsp;
          &nbsp;
          /usuario adicional
        </div>
      </div>
    );
  }
  return (
    <div>
      <div>
        {currencyFormat(price, currency)}
      </div>
    </div>
  );
};
class Checkout extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const { userId, customerId, customerName } = session.get();
    this.state = {
      ordersHeaders: [
        { key: 'id', isKey: true },
        { key: 'sku', name: 'SKU' },
        { key: 'brand', name: 'Fabricante' },
        { key: 'name', name: 'Nombre' },
        { key: 'quantity', name: 'Cantidad' },
        {
          key: 'price',
          name: 'Precio',
          format: (cell, row) => {
            const { currency } = this.state;
            return <PriceCell price={cell} finalPrice={row.finalPrice} currency={currency} quantity={row.quantity} brand={row.brand} sku={row.sku} />;
          },
        },
        {
          key: 'finalPrice',
          name: 'Precio final unidad',
          format: cell => {
            const { currency } = this.state;
            return currencyFormat(cell, currency);
          },
        },
        { key: 'coupons', name: 'Cupones', format: cell => (cell.length === 0 ? '' : this.handleRenderCouponDetails(cell)) },
      ],
      checkoutData: [],
      ordersData: [],
      orderId: '',
      hpeIquoteId: '',
      urlFile: '',
      openPaymentByOpenpayModal: false,
      paymentMethods: [],
      currencyOptions: '',
      validateOrderModal: false,
      openPaymentBySpeiModal: false,
      modalMessagePaymentByReference: false,
      totalAmount: '',
      currentPaymentMethod: '',
      checkoutLoader: false,
      currentCurrency: '',
      currency: '',
      visible: false,
      invalidCoupons: [],
      ordersLoader: false,
      couponsLoader: false,
      reviewsArray: [],
      userId,
      customerId,
      userNameLoged: customerName,
      modalByReferenceNumbers: false,
      deviceSessionId: '',
      cardBrand: 'Visa',
      modalLimitPaymentByCard: false,
      couponsData: [],
      paymentLoader: false,
      leasePaymentFlag: false,
      leaseQuoteData: {},
      marginValue: 0,
      leaseQuoteLoader: true,
      leaseOptionSelected: '',
      leaseOptionSelectedValues: '',
      openLeaseModal: false,
      confirmLeaseType: false,
      ratingStars: 0,
      survey: false,
      textAreaComentarios: '',
      subtotal: '',
      leaseOptionsHeaders: [
        { key: 'id', isKey: true },
        { key: 'LeaseTerm', name: 'Duración', format: '' },
        { key: 'PaymentFrequency', name: 'Frecuencia de pago', format: (cell, row) => (row.PaymentFrequency === 'MON' ? 'Mensual' : 'Cuatrimestral') },
        {
          key: 'Amount',
          name: 'Cantidad a pagar',
          format: (cell, row) => {
            const { currentCurrency } = this.state;
            return (currentCurrency === 1 ? `$ ${row.Amount} MXN` : `$ ${row.Amount} USD`);
          },
        },
        {
          name: 'Seleccionar',
          format: (cell, row) => {
            const { leaseOptionSelected } = this.state;
            return (
              <Checkbox
                radio
                name="leaseSelection"
                value={row.id}
                checked={leaseOptionSelected === row.id}
                onChange={this.handleRadioSelection}
              />
            );
          },
        },
      ],
      reviews: [],
      readyToPay: false,
      validateOrderError: '',
      existingBrands: [],
      initialCurrency: match.params.currency,
      leaseTypes: [{ key: 1, text: 'FL', value: 1 }, { key: 2, text: 'FLV', value: 2 }],
      currentLeaseType: 1,
      leaseItemsHeaders: [
        { key: 'id', isKey: true },
        { key: 'sku', name: 'SKU' },
        { key: 'name', name: 'Nombre' },
        { key: 'quantity', name: 'Cantidad' },
        {
          key: 'price',
          name: 'Precio',
          format: cell => {
            const { currency } = this.state;
            return (currencyFormat(cell, currency));
          },
        },
        {
          key: 'upliftPrice',
          name: 'Precio con margen',
          format: (cell, row) => {
            const { currency, leaseQuoteLoader } = this.state;
            return (
              <TextField
                fullWidth
                type="number"
                error={row.fieldError || ''}
                value={cell || ''}
                disabled={leaseQuoteLoader}
                label={currencyFormat(cell, currency)}
                onChange={res => this.handlePriceUpliftChange(res, row)}
              />
            );
          },
        },
      ],
      leaseItemsData: [],
      leaseSubtotal: 0,
      quoteAgainFlag: true,
      context: null,
    };
  }

  componentDidMount() {
    this.getOrdersData();
    this.handleGetAccessKeys();
    this.handleGetCouponsByCustomer();
    window.scrollTo(0, 0);
  }

  setOrderCreditDays = orders => (
    orders.map(order => {
      let creditDays;
      order.items.forEach(item => {
        const creditDaysDiscounts = item.coupons.filter(coupon => coupon.type === couponTypes.creditDays.discount);
        if (creditDaysDiscounts.length) {
          creditDays = creditDaysDiscounts.toString();
        }
      });
      return { ...order, creditDays };
    })
  )

  getReviews = async orderId => {
    try {
      const { orderReviews: reviews } = await services.getReviews(orderId);
      this.setState({ reviews });
    } catch (error) {
      Toast('No se pudo obtener las revisiones de la orden', 'error');
    }
  };

  defineMainSku = orders => (
    orders.map(order => {
      const items = order.items.map(item => ({
        ...item,
        sku: item.sku2 && item.sku2 !== '' ? item.sku2 : item.sku,
      }));
      return { ...order, items };
    })
  )

  getOrdersData = async () => {
    const { t, history } = this.props;
    const { initialCurrency, leasePaymentFlag } = this.state;
    let { currencyOptions } = this.state;
    const paymentConfig = Number(initialCurrency) === 1 ? {
      cardBrand: '',
      currencyId: 2,
      paymentId: 1,
    } : {
      cardBrand: '',
      currencyId: 1,
      paymentId: 1,
    };
    try {
      this.setState({ checkoutLoader: true, leaseQuoteLoader: true });
      const { availableCredit } = await services.getCustomerCredit();
      const { paymentMethods } = await services.getPaymentMethods();
      const responseCheckout = await services.checkout(paymentConfig);
      const { subtotal, currencyId } = responseCheckout;
      let { paymentId } = responseCheckout;
      const ordersData = this.defineMainSku(responseCheckout.orders);
      const allItemsArrays = [];
      let filterPayments = [];
      const allOrders = this.setOrderCreditDays(ordersData);
      allOrders.map(item => allItemsArrays.push(item.items));
      const allItems = allItemsArrays.reduce((accumulatedArray, allItem) => accumulatedArray.concat(allItem));
      const amounts = [];
      const leaseItemsData = allItems.map(item => {
        amounts.push(item.price);
        return ({ ...item, upliftPrice: '', fieldError: '' });
      });
      const existingBrands = getBrands(allItems);
      this.getReviews(responseCheckout.id);
      let statePaymentMethods = (paymentMethods.map(options => {
        if (options.id === 9) {
          if (leasePaymentFlag) {
            const paymentOptions = {
              key: options.id,
              text: options.name,
              value: options.id,
            };
            return paymentOptions;
          }
          return undefined;
        }
        const paymentOptions = {
          key: options.id,
          text: options.name,
          value: options.id,
        };
        return paymentOptions;
      })).filter(Boolean);
      if (availableCredit === 0) {
        statePaymentMethods = statePaymentMethods.filter(payment => payment.key !== 1);
        paymentId = 6;
      } else if (availableCredit === 1) {
        currencyOptions = CURRENCY_OPTIONS;
      }
      const needUsdPaymentMethods = existingBrands.some(brand => (['Microsoft', 'VEEAM', 'VMware', 'VMWARE', 'Red Hat'].indexOf(brand.name) !== -1));
      if (needUsdPaymentMethods) {
        filterPayments = statePaymentMethods.filter(payment => payment.key === 1 || payment.key === 6);
        currencyOptions = USD_OPTION;
        if (existingBrands.length === 1 && existingBrands.some(brand => (['Red Hat', 'VEEAM'].indexOf(brand.name) !== -1))) {
          currencyOptions = CURRENCY_OPTIONS;
          if (existingBrands.some(brand => (['VEEAM'].indexOf(brand.name) !== -1))) {
            filterPayments = statePaymentMethods.filter(payment => payment.key !== 10);
          }
        }
      } else {
        filterPayments = statePaymentMethods;
        currencyOptions = paymentId === 1 ? CURRENCY_OPTIONS : MXN_OPTION;
      }
      this.setState({
        checkoutData: [responseCheckout],
        ordersData,
        totalAmount: responseCheckout.total,
        subtotal: responseCheckout.subtotal,
        currentCurrency: currencyId,
        currency: responseCheckout.currency,
        orderId: responseCheckout.id,
        existingBrands,
        paymentMethods: filterPayments,
        currentPaymentMethod: paymentId,
        currencyOptions,
        hpeIquoteId: responseCheckout.hpeQuoteId,
        leaseItemsData,
        leaseSubtotal: amounts.reduce((accumulator, currentValue) => accumulator + currentValue),
      });
      this.inRangeToLease(subtotal, currencyId, paymentId);
      const responseReviews = await services.getReviews(responseCheckout.id);
      this.setState({ reviewsArray: responseReviews.orderReviews });
    } catch (error) {
      this.setState({ checkoutLoader: false });
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = error.message;
      Toast(messageError, 'error');
      history.push(routeNames.shoppingCart.route);
    }
  }

  getCurrencyOptions = () => {
    this.setState({ currencyOptions: CURRENCY_OPTIONS });
  }

  getExchangeRate = () => (
    services.getExchangeRates()
  )

  getQuoteData = quoteParams => (
    services.getLeaseQuote(quoteParams)
  )

  getLeaseQuote = async () => {
    const { t } = this.props;
    try {
      const {
        paymentMethods, leaseQuoteData, leasePaymentFlag, currentPaymentMethod, currentLeaseType, leaseTypes, leaseItemsData,
      } = this.state;
      const { marginValue: marginUplift, currentCurrency: currency } = this.state;
      const leaseType = leaseTypes.find(({ value }) => value === currentLeaseType);
      const items = leaseItemsData.map(({ sku, upliftPrice, price }) => ({ sku, upliftPrice: upliftPrice || price }));
      const options = { marginUplift, currency };
      let quoteData = leaseQuoteData;
      if (leasePaymentFlag && currentPaymentMethod === 9) {
        quoteData = await this.getQuoteData({ ...options, leaseType: leaseType.text, items });
      }
      this.setState({
        leaseQuoteData: quoteData, leasePaymentFlag: true, leaseQuoteLoader: false, checkoutLoader: false,
      });
      this.reloadPaymentMethods(paymentMethods);
    } catch (error) {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = error.message;
      Toast(messageError, 'error');
      this.setState({ checkoutLoader: false, leasePaymentFlag: false, leaseQuoteLoader: false });
    }
  }

  hasReviews = () => {
    const { reviews } = this.state;
    const pendingReviews = reviews.some(review => review.idResolution === 0 || (review.idResolution !== 0 && review.resolution === false));
    return pendingReviews;
  }

  saveLeaseQuote = leaseQuote => (
    services.saveLeaseQuote(leaseQuote)
  )

  reloadPaymentMethods = async paymentMethods => {
    const leaseMethod = { key: 9, text: 'Financiamiento HPEFS', value: 9 };
    const { leasePaymentFlag } = this.state;
    const reloadedMethods = paymentMethods;
    if (leasePaymentFlag) {
      if (!paymentMethods.find(method => method.key === 9)) {
        reloadedMethods.push(leaseMethod);
        this.setState({ paymentMethods: reloadedMethods });
      }
    } else {
      const methods = reloadedMethods.filter(method => method.key !== 9);
      this.setState({ paymentMethods: methods });
    }
  }

  inRangeToLease = async () => {
    this.setState({ checkoutLoader: false, leasePaymentFlag: false, leaseQuoteLoader: false });
  };

  handleGetAccessKeys = () => {
    const { t } = this.props;
    services.getAccessKeys().then(response => {
      const sandboxFlag = !(process.env.REACT_APP_OPENPAY_SANDBOX === 'false');
      const responseOpenpay = response.openpay;
      window.OpenPay.setId(responseOpenpay.id); // eslint-disable-line
      window.OpenPay.setApiKey(responseOpenpay.apiKey); // eslint-disable-line
      window.OpenPay.setSandboxMode(sandboxFlag); // eslint-disable-line
      this.setState({ deviceSessionId: window.OpenPay.deviceData.setup() }); // eslint-disable-line
    }).catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    });
  }

  handlePaymentChange = () => {
    this.setState({ readyToPay: false, validateOrderError: '' });
    let { currentPaymentMethod } = this.state;
    switch (currentPaymentMethod) {
      case paymentMethod.CREDIT: this.handlePaymentService(); break;
      case paymentMethod.BANK_CARD: this.handlePaymentByOpenpay(); break;
      case paymentMethod.BANK_REFERENCE: this.handlePaymentByReference(); break;
      case paymentMethod.SPEI: this.handlePaymentBySpei(); break;
      case paymentMethod.WIRE_TRANSFER: this.handlePaymentService(); break;
      case paymentMethod.FINANCING_HPEFS: this.handleApplyLease(); break;
      case paymentMethod.RESERVED: this.handlePaymentService(); break;
      default: currentPaymentMethod = false; break;
    }
  }

  handlePaymentByReference = async () => {
    const { t } = this.props;
    this.setState({ checkoutLoader: true });
    if (this.hasReviews()) {
      Toast(t('checkout.error.pendingReviews'), 'error');
    } else {
      try {
        const result = await this.validateOrder();
        if (result && result.orderReady) {
          const { checkoutData, orderId, deviceSessionId } = this.state;
          const amount = Number(checkoutData.map(total => total.total));
          if (amount <= 9999.99) {
            const roundedQuantity = amount.toFixed(2);
            const paymentDetails = {
              amount: roundedQuantity,
              description: 'Pago de servicios',
              orderId,
              deviceSessionId,
            };
            this.handleCreateCustomer();
            services.createReference(paymentDetails).then(referenceResponse => {
              this.setState({
                urlFile: referenceResponse.urlFile,
                orderId: referenceResponse.orderId,
              });
              this.handlePaymentService(referenceResponse.paymentId);
            }).catch(error => {
              this.setState({ checkoutLoader: false });
              let messageError = '';
              if (error.message === 'amount is required with min value 1 and max value 9999.99') messageError = 'La cantidad maxima permitida para este medio de pago es de $9,999.99 MXN';
              else messageError = t('error-codes.default');
              Toast(messageError, 'error');
            });
          } else {
            this.setState({ checkoutLoader: false });
            Toast('El total debe ser menor a la cantidad de $10,000 MXN', 'warning');
          }
        }
      } catch (error) {
        this.setState({ checkoutLoader: false });
        let messageError = '';
        if (!error.code) messageError = error.message;
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      }
    }
  }

  validateOrder = () => {
    const { orderId, currentCurrency, currentPaymentMethod } = this.state;
    return services.validateOrder({
      orderId,
      paymentId: currentPaymentMethod,
      currencyId: currentCurrency,
    });
  }

  handlePaymentByOpenpay = async () => {
    const { t } = this.props;
    const { checkoutData, currentCurrency } = this.state;
    const { exchangeRate } = checkoutData[0];

    let total = Number(checkoutData.map(getTotal => getTotal.total));
    if (currentCurrency === 2) {
      total *= exchangeRate;
    }
    this.setState({ checkoutLoader: true });

    if (this.hasReviews()) {
      Toast(t('checkout.error.pendingReviews'), 'error');
    } else {
      try {
        const result = await this.validateOrder();
        if (result && result.orderReady) {
          this.setState({ checkoutLoader: false });
          if (total <= 100000) {
            this.setState({ readyToPay: true, openPaymentByOpenpayModal: true });
          } else {
            Toast('El total debe ser menor a la cantidad de $100,000 MXN', 'warning');
          }
        }
      } catch (error) {
        let messageError = '';
        if (!error.code) messageError = error.message;
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ validateOrderError: messageError, readyToPay: true, checkoutLoader: false });
      }
    }
  };

  handlePaymentMethodChange = (e, { value: paymentMethodId }) => {
    const { checkoutData, currentCurrency } = this.state;
    const { exchangeRate } = checkoutData[0];
    this.setState({
      leaseQuoteData: {}, leaseOptionSelected: '', leaseOptionSelectedValues: '', currentLeaseType: 1,
    });
    let total = Number(checkoutData.map(getTotal => getTotal.total));
    if (currentCurrency === 2) {
      total *= exchangeRate;
    }
    checkoutData.map(data => {
      data.paymentId = paymentMethodId; // eslint-disable-line
      return data;
    });
    this.handleUpdateCurrencyOptions(paymentMethodId);
    if (paymentMethodId === 3 && total > 9999.99) {
      this.setState({ modalMessagePaymentByReference: true });
    }
    if (paymentMethodId === 2 && total > 100000) {
      this.setState({ modalLimitPaymentByCard: true });
    }
    if (paymentMethodId === 9) {
      this.setState({ leaseQuoteLoader: true });
    }
  }

  handleUpdateCurrencyOptions = paymentMethodId => {
    const { checkoutData, currentCurrency, existingBrands } = this.state;
    let currencyOption = [];
    const needUsdPaymentMethods = existingBrands.some(brand => (['Microsoft', 'VEEAM', 'VMWARE', 'Red Hat'].indexOf(brand.name) !== -1));
    switch (paymentMethodId) {
      case 1:
        currencyOption = !needUsdPaymentMethods ? CURRENCY_OPTIONS : USD_OPTION;
        if (existingBrands.length === 1 && existingBrands.some(brand => (['Red Hat', 'VEEAM'].indexOf(brand.name) !== -1))) {
          currencyOption = CURRENCY_OPTIONS;
        }
        this.handlePaymentMethodRequest(!needUsdPaymentMethods ? currentCurrency : 2, paymentMethodId);
        break;
      case paymentMethod.BANK_CARD:
        currencyOption = !needUsdPaymentMethods ? CURRENCY_OPTIONS : USD_OPTION;
        if (existingBrands.length === 1 && existingBrands.some(brand => (['Red Hat', 'VEEAM'].indexOf(brand.name) !== -1))) {
          currencyOption = CURRENCY_OPTIONS;
        }
        this.handlePaymentMethodRequest(currentCurrency, paymentMethodId);
        break;
      case paymentMethod.BANK_REFERENCE:
        currencyOption = MXN_OPTION;
        this.handlePaymentMethodRequest(1, paymentMethodId);
        break;
      case paymentMethod.SPEI:
        currencyOption = MXN_OPTION;
        this.handlePaymentMethodRequest(1, paymentMethodId);
        break;
      case paymentMethod.WIRE_TRANSFER:
        currencyOption = !needUsdPaymentMethods ? MXN_OPTION : USD_OPTION;
        if (existingBrands.length === 1 && existingBrands.some(brand => (['Red Hat', 'VEEAM'].indexOf(brand.name) !== -1))) {
          currencyOption = CURRENCY_OPTIONS;
        }
        this.handlePaymentMethodRequest((!needUsdPaymentMethods ? 1 : 2), paymentMethodId);
        break;
      case paymentMethod.FINANCING_HPEFS:
        currencyOption = CURRENCY_OPTIONS;
        this.handlePaymentMethodRequest(currentCurrency, paymentMethodId);
        break;
      case paymentMethod.RESERVED:
        currencyOption = CURRENCY_OPTIONS;
        this.handlePaymentMethodRequest(currentCurrency, paymentMethodId);
        break;
      default:
        currencyOption = false;
    }
    this.setState({
      checkoutData,
      currentPaymentMethod: paymentMethodId,
      currencyOptions: currencyOption,
      cardBrand: 'Visa',
    });
  }

  handleCurrencyChange = (e, { value }) => {
    const { currentPaymentMethod } = this.state;
    this.setState({ currentCurrency: value });
    this.handlePaymentMethodRequest(value, currentPaymentMethod);
  }

  handleLeaseTypeChange = (e, { value }) => {
    this.setState({ currentLeaseType: value });
  }

  handleCardTypeChange = (e, { value }) => {
    const { currentCurrency, currentPaymentMethod } = this.state;
    const formatedValue = value === 'VisaMasterCard' ? 'Visa' : value;
    this.setState({ cardBrand: formatedValue });
    this.handlePaymentMethodRequest(currentCurrency, currentPaymentMethod, formatedValue);
  };

  handlePaymentMethodRequest = (currency, paymentMethodId, cardBrand = 'Visa') => {
    const { t } = this.props;
    const allItemsArrays = [];
    this.setState({ ordersLoader: true });
    const paymentConfig = {
      currencyId: currency,
      paymentId: paymentMethodId === 9 ? 1 : paymentMethodId,
      cardBrand: paymentMethodId === 2 ? cardBrand : '',
    };
    services.checkout(paymentConfig).then(checkoutResponse => {
      const ordersData = this.defineMainSku(checkoutResponse.orders);
      const allOrders = this.setOrderCreditDays(ordersData);
      allOrders.map(item => allItemsArrays.push(item.items));
      const allItems = allItemsArrays.reduce((accumulatedArray, allItem) => accumulatedArray.concat(allItem));
      const amounts = [];
      const leaseItemsData = allItems.map(item => {
        amounts.push(item.price);
        return ({ ...item, upliftPrice: '', fieldError: '' });
      });
      const { subtotal } = checkoutResponse;
      this.setState({
        checkoutData: [checkoutResponse],
        ordersData,
        totalAmount: checkoutResponse.total,
        currency: checkoutResponse.currency,
        currentCurrency: checkoutResponse.currencyId,
        ordersLoader: false,
        leaseItemsData,
        leaseSubtotal: amounts.reduce((accumulator, currentValue) => accumulator + currentValue),
      });
      this.inRangeToLease(subtotal, currency, paymentMethod);
    }).catch(error => {
      let messageError = '';
      if (!error.code) messageError = error.message;
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    });
  }

  openValidateOrderModal = () => {
    this.setState({ validateOrderModal: true });
  }

  handleValidateOrder = () => {
    const { history } = this.props;
    history.push(routeNames.quotations.route);
  }

  handleGetAndApplyCoupons = arrayCoupons => {
    const {
      currentCurrency, currentPaymentMethod, subtotal,
    } = this.state;
    this.setState({ couponsData: arrayCoupons });
    const couponsId = arrayCoupons.map(coupon => ({ ...coupon, subtotal }));
    const { t } = this.props;
    const paymentConfig = {
      paymentId: currentPaymentMethod,
      currencyId: currentCurrency,
    };
    let message = '';
    services.addCoupons({ couponsId })
      .then(response => {
        const arrayinvalidCopupons = response.invalidCopupons.map(couponId => ({ couponId }));
        if (arrayinvalidCopupons.length > 0) this.setState({ visible: true });
        if (response.validCoupons.length > 0) { message = t('checkout.appliedCoupons'); Toast(message, 'success'); }
        this.setState({
          invalidCoupons: arrayinvalidCopupons,
          couponsLoader: true,
        });
        this.handleGetReviews();
      })
      .then(() => services.checkout(paymentConfig)
        .then(checkoutResponse => {
          const ordersData = this.defineMainSku(checkoutResponse.orders);
          ordersData.map(order => {
            order.items.map(item => item.coupons.map(coupon => order.creditDays = coupon.days)); // eslint-disable-line
            return order;
          });
          this.setState({
            couponsLoader: false,
            checkoutData: [checkoutResponse],
            ordersData,
            totalAmount: checkoutResponse.total,
            subtotal: checkoutResponse.subtotal,
            currentPaymentMethod,
            currentCurrency,
            currency: checkoutResponse.currency,
          });
        })
        .catch(error => {
          this.setState({ checkoutLoader: false, couponsLoader: false });
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }))
      .catch(error => {
        this.setState({ checkoutLoader: false, couponsLoader: false });
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else if (error.code === 1007) messageError = error.message.replace('2 UNKNOWN:', '');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleShoppingCartToQuote = () => {
    const { t, updateCartCount, history } = this.props;
    const name = 'cotización en validación';
    services.shoppingCartToQuote({ name })
      .then(responseQuote => {
        if (responseQuote) history.push(routeNames.quotations.route);
      }).then(() => updateCartCount())
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ validateOrderModal: false });
        Toast(messageError, 'error');
      });
  }

  handlePaymentBySpei = async () => {
    const { t } = this.props;
    this.setState({ checkoutLoader: true });
    if (this.hasReviews()) {
      Toast(t('checkout.error.pendingReviews'), 'error');
    } else {
      try {
        const result = await this.validateOrder();
        if (result && result.orderReady) {
          const { checkoutData, orderId, deviceSessionId } = this.state;
          const amount = Number(checkoutData.map(total => total.total));
          const roundedQuantity = amount.toFixed(2);
          const paymentDetails = {
            amount: roundedQuantity,
            description: 'Compra pago transferencia SPEI',
            orderId,
            deviceSessionId,
          };
          this.handleCreateCustomer();
          services.createSpeiReference(paymentDetails).then(responseRefrenceSpei => {
            this.setState({
              urlSpeiFile: responseRefrenceSpei.urlFile,
              orderId: responseRefrenceSpei.orderId,
            });
            this.handlePaymentService(responseRefrenceSpei.paymentId);
          }).catch(error => {
            this.setState({ checkoutLoader: false });
            let messageError = '';
            if (error.message === 'The order_id has already been processed') messageError = 'La orden ya ha sido procesada.';
            else messageError = t('error-codes.default');
            Toast(messageError, 'error');
          });
        }
      } catch (error) {
        this.setState({ checkoutLoader: false });
        let messageError = '';
        if (!error.code) messageError = error.message;
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      }
    }
  }

  handleOpenPdfSpei = () => this.setState({ openPaymentBySpeiModal: true });

  sendToReview = message => {
    const { orderId } = this.state;
    const UEN_SERVICES = 46;
    services.addReview(orderId, {
      uen: UEN_SERVICES,
      reason: message,
    });
  };

  sendReviewMessage = async cartId => {
    const body = { cartId };
    await services.agentReviewNotification(body);
  };

  isFastPäymentmethod = paymentMethodId => (
    paymentMethodId === paymentMethod.CREDIT
    || paymentMethodId === paymentMethod.BANK_CARD
    || paymentMethodId === paymentMethod.WIRE_TRANSFER
    || paymentMethodId === paymentMethod.RESERVED
  )

  handlePaymentService = (openpayPaymentId = undefined) => {
    const { t, updateCartCount } = this.props;
    const {
      currentPaymentMethod, existingBrands,
    } = this.state;
    let { currentCurrency } = this.state;
    const hasVmwareItems = existingBrands.some(brand => (['Microsoft', 'VMWARE'].indexOf(brand.name) !== -1));
    if (hasVmwareItems) currentCurrency = 2;
    const paymentConfig = {
      paymentId: currentPaymentMethod,
      currencyId: currentCurrency,
    };
    if (openpayPaymentId !== undefined) paymentConfig.openpayId = openpayPaymentId;
    if (this.isFastPäymentmethod(currentPaymentMethod)) {
      this.setState({ checkoutLoader: true });
    }
    services.buy(paymentConfig)
      .then(() => {
        const msgSuccess = (currentPaymentMethod === 6 || currentPaymentMethod === 4) ? t('openpay.successfullOrder') : t('openpay.successfullPayment');
        Toast(msgSuccess, 'success');
        if (this.isFastPäymentmethod(currentPaymentMethod)) {
          this.setState({
            checkoutLoader: false, openPaymentByOpenpayModal: false, cardBrand: 'Visa',
          });
          this.redirectPayment(currentPaymentMethod);
        }
        if (currentPaymentMethod === 3) this.redirectPayment(currentPaymentMethod);
        if (currentPaymentMethod === 4) this.redirectPayment(currentPaymentMethod);
        this.setState({ paymentLoader: false });
        // analyticsEvent('Compra', 'Confirmado', 'Carrito comprado');
      })
      .then(() => updateCartCount())
      .catch(error => {
        this.setState({ checkoutLoader: false, openPaymentByOpenpayModal: false });
        this.setState({ paymentLoader: false });
        let messageError = '';
        switch (error.code) {
          case callError.orders.CART_REVIEW:
            this.sendToReview(error.message);
            messageError = t('checkout.error.cartReview');
            break;
          case callError.general.RESOURCE_NOT_FOUND:
            messageError = t(`error-codes.${error.code}`);
            break;
          case callError.orders.SHIPPING_REVIEW:
            messageError = t('checkout.error.shippingReview');
            break;
          default:
            messageError = error.message;
            break;
        }
        const { orderId } = this.state;
        if (messageError === t('checkout.approveReviewMessage')) this.sendReviewMessage(orderId);
        Toast(messageError, 'error');
      });
  }

  redirectPayment = type => {
    const { history } = this.props;
    const { urlFile, urlSpeiFile } = this.state;
    if (type === 3) {
      history.push({
        pathname: routeNames.payment.route.replace(':type', type),
        state: { detail: urlFile },
      });
    } else if (type === 4) {
      history.push({
        pathname: routeNames.payment.route.replace(':type', type),
        state: { detail: urlSpeiFile },
      });
    } else {
      history.push(routeNames.payment.route.replace(':type', type));
    }
  }

  handleLeasePayment = async () => {
    this.setState({ checkoutLoader: true, confirmLeaseType: false });
    const { t } = this.props;
    const {
      leaseOptionSelected, leaseOptionSelectedValues, marginValue: marginUplift, checkoutData, leaseQuoteData,
      leaseItemsData,
    } = this.state;
    if (leaseOptionSelected === '') {
      Toast(t('checkout.lease-unselected'), 'error');
    } else {
      try {
        const { id: orderId } = checkoutData[0];
        const {
          requestId, hardwareAmount, softwareAmount, printerAmount, servicesAmount,
        } = leaseQuoteData;
        const {
          LeaseType: leaseType, PaymentFrequency: paymentFrecuency, LeaseTerm: leaseTerm, Amount: paymentAmount,
        } = leaseOptionSelectedValues;
        const items = leaseItemsData.map(({ sku, upliftPrice, price }) => ({ sku, upliftPrice: upliftPrice || price }));
        const config = {
          requestId,
          hardwareAmount,
          softwareAmount,
          printerAmount,
          servicesAmount,
          leaseType,
          paymentFrecuency,
          leaseTerm,
          paymentAmount,
          orderId,
          marginUplift,
          items,
        };
        const result = await this.saveLeaseQuote(config);
        if (result) {
          this.setState({ openLeaseModal: true });
        }
      } catch (error) {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = error.message;
        Toast(messageError, 'error');
        this.setState({ checkoutLoader: false });
      }
    }
  };

  validateUpliftPriceFormat = () => {
    const { leaseItemsData } = this.state;
    const errors = [];
    leaseItemsData.forEach(item => {
      if (!validator.isDecimal(String(item.upliftPrice || item.price))) {
        errors.push(true);
      }
    });
    return errors.some(item => item === true);
  }

  validateItemsPrices = () => {
    const { t } = this.props;
    const hasErrors = this.validateUpliftPriceFormat();
    if (!hasErrors) {
      const { leaseQuoteData, leaseSubtotal } = this.state;
      const {
        hardwareAmount, softwareAmount, printerAmount, servicesAmount,
      } = leaseQuoteData;
      const amounts = [hardwareAmount, softwareAmount, printerAmount, servicesAmount];
      const leaseInitSubtotal = amounts.reduce((accumulator, currentValue) => accumulator + currentValue);
      if (leaseInitSubtotal === leaseSubtotal) return true;
      return false;
    }
    Toast(t('lease-quote.diferentPrices'), 'error');
    return false;
  }

  handleApplyLease = () => {
    const { t } = this.props;
    const { leaseOptionSelected } = this.state;
    if (leaseOptionSelected === '') {
      Toast(t('checkout.lease-unselected'), 'error');
    } else if (!this.validateItemsPrices()) {
      Toast(t('lease-quote.pricesChanged'));
    } else this.setState({ confirmLeaseType: true });
  }

  handleCancelLeaseType = () => {
    this.setState({ confirmLeaseType: false });
  }

  handleRenderCouponDetails = coupons => {
    const { t } = this.props;
    const { currency } = this.state;
    return (
      <Popup
        size="huge"
        trigger={<Label as="a" tag color="blue">{t('checkout.seeCoupon')}</Label>}
        flowing
        on="click"
      >
        {coupons.map(item => (
          <Grid centered>
            <Grid.Row>
              <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={7}>
                <Header as="h4" color="blue">
                  {t('checkout.couponCode')}
                  &nbsp;
                  <Header as="h5" color="black">
                    {item.code}
                  </Header>
                </Header>
              </Grid.Column>
              <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={9}>
                <Header as="h4" color="blue">
                  {t('checkout.discountApplied')}
                  &nbsp;
                  <Header as="h5" color="green">
                    {item.discount ? currencyFormat(item.discount, currency) : item.days}
                  </Header>
                </Header>
              </Grid.Column>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                <Button color="blue" onClick={() => this.handleDeleteCoupon(item)} fluid basic>
                  <Icon name="close" />
                  Eliminar cupón aplicado
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ))}
      </Popup>
    );
  }

  handleDeleteCoupon = ({ code }) => {
    const { orderId } = this.state;
    const { t } = this.props;
    services.deleteCoupon(orderId, code)
      .then(() => this.getOrdersData())
      .catch(error => {
        if (error.code === 1008 || error.code === 1622) return;
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleDismiss = () => {
    this.setState({ visible: false });
  }

  handleBack = () => {
    const { history } = this.props;
    history.push(routeNames.shoppingCart.route);
  }

  handlePaymentConfirmationByCard = paymentConfirm => {
    if (paymentConfirm !== null) this.handlePaymentService(paymentConfirm.paymentId);
  }

  handleCreateCustomer = () => {
    const { t } = this.props;
    services.createCustomer()
      .catch(error => {
        if (error.code === 1008 || error.code === 1622) return;
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleGetReviews = () => {
    const { orderId } = this.state;
    const { t } = this.props;
    services.getReviews(orderId).then(response => {
      this.setState({ reviewsArray: response.orderReviews });
    }).catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      if (error.code && error.code !== 1005) Toast(messageError, 'error');
    });
  }

  handleOpenModalByReferenceNumbers = () => this.setState({ modalByReferenceNumbers: true });

  handleOnUpdate = (e, { width }) => {
    if (width > 768) this.setState({ width, centeredProperty: 'centered' });
    else this.setState({ width, centeredProperty: '' });
  }

  handleGetCouponsByCustomer = async () => {
    const { customerId } = this.state;
    const coupons = await services.getCouponsByCustomerId(customerId);
    this.setState({ couponsData: coupons.coupons });
  }

  handleChangePaymentLoaderStatus = status => {
    this.setState({ paymentLoader: status });
  }

  handleMarginChange = (e, { value }) => {
    this.setState({ marginValue: value, leaseQuoteLoader: true });
    this.getLeaseQuote();
  }

  handleRadioSelection = (e, selection) => {
    const { leaseQuoteData } = this.state;
    const { leasePaymentOptions: options } = leaseQuoteData;
    const values = options.find(type => type.id === selection.value);
    this.setState({ leaseOptionSelected: selection.value, leaseOptionSelectedValues: values });
  }

  handleRate = (e, { rating }) => {
    this.setState({ ratingStars: rating, survey: true });
  }

  sendSurvey = () => {
    const {
      orderId, ratingStars, survey, textAreaComentarios,
    } = this.state;
    if (survey) {
      const body = {
        orderId,
        rating: ratingStars !== 0 ? ratingStars : null,
        comments: textAreaComentarios !== '' ? textAreaComentarios : 'vacio',
      };
      services.satisfactionSurvey(body);
    }
  }

  handleInputChangeTextArea = e => {
    this.setState({ [e.target.id]: e.target.value, survey: true });
  }

  handleCloseOpenpayForm = () => {
    this.setState({ openPaymentByOpenpayModal: false, readyToPay: false, validateOrderError: '' });
  }

  getSelectedRows = row => {
    const { leaseQuoteData } = this.state;
    const { leasePaymentOptions: options } = leaseQuoteData;
    const values = options.find(type => type.id === row[0].id);
    this.setState({ leaseOptionSelected: row[0].id, leaseOptionSelectedValues: values });
  }

  handlePriceUpliftChange = async (event, row) => {
    const { leaseItemsData, leaseQuoteLoader } = this.state;
    if (!leaseQuoteLoader) this.setState({ quoteAgainFlag: false });
    const { value: inputValue } = event.target;
    const value = !validator.isAlpha(inputValue) ? parseFloat(inputValue) : 0;
    const amounts = [];
    const newItems = leaseItemsData.map(item => {
      if ((item.sku === row.sku) && value !== 0) item.upliftPrice = isNaN(value) ? '' : value; // eslint-disable-line
      amounts.push(item.upliftPrice || item.price);
      return item;
    });
    const leaseSubtotal = amounts.reduce((accumulator, currentValue) => accumulator + currentValue);
    this.setState({ leaseItemsData: newItems, leaseSubtotal });
  }

  reloadQuickQuote = async () => {
    const { t } = this.props;
    try {
      const hasErrors = this.validateUpliftPriceFormat();
      if (!hasErrors) {
        this.setState({
          quoteAgainFlag: true, leaseQuoteLoader: true, leaseQuoteData: {}, leaseOptionSelected: '', leaseOptionSelectedValues: '',
        });
        const {
          paymentMethods, leaseItemsData, leaseTypes, currentLeaseType, marginValue: marginUplift, currentCurrency: currency,
        } = this.state;
        const leaseType = leaseTypes.find(({ value }) => value === currentLeaseType);
        const items = leaseItemsData.map(({ sku, upliftPrice, price }) => ({ sku, upliftPrice: upliftPrice || price }));
        const options = { marginUplift, currency };
        const quoteData = await this.getQuoteData({ ...options, leaseType: leaseType.text, items });
        this.setState({
          leaseQuoteData: quoteData, leasePaymentFlag: true, leaseQuoteLoader: false, checkoutLoader: false,
        });
        this.reloadPaymentMethods(paymentMethods);
      } else {
        Toast(t('lease-quote.diferentPrices'), 'error');
      }
    } catch (error) {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = error.message;
      Toast(messageError, 'error');
      this.setState({ checkoutLoader: false, leasePaymentFlag: false, leaseQuoteLoader: false });
    }
  }

  handleContextRef = ref => {
    this.setState({ context: ref });
  };

  render() {
    const { t, history } = this.props;
    const {
      ordersHeaders, ordersData, checkoutData, openPaymentByOpenpayModal,
      validateOrderModal, openPaymentBySpeiModal, urlSpeiFile, modalMessagePaymentByReference,
      totalAmount, checkoutLoader, invalidCoupons, visible, ordersLoader,
      couponsLoader, reviewsArray, userId, customerId, userNameLoged, modalByReferenceNumbers, currentPaymentMethod, width,
      centeredProperty, cardBrand, modalLimitPaymentByCard, couponsData, leaseQuoteData, leaseOptionsHeaders, paymentLoader,
      marginValue, leaseQuoteLoader, openLeaseModal, confirmLeaseType, leaseOptionSelectedValues: leaseValues, readyToPay,
      validateOrderError, hpeIquoteId, existingBrands, leaseTypes, currentLeaseType, leaseItemsHeaders, leaseItemsData, leaseSubtotal,
      quoteAgainFlag, context, paymentMethods, currencyOptions, currentCurrency,
    } = this.state;

    return (
      <div ref={this.handleContextRef}>
        <Grid container style={{ paddingTop: '1rem' }}>
          {/* <Responsive as={Grid.Row} fireOnMount onUpdate={this.handleOnUpdate} /> */}
          <Grid.Row>
            <Header as="h2" color="blue">{t('checkout.paymentAndShipping')}</Header>
          </Grid.Row>
          {checkoutLoader === true ? (
            <Grid.Row>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="justified">
                <Dimmer active inverted>
                  <Loader active size="big" />
                </Dimmer>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid>
              <Grid.Row centered>
                <Grid.Column largeScreen={11} computer={11} tablet={11} mobile={16} className="no-padding order-mobile">
                  <Grid.Row className="padding-y padding-x">
                    <Header as="h4" color="blue">{t('checkout.generatedOrders')}</Header>
                  </Grid.Row>
                  {ordersLoader ? (
                    <Grid.Row>
                      <Grid.Column computer={16} tablet={16} mobile={16} textAlign="justified">
                        <Dimmer active inverted>
                          <Loader active size="big" />
                        </Dimmer>
                      </Grid.Column>
                    </Grid.Row>
                  ) : (
                    <Grid.Row className="padding-btm-gral padding-x">
                      <Grid.Column computer={16} largeScreen={16} mobile={16} tablet={15}>
                        <Segment raised style={{ overflowX: 'auto' }}>
                          <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                            <Orders ordersHeaders={ordersHeaders} ordersData={ordersData} currency={currentCurrency} width={width} />
                          </Responsive>
                          <Responsive minWidth={Responsive.onlyMobile.minWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
                            <SiclikTableMobile ordersData={ordersData} currency={currentCurrency} />
                          </Responsive>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  {(couponsData.length > 0 && !hpeIquoteId) ? (
                    <Grid.Row centered className="padding-bottom-header">
                      <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                        <Header as="h4" color="blue">{t('checkout.couponsHeader')}</Header>
                        <Segment raised className="container-coupons">
                          <Grid.Row>
                            <Coupons currentPaymentMethod={currentPaymentMethod} getCoupons={coupon => this.handleGetAndApplyCoupons(coupon)} />
                          </Grid.Row>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  ) : ''}
                  {couponsLoader === true ? (
                    <Grid.Row>
                      <Grid.Column computer={16} tablet={16} mobile={16} textAlign="justified">
                        <Dimmer active inverted>
                          <Loader active size="medium" />
                        </Dimmer>
                      </Grid.Column>
                    </Grid.Row>
                  ) : ''}
                  <Grid.Row className="no-padding-y">
                    <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={16}>
                      {visible ? (
                        <Transition visible animation="scale" duration={500}>
                          <Message warning onDismiss={this.handleDismiss}>
                            <Message.Header>{t('checkout.invalidCoupons')}</Message.Header>
                            <List bulleted>
                              {invalidCoupons.map(coupon => (
                                <List.Item>{coupon.couponId}</List.Item>
                              ))}
                            </List>
                          </Message>
                        </Transition>
                      ) : ''}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="width-mobile padding-x">
                    <Grid.Column largeScreen={16} computer={16} tablet={15} mobile={16}>
                      <Segment>
                        <Label attached="top">
                          <Header as="h4" color="blue">{t('checkout.reviews')}</Header>
                        </Label>
                        {
                          reviewsArray.length === 0 ? (
                            <p>
                              {t('checkout.noPendingAuthorizations')}
                            </p>
                          ) : (
                            <Authorization
                              reviewsArray={reviewsArray}
                              userId={userId}
                              userName={userNameLoged}
                              customerId={customerId}
                            />
                          )
                        }
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column largeScreen={5} computer={5} tablet={5} only="tablet computer largeScreen">
                  <Sticky context={context}>
                    <FinalUser
                      checkoutData={checkoutData}
                      paymentMethods={paymentMethods}
                      handlePaymentMethodChange={this.handlePaymentMethodChange}
                      currencyOptions={currencyOptions}
                      handleCurrencyChange={this.handleCurrencyChange}
                      currentCurrency={currentCurrency}
                      handleOpenModalByReferenceNumbers={this.handleOpenModalByReferenceNumbers}
                      currentPaymentMethod={currentPaymentMethod}
                      cardBrand={cardBrand}
                      handleCardTypeChange={this.handleCardTypeChange}
                      handleMarginChange={this.handleMarginChange}
                      marginValue={marginValue}
                      hpeIquoteId={hpeIquoteId}
                      leaseTypesArray={leaseTypes}
                      currentLeaseType={currentLeaseType}
                      handleLeaseTypeChange={this.handleLeaseTypeChange}
                      messageConfirmCurrency={existingBrands.some(brand => (['Red Hat', 'VEEAM'].indexOf(brand.name) !== -1))}
                    />
                    <Payment checkoutData={checkoutData} currency={currentCurrency} />
                    <Grid.Row centered={centeredProperty} className="padding-y">
                      <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} className="padding-y">
                        <Button size="large" fluid color="green" id="idPayment" disabled={ordersLoader || leaseQuoteLoader} onClick={this.handlePaymentChange}>
                          <span>
                            {(currentPaymentMethod === 9 ? t('checkout.lease-apply') : t('checkout.pay'))
                              && (currentPaymentMethod === 10 ? t('checkout.reserve') : t('checkout.pay')) }
                          </span>
                        </Button>
                      </Grid.Column>
                      <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                        <Button size="medium" basic fluid color="gray" onClick={this.handleBack}>
                          <span>{t('checkout.back')}</span>
                        </Button>
                        <Responsive maxWidth={768}>
                          <br />
                        </Responsive>
                      </Grid.Column>
                    </Grid.Row>
                  </Sticky>
                </Grid.Column>
                <Grid.Column mobile={16} only="mobile">
                  <FinalUser
                    checkoutData={checkoutData}
                    paymentMethods={paymentMethods}
                    handlePaymentMethodChange={this.handlePaymentMethodChange}
                    currencyOptions={currencyOptions}
                    handleCurrencyChange={this.handleCurrencyChange}
                    currentCurrency={currentCurrency}
                    handleOpenModalByReferenceNumbers={this.handleOpenModalByReferenceNumbers}
                    currentPaymentMethod={currentPaymentMethod}
                    cardBrand={cardBrand}
                    handleCardTypeChange={this.handleCardTypeChange}
                    handleMarginChange={this.handleMarginChange}
                    marginValue={marginValue}
                    hpeIquoteId={hpeIquoteId}
                    leaseTypesArray={leaseTypes}
                    currentLeaseType={currentLeaseType}
                    handleLeaseTypeChange={this.handleLeaseTypeChange}
                    messageConfirmCurrency={existingBrands.some(brand => (['Red Hat', 'VEEAM'].indexOf(brand.name) !== -1))}
                  />
                  <Payment checkoutData={checkoutData} currency={currentCurrency} />
                  <Grid.Row centered={centeredProperty} className="padding-y">
                    <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} className="padding-y">
                      <Button size="large" fluid color="green" id="idPayment" disabled={ordersLoader || leaseQuoteLoader} onClick={this.handlePaymentChange}>
                        <span>
                          {(currentPaymentMethod === 9 ? t('checkout.lease-apply') : t('checkout.pay'))
                            && (currentPaymentMethod === 10 ? t('checkout.reserve') : t('checkout.pay')) }
                        </span>
                      </Button>
                    </Grid.Column>
                    <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                      <Button size="medium" basic fluid color="gray" onClick={this.handleBack}>
                        <span>{t('checkout.back')}</span>
                      </Button>
                      <Responsive maxWidth={768}>
                        <br />
                      </Responsive>
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
              {
                currentPaymentMethod === 9 ? (
                  <div className="checkout-lease-container">
                    <Grid.Row className="checkout-lease-options">
                      <Grid.Column>
                        <Segment raised style={{ overflowX: 'auto' }}>
                          <LeaseOptions
                            leaseOptionsHeaders={leaseOptionsHeaders}
                            data={leaseQuoteData}
                            leaseQuoteLoader={leaseQuoteLoader}
                            ordersLoader={ordersLoader}
                            handleTableSelected={this.getSelectedRows}
                            leaseItemsHeaders={leaseItemsHeaders}
                            leaseItemsData={leaseItemsData}
                            leaseSubtotal={leaseSubtotal}
                            reloadQuickQuote={this.reloadQuickQuote}
                            quoteAgainFlag={quoteAgainFlag}
                          />
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </div>
                ) : ''
              }
            </Grid>
          )}
          <Grid.Row>
            <Confirm
              open={confirmLeaseType}
              header={t('checkout.confirmPayment')}
              content={`${t('checkout.lease-confirm')} Duración: ${leaseValues.LeaseTerm}, Frecuencia de pago: ${leaseValues.PaymentFrequency === 'MON' ? 'Mensual' : 'Cuatrimestral'}, Monto: ${leaseValues.Amount}?`}
              cancelButton={t('cancel')}
              confirmButton={t('yes')}
              onCancel={this.handleCancelLeaseType}
              onConfirm={this.handleLeasePayment}
            />
          </Grid.Row>
          <Grid.Row>
            <Modal open={openLeaseModal} size="small">
              <Modal.Header content={<Header color="blue" textAlign="center" as="h3">{t('checkout.lease-title')}</Header>} />
              <Modal.Content>
                <Modal.Description>
                  <p>{t('checkout.lease-info')}</p>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color="green" onClick={() => { this.setState({ openLeaseModal: false }); history.push(routeNames.homeComercio.route); }}>{t('checkout.goToHome')}</Button>
                <Button color="blue" onClick={() => { this.setState({ openLeaseModal: false }); history.push(routeNames.quotations.route); }}>Ir a Cotizaciones</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Row>
          <Grid.Row>
            <Modal open={openPaymentBySpeiModal} size="small">
              <Modal.Header content={<Header color="blue" textAlign="center" as="h3">{t('checkout.paymentBySpei')}</Header>} />
              <Modal.Content>
                <Modal.Description>
                  <p>
                    {t('checkout.speiConfirmation')}
                    <a style={{ color: 'blue' }} href={urlSpeiFile} target="_blank" rel="noopener noreferrer">
                      <b>{t('checkout.here')}</b>
                    </a>
                  </p>
                </Modal.Description>
                <Modal.Description>
                  <p>{t('checkout.paymentCompletedByReference')}</p>
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </Grid.Row>
          <Grid.Row>
            <Modal open={openPaymentByOpenpayModal} size="fullscreen">
              <Icon name="window close" onClick={() => this.handleCloseOpenpayForm()} />
              <Modal.Header content={<Header color="blue" textAlign="center" as="h3">{t('checkout.paymentByOpenpay')}</Header>} />
              <Modal.Content>
                <Openpay
                  amount={totalAmount}
                  response={paymentConfirm => {
                    this.handlePaymentConfirmationByCard(paymentConfirm);
                  }}
                  handleLoaderStatusChange={this.handleChangePaymentLoaderStatus}
                  paymentLoader={paymentLoader}
                  readyToPay={readyToPay}
                  validateOrderError={validateOrderError}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button color="black" onClick={() => this.handleCloseOpenpayForm()}>{t('checkout.exit')}</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Row>
          <Grid.Row>
            <Modal open={validateOrderModal}>
              <Modal.Header content={<Header color="blue" textAlign="center" as="h3">{t('checkout.orderValidationHeader')}</Header>} />
              <Modal.Content>
                <Modal.Description>
                  <p>{t('checkout.orderValidationMessage')}</p>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color="blue" onClick={this.handleShoppingCartToQuote}>{t('checkout.accept')}</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Row>
          <Grid.Row>
            <Modal open={modalMessagePaymentByReference}>
              <Icon name="window close" onClick={() => this.setState({ modalMessagePaymentByReference: false })} />
              <Modal.Header content={<Header color="blue" textAlign="center" as="h3">{t('checkout.paymentByReference')}</Header>} />
              <Modal.Content>
                <Modal.Description>
                  <Header color="black" textAlign="center" as="h4"><p>{t('checkout.paymentLimitByReference')}</p></Header>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color="blue" onClick={() => this.setState({ modalMessagePaymentByReference: false })}>{t('checkout.accept')}</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Row>
          <Grid.Row>
            <Modal open={modalByReferenceNumbers}>
              <Icon name="window close" onClick={() => this.setState({ modalByReferenceNumbers: false })} />
              <Modal.Header content={<Header color="blue" textAlign="center" as="h3">{t('checkout.bankReferences')}</Header>} />
              <Modal.Content>
                <Modal.Description>
                  <Header color="blue" textAlign="center" as="h4"><p>Banamex</p></Header>
                  <Header color="black" textAlign="center" as="h4"><p>{t('checkout.banamexAccountNumber')}</p></Header>
                </Modal.Description>
              </Modal.Content>
              <Modal.Content>
                <Modal.Description>
                  <Header color="blue" textAlign="center" as="h4"><p>Bancomer</p></Header>
                  <Header color="black" textAlign="center" as="h4"><p>{t('checkout.bancomerAccountNumber')}</p></Header>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color="blue" onClick={() => this.setState({ modalByReferenceNumbers: false })}>{t('checkout.accept')}</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Row>
          <Grid.Row>
            <Modal open={modalLimitPaymentByCard}>
              <Icon name="window close" onClick={() => this.setState({ modalLimitPaymentByCard: false })} />
              <Modal.Header content={<Header color="blue" textAlign="center" as="h3">{t('Tarjeta bancaria')}</Header>} />
              <Modal.Content>
                <Modal.Description>
                  <Header color="black" textAlign="center" as="h4"><p>{t('checkout.paymentLimitByCard')}</p></Header>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color="blue" onClick={() => this.setState({ modalLimitPaymentByCard: false })}>{t('checkout.accept')}</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

Checkout.defaultProps = {
  t: null,
  history: null,
  updateCartCount: () => { },
};

Checkout.propTypes = {
  t: PropTypes.func,
  history: PropTypes.func,
  updateCartCount: PropTypes.func,
};

export default Checkout;
