import React, { Component } from 'react';
import {
  Header, Grid, Dropdown, Loader, Button, Confirm, Icon, Label, GridRow, GridColumn,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import { io } from 'socket.io-client';
import moment from 'moment';
import * as session from '../common/sessions';
import { analyticsEvent } from '../common/utils';
import Months from '../common/components/months';
import SiclikTable from '../common/components/table';
import Toast from '../common/components/toast';
import Years from '../common/components/years';
import { TextField } from '../common/components/materials';
import quotesService from './services';
import routes from '../route-names';

const DISABLE_CHAT = process.env.REACT_APP_DISABLE_CHAT;

function eventQuotationGA(label) {
  analyticsEvent('Cotizaciones', 'Click cotizaciones', label);
}

class QuotationMain extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    const { userId, customerId } = session.get();
    this.state = {
      customerIdToken: customerId,
      quotesArray: [],
      quotesArrayFiltered: [],
      search: '',
      searchError: '',
      userIdToken: userId,
      quotesHeaders: [
        { key: 'orderId', isKey: true },
        {
          key: 'unreadMessages',
          name: t('quotes.chat'),
          format: (cell, row) => (
            <Grid>
              <GridRow columns={2}>
                <GridColumn largeScreen={5} computer={9} tablet={9} mobile={12}>
                  <Icon name="wechat" size="big" disabled={!row.isInvited} />
                </GridColumn>
                <GridColumn largeScreen={5} computer={9} tablet={9} mobile={12} floated="left">
                  <Label floating circular size="tiny" color="red">
                    {row.unreadMessages}
                  </Label>
                </GridColumn>
              </GridRow>
            </Grid>
          ),
        },
        { key: 'name', name: t('quotes.name') },
        { key: 'dateFormated', name: t('quotes.applicationDate') },
        { key: 'endUserName', name: t('quotes.endUser') },
        { key: 'seller', name: t('quotes.seller') },
        { key: 'userName', name: t('quotes.requester') },
        { key: 'status', name: t('quotes.status') },
        {
          key: 'open',
          name: 'Abrir',
          format: (cell, row) => (
            <Button
              color="blue"
              icon="file outline"
              onClick={() => {
                eventQuotationGA('Abrir cotización');
                props.history.push(`/cotizaciones/detalle/${row.orderId}`);
              }}
            />
          ),
        },
        {
          key: 'actions',
          name: 'Abrir en el carrito',
          format: (cell, row) => (
            <Button
              color="blue"
              icon="shop"
              onClick={() => {
                eventQuotationGA('Abrir cotización en el carrito');
                this.handleClickQuoteToCart(row);
              }}
            />
          ),
        },
        {
          key: 'delete',
          name: 'Eliminar',
          format: (cell, row) => (
            <Button
              color="blue"
              icon="trash"
              onClick={() => {
                eventQuotationGA('Eliminar cotización');
                this.handleDeleteQuotation(row);
              }}
            />
          ),
        },
      ],
      arrayUsers: [],
      isLeaseQuote: false,
      loading: true,
      open: false,
      quoteId: '',
      quoteName: '',
      status: '',
      statusArray: [],
      userId: '',
    };
    const { userIdToken, customerIdToken, order } = this.state;
    this.socket = io(`${process.env.REACT_APP_API_ENDPOINT}:8994`, { query: `userId=${userIdToken}&customerId=${customerIdToken}&orderId=${(order || null)}`, autoConnect: false });
    if (!DISABLE_CHAT) {
      this.socket.connect();
      this.socket.on('server:newMessages', message => {
        this.reloadUnreadMessages(message);
      });
    }
  }

  componentDidMount = () => {
    this.handleAccessPermission();
    this.handleGetStatus();
  }

  reloadUnreadMessages = async message => {
    const { quotesArrayFiltered, userIdToken: userId } = this.state;
    const { orderId } = message;
    if (quotesArrayFiltered.length > 0) {
      const order = quotesArrayFiltered.find(quoute => quoute.orderId === orderId);
      if (order !== undefined) {
        const options = {
          userId,
          orders: [
            { orderId: order.orderId },
          ],
        };
        const { orders } = await quotesService.getUnreadMessages(options);
        const { unreadMessages } = orders.find(orderResult => orderResult.orderId === order.orderId);
        const orderPosition = quotesArrayFiltered.findIndex(quote => quote.orderId === order.orderId);
        if (orderPosition > -1) quotesArrayFiltered[orderPosition].unreadMessages = unreadMessages;
        this.setState({ quotesArrayFiltered });
      }
    }
  };

  handleAccessPermission = () => {
    const { access, userId } = session.get();
    const hasAccess = securityService.validate(access, [
      securityService.getAccess.general,
      securityService.getAccess.compra,
      securityService.getAccess.cotizacion,
      securityService.getAccess.finanzas,
      securityService.getAccess.envios,
      securityService.getAccess.pedidos,
      securityService.getAccess.usuarios,
    ]);
    if (hasAccess) {
      this.handleGetUsers();
      this.setState({ visibleUsers: true });
    }
    this.setState({ userId });
    this.handleGetQuotations(userId, 0);
  }

  handleGetQuotations = (userId, getAll) => {
    const { t } = this.props;
    const statusArray = [];
    this.setState({ loading: true });
    quotesService.getQuotationsByUser(userId, getAll)
      .then(quotes => {
        const quotation = quotes.quotation.map(quote => {
          const dateFormated = moment(quote.date).format('DD-MM-YYYY');
          statusArray.push(quote.status);
          return { ...quote, dateFormated };
        }).filter(quote => quote.isLeaseQuote === false);
        const modifiedQuotes = { ...quotes, quotation };
        this.handleGetStatus(statusArray);
        this.setState({ quotesArray: modifiedQuotes.quotation, quotesArrayFiltered: modifiedQuotes.quotation, loading: false });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ quotesArray: [], quotesArrayFiltered: [], loading: false });
        Toast(messageError, 'error');
      });
  }

  handleGetStatus = statusItems => {
    if (statusItems && statusItems.length > 0) {
      const status = statusItems.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
      const statusArray = status.map(order => { const formatedStatus = { key: order, text: order, value: order }; return formatedStatus; });
      this.setState({ statusArray });
    }
  }

  handleGetUsers = () => {
    const { t } = this.props;
    const arrayUsers = [];
    const { customerId } = session.get();
    quotesService.getUsers(customerId)
      .then(response => {
        response.users.map(user => {
          if (/* user.confirmedAccount === 1 && */ user.name && user.name.length > 1) {
            arrayUsers.push({ key: user.id, text: user.name, value: user.id });
          }
          return user;
        });
        arrayUsers.push({ key: 'all', text: 'Todas las cotizaciones', value: 'all' });
        this.setState({ arrayUsers });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  validations = event => {
    const { t } = this.props;
    const { quotesArray } = this.state;
    const maxLength = 31;
    let mistakes = false;
    const { value: search } = event.target;
    const quotesArrayFiltered = JSON.parse(JSON.stringify(quotesArray));
    if ((search.trim()).length > maxLength) {
      this.setState({ search, searchError: t('branchOffice.errors.toLong') });
      mistakes = true;
      return;
    }
    if (mistakes !== true) this.setState({ search, searchError: '' }); this.filterGeneral(search, 1, quotesArrayFiltered);
  }

  handleYearChange = year => {
    const { quotesArray } = this.state;
    const quotesArrayFiltered = JSON.parse(JSON.stringify(quotesArray));
    this.setState({ year });
    this.filterGeneral(year, 2, quotesArrayFiltered);
  }

  handleMonthDeadLineChange = monthDeadLine => {
    const { quotesArray } = this.state;
    const quotesArrayFiltered = JSON.parse(JSON.stringify(quotesArray));
    this.setState({ monthDeadLine });
    this.filterGeneral(monthDeadLine, 3, quotesArrayFiltered);
  }

  handleMonthApplicantChange = monthApplicant => {
    const { quotesArray } = this.state;
    const quotesArrayFiltered = JSON.parse(JSON.stringify(quotesArray));
    this.setState({ monthApplicant });
    this.filterGeneral(monthApplicant, 5, quotesArrayFiltered);
  }

  handleStatusChange = (e, { value }) => {
    const { quotesArray } = this.state;
    const quotesArrayFiltered = JSON.parse(JSON.stringify(quotesArray));
    this.setState({ status: value });
    this.filterGeneral(value, 4, quotesArrayFiltered);
  }

  filterGeneral = (value, typeFilter, quotesArrayFiltered) => {
    let {
      search, year, monthDeadLine, monthApplicant, status,
    } = this.state;
    let copyQuotesArrayFiltered = [...quotesArrayFiltered];
    if (typeFilter === 1) search = value;
    if (typeFilter === 2) year = value;
    if (typeFilter === 3) monthDeadLine = value;
    if (typeFilter === 4) status = value;
    if (typeFilter === 5) monthApplicant = value;

    if (search) copyQuotesArrayFiltered = this.filterForSearch(search, quotesArrayFiltered);
    if (year) copyQuotesArrayFiltered = this.filterForYear(year, quotesArrayFiltered);
    if (monthDeadLine) copyQuotesArrayFiltered = this.filterForMonthDeadLine(monthDeadLine, quotesArrayFiltered);
    if (status) copyQuotesArrayFiltered = this.filterForStatus(status, quotesArrayFiltered);
    if (monthApplicant) copyQuotesArrayFiltered = this.filterForMonthApplicant(monthApplicant, quotesArrayFiltered);
    // eslint-disable-next-line react/no-unused-state
    this.setState({ copyQuotesArrayFiltered });
  }

  filterForSearch = (filter, quotesToFilter) => {
    let foundData = [];
    foundData = quotesToFilter.filter(filterInfo => (filterInfo.name && filterInfo.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      || (filterInfo.endUserName && filterInfo.endUserName.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      || (filterInfo.seller && filterInfo.seller.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      || (filterInfo.userName && filterInfo.userName.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
    return foundData;
  }

  filterForYear = (year, ordersToFilter) => {
    let foundData = [];
    foundData = ordersToFilter.filter(filterInfo => Number(filterInfo.dateFormated.substring(6, 10)) === Number(year));
    return foundData;
  }

  filterForMonthDeadLine = (month, quotesToFilter) => {
    let foundData = [];
    foundData = quotesToFilter.filter(filterInfo => Number(filterInfo.effectiveDate.substring(3, 5)) === Number(month));
    return foundData;
  }

  filterForMonthApplicant = (month, quotesToFilter) => {
    let foundData = [];
    foundData = quotesToFilter.filter(filterInfo => Number(filterInfo.dateFormated.substring(3, 5)) === Number(month));
    return foundData;
  }

  filterForStatus = (status, quotesToFilter) => {
    let foundData = [];
    foundData = quotesToFilter.filter(filterInfo => filterInfo.status === status);
    return foundData;
  }

  handleClickQuoteToCart = quote => {
    this.setState({
      quoteId: quote.orderId, quoteName: quote.name, open: true, isLeaseQuote: quote.isLeaseQuote,
    });
  }

  deleteLeaseQuote = quoteId => (
    quotesService.deleteLeaseQuote(quoteId)
  )

  handleConfirmQuoteToCart = () => {
    const { t, updateCartCount, history } = this.props;
    const { quoteId, isLeaseQuote } = this.state;
    quotesService.quoteToShoppingCart(quoteId)
      .then(changeQuote => {
        if (changeQuote.changedRows && changeQuote.changedRows === 1) {
          if (isLeaseQuote) {
            try {
              this.deleteLeaseQuote(quoteId);
            } catch (error) {
              Toast(t('quotes.cantDeleteLeaseQuote'), 'warning');
            }
          }
          updateCartCount();
          history.push(routes.shoppingCart.route);
        } else {
          Toast('Ocurrio un error al abrir la cotización, favor de intentarlo nuevamente', 'error');
        }
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ quotesArray: [], quotesArrayFiltered: [], loading: false });
        Toast(messageError, 'error');
      });
  }

  handleChangeUser = (e, { value }) => {
    const { userIdToken } = this.state;
    this.setState({ userId: value });
    let getAll = 0;
    let modifiedValue = value;
    if (value === 'all') {
      modifiedValue = userIdToken;
      getAll = 1;
    } else {
      getAll = 0;
    }
    this.handleGetQuotations(modifiedValue, getAll);
  }

  handleDeleteQuotation = row => {
    this.setState({
      openDelete: true, quoteName: row.name, quoteId: row.orderId, isLeaseQuote: row.isLeaseQuote,
    });
  }

  handleConfirmDelete = () => {
    const { t } = this.props;
    const {
      quoteId, search, quotesArray, isLeaseQuote,
    } = this.state;
    quotesService.deleteQuotations(quoteId).then(() => {
      Toast(t('Cotización eliminada'), 'success');
      const quoteDeleted = quotesArray.findIndex(quote => quote.orderId === quoteId);
      quotesArray.splice(quoteDeleted, 1);
      this.filterGeneral(search, 4, quotesArray);
      if (isLeaseQuote) {
        try {
          this.deleteLeaseQuote(quoteId);
        } catch (error) {
          Toast(t('quotes.cantDeleteLeaseQuote'), 'warning');
        }
      }
      this.setState({ quotesArray, openDelete: false });
    }).catch(error => Toast(t(`error-codes.${error.code}`, 'error')));
  }

  render() {
    const { t } = this.props;
    const {
      visibleUsers, search, searchError, quotesArrayFiltered, quotesHeaders, statusArray, status, loading,
      arrayUsers, open, quoteName, userId, openDelete,
    } = this.state;
    return (
      <Grid container style={{ paddingTop: '1rem' }}>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={5} tablet={6} mobile={15}>
            <Header color="blue" as="h1">{t('quotes.quotes')}</Header>
          </Grid.Column>
          {visibleUsers && arrayUsers && arrayUsers.length > 0
            ? (
              <Grid.Column largeScreen={4} computer={5} tablet={6}>
                <Dropdown
                  options={arrayUsers}
                  placeholder={t('quotes.chooseQuotes')}
                  onChange={this.handleChangeUser}
                  value={userId}
                  search
                  selection
                />
              </Grid.Column>
            )
            : null}
        </Grid.Row>
        {loading
          ? <Loader active inline="centered" />
          : (
            <Grid.Row columns={4}>
              <Grid.Column largeScreen={5} computer={9} tablet={9} mobile={12}>
                <TextField
                  fullWidth
                  maxLength={30}
                  value={search || ''}
                  error={searchError || ''}
                  label={t('quotes.search')}
                  onChange={this.validations}
                />
              </Grid.Column>
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={2}>
                <Years min={2010} max={2030} change={this.handleYearChange} />
              </Grid.Column>
              <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={15}>
                <Months
                  title="quotes.monthApplicant"
                  tr={t}
                  change={this.handleMonthApplicantChange}
                />
              </Grid.Column>
              <Grid.Column largeScreen={3} computer={4} tablet={4} mobile={15}>
                <Dropdown
                  selection
                  value={status}
                  options={statusArray}
                  placeholder={t('quotes.status')}
                  onChange={this.handleStatusChange}
                  clearable
                />
              </Grid.Column>
            </Grid.Row>
          )}
        {
          loading
            ? null
            : (
              <Grid.Row centered>
                <Grid.Column style={{ overflowX: 'auto' }}>
                  <SiclikTable
                    selectable
                    data={quotesArrayFiltered}
                    headers={quotesHeaders}
                    pageSize={10}
                    singleSelection
                  />
                </Grid.Column>
              </Grid.Row>
            )
        }
        <Grid.Row>
          <Confirm
            open={open}
            header={`${t('quotes.quoteToCartTitle')} ${quoteName} ${t('quotes.quoteToCartTitleComplement')}`}
            content={t('quotes.quoteToCartContent')}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({ open: false })}
            onConfirm={this.handleConfirmQuoteToCart}
          />
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={openDelete}
            header={t('¿Estas seguro de eliminar la siguiente cotización?')}
            content={quoteName}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({ openDelete: false })}
            onConfirm={this.handleConfirmDelete}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

QuotationMain.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  updateCartCount: PropTypes.func.isRequired,
};

export default QuotationMain;
