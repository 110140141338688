/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
const url = process.env.REACT_APP_API_ENDPOINT;
// console.log('enviroment:', process.env.REACT_APP_ENV, process.env.REACT_APP_API_ENDPOINT);

const apiAuthentication = `${url}:8999`;
const apiSiclik = `${url}:8998`;
const apiSiclikComercio = `${url}:8997`;
const apiChat = `${url}:8994`;
const apiNotifications = `${url}:8995`;

const notifications = {
  agentReviewNotification: () => `${apiNotifications}/cart/agents/validate-coupon`,
  sendReviewNotification: () => `${apiNotifications}/orders/review`,
};

const authentication = {
  login: () => `${apiAuthentication}/auth/login`,
  requestNewPassword: () => `${apiAuthentication}/auth/forgot-password`,
  resetPassword: () => `${apiAuthentication}/auth/reset-password`,
  authenticationCode: () => `${apiAuthentication}/users/secret-key`,
  validateCode: (secretKey, userId) => `${apiAuthentication}/users/secret-key/${secretKey}/validate/${userId}`,
  tokenSuscribe: () => `${apiAuthentication}/token/suscribe`,
  refreshToken: () => `${apiAuthentication}/auth/token`,
  validateTokenSession: token => `${apiAuthentication}/sessions/recovery/${token}/validate`,
  guestSession: () => `${apiAuthentication}/sessions/guest-users`,
  externalSession: () => `${apiAuthentication}/sessions/external`,
};

const siclikAuth = {
  authSiclik: () => `${apiSiclikComercio}/auth/siclik`,
};

const siclik = {
  addProfileImage: () => `${apiSiclik}/users/image/profile`,
  getSignedUrl: () => `${apiSiclik}/upload-s3/signed-url`,
  getFilesS3: () => `${apiSiclik}/get-s3/details`,
  generateSignedUrl: () => `${apiSiclik}/aws/s3/signed-url`,
  deleteImageS3: () => `${apiSiclik}/delete-s3/object`,
  getFileS3: () => `${apiSiclik}/get-s3/details`,
  deleteFileS3: () => `${apiSiclik}/delete-s3/object`,
  getSteps: () => `${apiSiclik}/onboarding/users/steps`,
  getUserInformation: userId => `${apiSiclik}/users/${userId}`,
  validatePostalCode: postalCode => `${apiSiclik}/addresses/${postalCode}`,
  updateUser: () => `${apiSiclik}/users`,
  updateStep: () => `${apiSiclik}/onboarding/users/steps`,
  updatePassword: () => `${apiSiclik}/users/password`,
  getCustomerUsers: customerId => `${apiSiclik}/customers/${customerId}/users`,
  updateCustomerUsers: userId => `${apiSiclik}/users/${userId}/roles`,
  getRoles: (queryParams = '') => `${apiSiclik}/roles${queryParams}`,
  addFinalUsers: () => `${apiSiclik}/customers/end-users`,
  getFinalUsers: customerId => `${apiSiclik}/customers/end-users?${customerId ? `customerId=${customerId}` : ''}`,
  getAccesTokenPowerBI: () => `${apiSiclik}/power-bi/oauth2/token`,
  getShoppingCart: () => `${apiSiclik}/shopping-cart/orders`,
  addUser: () => `${apiSiclik}/users`,
  getCustomerById: customerId => `${apiSiclik}/customers/${customerId}`,
  deleteUserById: userId => `${apiSiclik}/users/${userId}/disable`,
  getProcessStatus: () => `${apiSiclik}/orders/steps`,
  getCustomersGroups: () => `${apiSiclik}/groups/customers`,
  addDiscount: () => `${apiSiclik}/coupons/couponsHeaders`,
  updateDiscount: () => `${apiSiclik}/coupons/couponsHeaders`,
  getDiscountById: discountId => `${apiSiclik}/coupons/${discountId}/headerById`,
  getDiscountDetail: discountId => `${apiSiclik}/coupons/${discountId}/couponDetailById`,
  addDiscountDetail: () => `${apiSiclik}/coupons/couponDetail`,
  updateDiscountDetail: () => `${apiSiclik}/coupons/couponsDetail`,
  disableEndUser: userId => `${apiSiclik}/customers/end-users/${userId}`,
  getCouponsByCustomerId: customerId => `${apiSiclik}/coupons/${customerId}/couponsByCustomer`,
  getCfdi: () => `${apiSiclik}/billing/cfdi`,
  updateFinalUser: () => `${apiSiclik}/customers/end-users`,
  getBehaviorTypesDiscounts: () => `${apiSiclik}/coupons/discountStructure`,
  getTypesDiscounts: () => `${apiSiclik}/coupons/DiscountMode`,
  getGroups: type => `${apiSiclik}/coupons/group/${type}`,
  getMembersGroupsById: (groupId, type) => `${apiSiclik}/coupons/groups/${type}/elements/${groupId}`,
  addGroup: () => `${apiSiclik}/coupons/group`,
  updateGroup: () => `${apiSiclik}/coupons/group`,
  deleteGroup: (type, groupId) => `${apiSiclik}/coupons/group/${type}/element/${groupId}`,
  addElementToGroup: () => `${apiSiclik}/coupons/group/element`,
  deleteElementOfGroup: (type, groupId) => `${apiSiclik}/coupons/group/${type}/relationship/${groupId}`,
  getDiscountsList: (userId, getAll) => `${apiSiclik}/coupons/coupon-list/user/${userId}/${getAll}`,
  deleteDiscountById: discountId => `${apiSiclik}/coupons/element/${discountId}`,
  getFinalUsersByRFC: rfc => `${apiSiclik}/end-users/${rfc}`,
  getCfdiAttachBuffer: () => `${apiSiclik}/orders/cfd-attachment`,
  getDaysDiscounts: () => `${apiSiclik}/coupons/discountDays`,
  addDiscountReview: () => `${apiSiclik}/coupons/approveCoupon`,
  getAgents: () => `${apiSiclik}/marketing/agents`,
  updateUserAdminRoles: userId => `${apiSiclik}/users/${userId}/admin/roles`,
  addAdminUser: () => `${apiSiclik}/users/admin`,
  getBannersByType: bannerTypeId => `${apiSiclik}/banners/${bannerTypeId}`,
  getDetailedPromotionals: () => `${apiSiclik}/promotionals`,
  getBannerTypes: () => `${apiSiclik}/promotional/types`,
  getBannerActions: () => `${apiSiclik}/promotional/actions`,
  addBanner: () => `${apiSiclik}/promotional`,
  updateBanner: () => `${apiSiclik}/promotional`,
  deleteBanner: bannerId => `${apiSiclik}/promotional/${bannerId}`,
  getFodaByCustomerId: (customerId, year, type) => `${apiSiclik}/customers/${customerId}/form/${type}/year/${year}`,
  addBussinesPlanItem: () => `${apiSiclik}/customers/business-plan`,
  updateBussinesPlanItem: () => `${apiSiclik}/customers/business-plan/item`,
  deleteBussinesPlanItem: itemId => `${apiSiclik}/customers/business-plan/item/${itemId}`,
  getBussinesPlanCategories: type => `${apiSiclik}/customers/business-plan/categories${type ? `?type=${type}` : ''}`,
  addBusinessPlanItem: () => `${apiSiclik}/customers/business-plan`,
  updateBusinessPlanItem: () => `${apiSiclik}/customers/business-plan/item`,
  deleteBusinessPlanItem: itemId => `${apiSiclik}/customers/business-plan/item/${itemId}`,
  getBusinessPlanCategories: type => `${apiSiclik}/customers/business-plan/categories${type ? `?type=${type}` : ''}`,
  addCrossSellingHeader: () => `${apiSiclik}/customers/cross-selling/header`,
  addCrossSellingItem: () => `${apiSiclik}/customers/cross-selling/item`,
  deleteCrossSellingHeader: crossSellingId => `${apiSiclik}/customers/cross-selling/header/${crossSellingId}`,
  getCrossSellingByCustomerId: (customerId, year) => `${apiSiclik}/customers/${customerId}/cross-selling/year/${year}`,
  deleteCrossSellingItem: itemId => `${apiSiclik}/customers/cross-selling/item/${itemId}`,
  updateCrossSellingHeader: () => `${apiSiclik}/customers/cross-selling/header`,
  getGroupsByCustomerId: customerId => `${apiSiclik}/groups/${customerId}`,
  getBusinessPlanClients: (customerId, year) => `${apiSiclik}/business-plan/clients/customer/${customerId}/year/${year}`,
  addBusinessPlanClientItem: () => `${apiSiclik}/business-plan/client`,
  deleteBusinessPlanClientItem: itemId => `${apiSiclik}/business-plan/client/${itemId}`,
  updateBusinessPlanClientItem: () => `${apiSiclik}/business-plan/client`,
  getIndustriesPercentByCustomerId: (customerId, year) => `${apiSiclik}/business-plan/segments/customer/${customerId}/year/${year}`,
  addIndustriesPercentItem: () => `${apiSiclik}/business-plan/segment-percent`,
  deleteIndustriesPercentItem: itemId => `${apiSiclik}/business-plan/segment-percent/${itemId}`,
  updateIndustriesPercentItem: () => `${apiSiclik}/business-plan/segment-percent`,
  getIndustries: () => `${apiSiclik}/industries`,
  getBusinessPlanElementsByCategoryDescription: (customerId, year, categoryDescription) => `${apiSiclik}/business-plan/customer/${customerId}/description/${categoryDescription}/year/${year}`,
  getUsersHasPermission: () => `${apiSiclik}/coupons/users-list-has-permission`,
  deleteUsersHasPermission: id => `${apiSiclik}/coupons/authorizer/users/${id}`,
  addUsersHasPermission: () => `${apiSiclik}/coupons/users`,
  getCouponsByUen: uenId => `${apiSiclik}/coupons/${uenId}`,
  getAnnualPlanByCustomer: (customerId, year, makerId) => `${apiSiclik}/business-plan/corporate-goals/customerId/${customerId}/year/${year}/makerId/${makerId}`,
  addCorporateGoals: () => `${apiSiclik}/customers/business-plan/corporate-goals`,
  updateCorporateGoals: () => `${apiSiclik}/customers/business-plan/corporate-goals`,
  deleteCorporateGoals: objetiveId => `${apiSiclik}/customers/business-plan/corporate-goals/${objetiveId}`,
  addGoalIndicators: () => `${apiSiclik}/customers/business-plan/goals-indicators`,
  updateGoalIndicators: () => `${apiSiclik}/customers/business-plan/goals-indicators`,
  deleteIndicator: indicatorId => `${apiSiclik}/customers/business-plan/goals-indicators/${indicatorId}`,
  addIndicatorStrategy: () => `${apiSiclik}/customers/business-plan/goals-tactics`,
  updateStrategy: () => `${apiSiclik}/customers/business-plan/goals-tactics`,
  deleteStrategy: strategyId => `${apiSiclik}/customers/business-plan/goals-tactics/${strategyId}`,
  getUsersCoupons: () => `${apiSiclik}/users-coupons`,
  getHomeComercioAssets: () => `${apiSiclik}/comercio/assets`,
  getMaintenanceAssets: () => `${apiSiclik}/maintenance/assets`,
  getCommonSearchs: ({ startDate, endDate }) => `${apiSiclik}/report/searchs?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`,
  addAdminCoupons: () => `${apiSiclik}/users/admin-coupons`,
  disableAdminCoupons: userId => `${apiSiclik}/admin-coupons/${userId}/disable`,
  getCouponsBySku: sku => `${apiSiclik}/coupons-by-sku/${sku}`,
  getAllUsers: () => `${apiSiclik}/users`,
  getCustomersByUser: () => `${apiSiclik}/users-customers`,
  disableUserByCustomer: () => `${apiSiclik}/user-customer`,
  addSurveyResponse: () => `${apiSiclik}/survey`,
  getSurveyResponse: () => `${apiSiclik}/survey`,
  getSurveyReport: () => `${apiSiclik}/report/survey`,
  disableSurvey: platformId => `${apiSiclik}/survey/disable/${platformId}`,
  getSegmentByCustomer: customerId => `${apiSiclik}/customers/${customerId}/segment`,
  addLogRestartSurvey: () => `${apiSiclik}/log-survey`,
  getLogRestartSurvey: () => `${apiSiclik}/log-survey`,
  getLogUserReport: () => `${apiSiclik}/log-user`,
  analyticEvent: () => `${apiSiclik}/analytics/event`,
};

const comercio = {
  getCategories: () => `${apiSiclikComercio}/products/categories`,
  getBranchOfficeAddress: customerFromOrder => `${apiSiclikComercio}/customers/branch-offices?${customerFromOrder ? `customerFromOrder=${customerFromOrder}` : ''}`,
  getAddressById: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}`,
  getSchedule: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/schedules`,
  addDocuments: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/documents`,
  updateDocuments: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/documents`,
  deleteDocuments: (branchOfficeId, documentId) => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/documents/${documentId}`,
  getManeuvers: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/maneuvers`,
  addManeuver: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/maneuvers`,
  updateManeuver: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/maneuver`,
  deleteManeuver: (branchOfficeId, maneuverId) => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/maneuvers/${maneuverId}`,
  addComment: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/comments`,
  updateComment: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/comments`,
  deleteComment: (branchOfficeId, commentId) => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/comments/${commentId}`,
  getDocuments: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/documents`,
  getComments: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/comments`,
  addSchedule: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}/schedules`,
  addAddress: () => `${apiSiclikComercio}/customers/branch-offices`,
  updateAddress: () => `${apiSiclikComercio}/customers/branch-offices`,
  getProductById: productId => `${apiSiclikComercio}/product/${productId}`,
  getStockById: sku => `${apiSiclikComercio}/products/${sku}/stock`,
  getStockOcById: productId => `${apiSiclikComercio}/stock-oc/${productId}`,
  getProductBySku: (productId, isVirtual) => `${apiSiclikComercio}/products/${productId}/${isVirtual}/details`,
  getProductInformation: (sku, allowVariants = false) => `${apiSiclikComercio}/product-catalog/details/${encodeURIComponent(sku)}?allowVariants=${allowVariants}`,
  getOutletById: productId => `${apiSiclikComercio}/products/${productId}/stock/outled`,
  getRelatedProducts: productId => `${apiSiclikComercio}/products/${encodeURIComponent(productId)}/related`,
  searchProducts: (category, keywords) => `${apiSiclikComercio}/products?${Number(category) ? `categoryId=${category}&` : ''}${keywords ? `keyword=${keywords}` : ''}`,
  search: filters => `${apiSiclikComercio}/product-catalog/search${filters}`,
  getAllCatalog: () => `${apiSiclikComercio}/product-catalog`,
  getOutletCatalog: () => `${apiSiclikComercio}/product-catalog/outlet`,
  getBundleCatalog: () => `${apiSiclikComercio}/product-catalog/bundle`,
  getOrdersByUser: userId => `${apiSiclikComercio}/order/${userId}`,
  getOrdersStatus: () => `${apiSiclikComercio}/orders/status`,
  getOrderDetails: orderId => `${apiSiclikComercio}/intelisis/orders/${orderId}`,
  getParcelPrice: () => `${apiSiclikComercio}/shipping/price`,
  getStandardShippingPrice: () => `${apiSiclikComercio}/shipping/standard/price`,
  getActiveOrder: () => `${apiSiclikComercio}/shopping-cart/items?comment=1&attaches=1`,
  getDocumentsTypes: () => `${apiSiclikComercio}/documents/types`,
  getShoppingCartTotalItems: () => `${apiSiclikComercio}/shopping-cart/items/count`,
  getDeliveryTraking: orderId => `${apiSiclikComercio}/shipping/status/${orderId}`,
  contactAgent: () => `${apiSiclikComercio}/shipping/contact-agent`,
  getQuotationsByUser: (userId, getAll) => `${apiSiclikComercio}/quotation/user/${userId}/${getAll}`,
  addBrandsData: () => `${apiSiclikComercio}/orders/maker-form`,
  getAllListProducts: () => `${apiSiclikComercio}/products/allLists`,
  addItems: () => `${apiSiclikComercio}/shopping-cart/items`,
  addPricesMaker: () => `${apiSiclikComercio}/shopping-cart/marker-prices`,
  updateCartComment: () => `${apiSiclikComercio}/shopping-cart/comment`,
  updateProductQuantity: sku => `${apiSiclikComercio}/shopping-cart/items/${sku}`,
  getCustomers: () => `${apiSiclikComercio}/customers/allCustomers`,
  disableBranchOffice: branchOfficeId => `${apiSiclikComercio}/customers/branch-offices/${branchOfficeId}`,
  generateTokenIquote: () => `${apiSiclikComercio}/iquote/token`,
  quoteToShoppingCart: quoteId => `${apiSiclikComercio}/quotation/${quoteId}/send-cart`,
  shoppingCartToQuote: () => `${apiSiclikComercio}/shopping-cart/send-quote`,
  getItems: () => `${apiSiclikComercio}/shopping-cart/items`,
  deleteShoppingCartProduct: (sku, outlet) => `${apiSiclikComercio}/shopping-cart/items/${sku}/outlet/${outlet}`,
  getExchangeRates: () => `${apiSiclikComercio}/intelisis/exchange-rates`,
  updateShoppingCart: () => `${apiSiclikComercio}/shopping-cart/config`,
  getDeliveryConfig: orderId => `${apiSiclikComercio}/shopping-cart/delivery-config${!orderId ? '' : `?orderId=${orderId}`}`,
  addDeliveryConfig: () => `${apiSiclikComercio}/shopping-cart/delivery-config`,
  addAttaches: () => `${apiSiclikComercio}/shopping-cart/add-attachment`,
  createCustomer: () => `${apiSiclikComercio}/payments/customer`,
  createReference: () => `${apiSiclikComercio}/payments/reference`,
  saveCard: () => `${apiSiclikComercio}/payments/card`,
  getCustomerDetails: userId => `${apiSiclikComercio}/payments/${userId}/customer`,
  paymentProcess: () => `${apiSiclikComercio}/payments/process`,
  getAccessKeys: () => `${apiSiclikComercio}/payments/oauth`,
  createSpeiReference: () => `${apiSiclikComercio}/payments/spei/reference`,
  getAttachments: orderId => `${apiSiclikComercio}/shopping-cart/${orderId}/attachments`,
  getAllProducts: () => `${apiSiclikComercio}/products/available`,
  checkout: () => `${apiSiclikComercio}/shopping-cart/checkout`,
  getDataFormMakers: orderId => `${apiSiclikComercio}/orders/${orderId}/maker-form`,
  updateQuoteReview: () => `${apiSiclikComercio}/orders/reviews/resolution`,
  quoteCheckout: () => `${apiSiclikComercio}/quotation/checkout`,
  deleteQuotationsById: quoteId => `${apiSiclikComercio}/quotation/${quoteId}`,
  addCoupons: () => `${apiSiclikComercio}/shopping-cart/coupons`,
  updateShippingPriceByAgent: () => `${apiSiclikComercio}/order/detail/shipping/updateShippingPriceByAgent`,
  getQuotationsAgentByUser: (userId, getAll) => `${apiSiclikComercio}/quotation/agent/${userId}/${getAll}`,
  buy: () => `${apiSiclikComercio}/shopping-cart/buy`,
  getBillingByMovId: (customerId, movId) => `${apiSiclikComercio}/intelisis/billing/${customerId}/movid/${movId}`,
  deleteAttachment: (orderId, attachId) => `${apiSiclikComercio}/shopping-cart/attaches/${orderId}/${attachId}`,
  deleteShoppingCart: () => `${apiSiclikComercio}/shopping-cart`,
  getQuoteReviews: orderId => `${apiSiclikComercio}/orders/${orderId}/reviews`,
  getCustomerCredit: (customerFromParams = '') => `${apiSiclikComercio}/customers/credit${customerFromParams}`,
  getAllOrdersAgent: () => `${apiSiclikComercio}/orders`,
  getOrdersByAgent: agentId => `${apiSiclikComercio}/intelisis/orders/agents/${agentId}`,
  getAllMakers: () => `${apiSiclikComercio}/makers`,
  getPaymentMethods: () => `${apiSiclikComercio}/payment-methods`,
  uploadCatalogImage: () => `${apiSiclikComercio}/products/upload/image`,
  addReview: orderId => `${apiSiclikComercio}/orders/${orderId}/reviews`,
  addUserToOrder: orderId => `${apiSiclikComercio}/orders/${orderId}/relate-user`,
  getParcels: () => `${apiSiclikComercio}/parcels`,
  getAllImages: productId => `${apiSiclikComercio}/products/images/${productId}`,
  getAllFamilies: () => `${apiSiclikComercio}/product/all-families`,
  getAttributesFamilies: () => `${apiSiclikComercio}/families-attributes`,
  addFamilyKeywords: () => `${apiSiclikComercio}/family-keyword`,
  deleteFamilyKeyword: attributeId => `${apiSiclikComercio}/disable-keyword/${attributeId}`,
  updateFamilyKeywords: () => `${apiSiclikComercio}/keyword/updateKeywords`,
  addFlexAttachProducts: () => `${apiSiclikComercio}/flex-attach/list`,
  dropImage: sku => `${apiSiclikComercio}/products/images/${sku}`,
  updateMainImage: () => `${apiSiclikComercio}/products/images`,
  getLeaseQuote: () => `${apiSiclikComercio}/hpefs/quick-quote`,
  saveLeaseQuote: () => `${apiSiclikComercio}/hpefs/quick-quote/config`,
  getAgentsByCustomer: customerId => `${apiSiclikComercio}/agents/${customerId}`,
  addAgents: () => `${apiSiclikComercio}/new-agents`,
  deleteAgentById: id => `${apiSiclikComercio}/agents/${id}`,
  getAllAgents: () => `${apiSiclikComercio}/agents/all-agents`,
  getBillingDetailsByMovId: movId => `${apiSiclikComercio}/intelisis/billing/details/${movId}`,
  getAgentDirectory: () => `${apiSiclikComercio}/agent/directory`,
  getAgentsRoles: () => `${apiSiclikComercio}/agent/roles`,
  getCustomersWithCredit: () => `${apiSiclikComercio}/all-customers/credit`,
  getAgentMakers: () => `${apiSiclikComercio}/agent/makers`,
  getLeaseQuoteByOrder: orderId => `${apiSiclikComercio}/hpefs/${orderId}/quote`,
  buyQuotation: () => `${apiSiclikComercio}/hpefs/buy`,
  getWishLists: () => `${apiSiclikComercio}/users/wish-list`,
  itemsWishList: wishListId => `${apiSiclikComercio}/users/wish-list/${wishListId}/items`,
  removeItemFromWishList: (wishListId, sku) => `${apiSiclikComercio}/users/wish-list/${wishListId}/items/${sku}`,
  generateQuickCart: () => `${apiSiclikComercio}/shopping-cart/quick`,
  buyQuickCart: () => `${apiSiclikComercio}/shopping-cart/quick/buy`,
  renameWishList: (wishListId, name) => `${apiSiclikComercio}/users/wish-list/${wishListId}/name/${name}`,
  deleteWishList: wishListId => `${apiSiclikComercio}/users/wish-list/${wishListId}`,
  getAttributesByKeywords: keyword => `${apiSiclikComercio}/products/attributes/${encodeURIComponent(keyword)}`,
  searchSuggestions: keyword => `${apiSiclikComercio}/product-catalog/suggestions/${encodeURIComponent(keyword)}`,
  addDepRequest: () => `${apiSiclikComercio}/dep/add-request`,
  deleteLeaseQuote: quoteId => `${apiSiclikComercio}/hpefs/${quoteId}/quote`,
  deleteReviewById: () => `${apiSiclikComercio}/orders/reviews/disabled`,
  updateAgentDirectory: () => `${apiSiclikComercio}/modify-agent`,
  getPublicAgents: customerId => `${apiSiclikComercio}/public-customer-agents?customerId=${customerId}`,
  updateDirectoryAgentByEmail: () => `${apiSiclikComercio}/directory-agent`,
  deleteDirectoryAgentByEmail: email => `${apiSiclikComercio}/directory-agent/${email}`,
  replaceAgentDirectory: () => `${apiSiclikComercio}/replace-agent`,
  satisfactionSurvey: () => `${apiSiclikComercio}/orders/satisfaction-survey`,
  stockCheckout: sku => `${apiSiclikComercio}/products/${sku}/stock`,
  pdfQuotation: orderId => `${apiSiclikComercio}/quotation/pdf/${orderId}`,
  getSteppedPrice: productId => `${apiSiclikComercio}/products/${productId}/price/stepped`,
  getSeriesStatus: billing => `${apiSiclikComercio}/dep/series/status/${billing}`,
  getCuponsByOrder: orderId => `${apiSiclikComercio}/orders/${orderId}/coupons`,
  getAttachesByOrder: orderId => `${apiSiclikComercio}/orders/${orderId}/attachments`,
  validateOrder: () => `${apiSiclikComercio}/shopping-cart/validate`,
  getBundleOptionsBySku: bundle => `${apiSiclikComercio}/products/options/${bundle}`,
  getQuotationByCustomerId: customerId => `${apiSiclikComercio}/quotations/customers/${customerId}`,
  getOrdersByCustomerId: customerId => `${apiSiclikComercio}/intelisis/orders/customers/${customerId}`,
  getVeeamOrders: () => `${apiSiclikComercio}/orders/veeam/orders`,
  getVeeamOrderById: requestId => `${apiSiclikComercio}/orders/veeam/${requestId}`,
  getProductDescription: productId => `${apiSiclikComercio}/product/description/${productId}`,
  addProductDescription: () => `${apiSiclikComercio}/product/description`,
  getPromotionalMessage: productId => `${apiSiclikComercio}/product/promotional/${productId}`,
  addPromotionalMessage: () => `${apiSiclikComercio}/product/promotional`,
  addProductsSpecifications: () => `${apiSiclikComercio}/product/specifications`,
  updateProductsSpecificationsById: () => `${apiSiclikComercio}/product/specifications/item`,
  getProductsSpecificationsBySku: productId => `${apiSiclikComercio}/product/specifications/${productId}`,
  disableProductsSpecificationsById: () => `${apiSiclikComercio}/product/disable/specifications/item`,
  getRequiredProductsBySku: sku => `${apiSiclikComercio}/products/${sku}/vmware-required`,
  getSubstituteProducts: sku => `${apiSiclikComercio}/product/substitute/${encodeURIComponent(sku)}`,
  validateIquoteCart: quoteId => `${apiSiclikComercio}/iquote/validate-quote/${quoteId}`,
  getInternRelated: sku => `${apiSiclikComercio}/products/intern/related/${sku}`,
  deleteInternRelated: id => `${apiSiclikComercio}/products/related/${id}`,
  addInternRelated: sku => `${apiSiclikComercio}/products/${sku}/related`,
  getVirtualProductsConfig: sku => `${apiSiclikComercio}/products/${sku}/virtuals`,
  getMostSelledProducts: () => `${apiSiclikComercio}/selled/products`,
  getProductsReport: ({ startDate = '', endDate = '', limit = 50, uenId }) =>
    `${apiSiclikComercio}/report/products?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}&limit=${encodeURIComponent(limit)}&uenId=${encodeURIComponent(uenId || '')}`,
  deleteCouponByCode: (orderId, couponCode) => `${apiSiclikComercio}/shopping-cart/coupons/${orderId}/${couponCode}`,
  getServiceAccounts: query => `${apiSiclikComercio}/service-accounts${query}`,
  deleteAccount: (customerId, query) => `${apiSiclikComercio}/service-accounts/${customerId}${query}`,
  addAccount: (customerId, query) => `${apiSiclikComercio}/service-accounts/${customerId}${query}`,
  getAccessByAccount: (customerId, query) => `${apiSiclikComercio}/service-accounts/access/${customerId}${query}`,
  deleteAccess: (customerKey, customerId, env) => `${apiSiclikComercio}/service-accounts/access/${customerKey}/customers/${customerId}${env}`,
  addAccess: query => `${apiSiclikComercio}/service-accounts/access${query}`,
  disableServiceAcount: customerId => `${apiSiclikComercio}/service-accounts/${customerId}`,
  addServiceAccount: customerId => `${apiSiclikComercio}/service-accounts/${customerId}`,
  getIndustries: () => `${apiSiclikComercio}/intelisis/industries`,
  getAgentDirectoryByMaker: makerId => `${apiSiclikComercio}/agent-directory/${makerId}`,
  getDirectoryByAgent: email => `${apiSiclikComercio}/directory/${email}`,
  getAgentsByCustomerId: customerId => `${apiSiclikComercio}/agents-by/${customerId}`,
  getAgentList: () => `${apiSiclikComercio}/agents-list`,
  getCustomerList: () => `${apiSiclikComercio}/customers-list`,
  baseRouteTags: () => `${apiSiclikComercio}/product-catalog/tags`,
  baseProductCategorizations: () => `${apiSiclikComercio}/product-catalog/categorizations`,
  deleteProductCategorization: (categorizationId, brandId) => `${apiSiclikComercio}/product-catalog/categorizations/${categorizationId}/${brandId}`,
  getAttributesPerBrand: (brand, attribute) => `${apiSiclikComercio}/product-catalog/brands/${brand}/attributes/${attribute}`,
  getBrands: () => `${apiSiclikComercio}/product-catalog/brands`,
  syncProductCatalog: () => `${apiSiclikComercio}/product-catalog/sync-up/by-params`,
  syncIcecatProducts: () => `${apiSiclikComercio}/product-catalog/sync-up/icecat`,
  getAvailableRedhatByCustomer: customerId => `${apiSiclikComercio}/customer/isAvailableRedHat/${customerId}`,
  getFormCustomerFinalUser: (finalUserId, customerId) => `${apiSiclikComercio}/customer/form/${finalUserId}/${customerId}`,
  addFormCustomerFinalUser: () => `${apiSiclikComercio}/customer/form/add`,
  updateFormCustomerFinalUser: () => `${apiSiclikComercio}/customer/form/update`,
  getAbandonedCarts: () => `${apiSiclikComercio}/abandoned-carts`,
  getBestBuyers: ({ to, from, top = 100 }) => `${apiSiclikComercio}/best/buyers?startDate=${encodeURIComponent(from)}&endDate=${encodeURIComponent(to)}&limit=${encodeURIComponent(top)}`,
  getItemsContentByBrand: brand => `${apiSiclikComercio}/product-catalog/content?brand=${brand}`,
  syncItemsByContent: () => `${apiSiclikComercio}/product-catalog/sync-up/content`,
  getItemContentBySource: (sku, brand, source) => `${apiSiclikComercio}/product-catalog/content-by-source?sku=${sku}&brand=${brand}&source=${source}`,
  getItemContent: sku => `${apiSiclikComercio}/product-catalog/content?sku=${sku}`,
  updateItemContent: () => `${apiSiclikComercio}/product-catalog/content`,
  addImage: () => `${apiSiclikComercio}/product-catalog/content/images`,
  removeImage: imageId => `${apiSiclikComercio}/product-catalog/content/images/${imageId}`,
  getProductDataSheet: sku => `${apiSiclikComercio}/product-catalog/content/datasheet?sku=${sku}`,
  getProductCatalogBySku: sku => `${apiSiclikComercio}/product-catalog/product/${encodeURIComponent(sku)}`,
  getCouponsByOrderReport: ({ startDate = '', endDate = '', uenId }) => `${apiSiclikComercio}/report/coupons/redeemed?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}&uenId=${encodeURIComponent(uenId || '')}`,
  getLogSessionsReport: ({ startDate = '', endDate = '' }) => `${apiSiclikComercio}/report/sessions?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`,
  getCatalogAttributes: attribute => `${apiSiclikComercio}/product-catalog/attributes?attribute=${attribute}`,
  getItemsByAttribute: (attribute, value) => `${apiSiclikComercio}/product-catalog/products-by-attributes?attribute=${attribute}&value=${value}`,
  fetchProductCatalog: () => `${apiSiclikComercio}/product-catalog/catalog-search`,
  getImagesReport: () => `${apiSiclikComercio}/report/images`,
  getUnmeasuredProductReport: () => `${apiSiclikComercio}/report/unmeasured-products`,
  getBoxConfigurations: () => `${apiSiclikComercio}/box/configurations`,
  updateBoxConfigurations: id => `${apiSiclikComercio}/box/configuration/${id}`,
  getExtendedAreas: postalCode => `${apiSiclikComercio}/extended/areas/${postalCode}`,
  addExtendedArea: () => `${apiSiclikComercio}/extended/area`,
  getSearchApiSiclik: ({ sku, brand, keyword, customerKey, secret }) => `${apiSiclikComercio}/b2b2c/api/siclik/search?sku=${sku}&brand=${brand}&keyword=${keyword}&customerKey=${customerKey}&secret=${secret}`,
  generateTags: () => `${apiSiclikComercio}/generate/ai/tags`,
  generateMissingTags: () => `${apiSiclikComercio}/generate/missing/tags`,
  getAutodeskPrices: () => `${apiSiclikComercio}/autodesk/prices`,
  magicSearch: () => `${apiSiclikComercio}/openai/search`,
};

const chat = {
  getUnreadMessages: () => `${apiChat}/messages/unread`,
  getUsersList: orderId => `${apiChat}/users/${orderId}/members`,
  deleteNotification: notificationId => `${apiChat}/notification/${notificationId}`,
  getAllNotifications: () => `${apiChat}/notifications/all`,
  getUserNotifications: () => `${apiChat}/notifications`,
  getNotificationsUnreadCount: () => `${apiChat}/notifications/count`,
  createNotification: () => `${apiChat}/notifications`,
  readNotifications: () => `${apiChat}/notifications/read`,
};

const convencion = {
  getPointsByCustomer: customerId => `${apiSiclikComercio}/customers/integrator-points/${customerId}`,
  addDataRegisterIntegrator: () => `${apiSiclikComercio}/public-customer-integrator-convention`,
  getIntegratorPoints: () => `${apiSiclikComercio}/customers/integrator-points`,
  updateIntegratorPoints: () => `${apiSiclikComercio}/customer/integrator-points`,
  getPointsByCustomer2: customerId => `${apiSiclikComercio}/customers/integrator-points-test/${customerId}`,
  addDataRegisterIntegrator2: () => `${apiSiclikComercio}/customer-integrator-convention-test`,
  deleteUserRegistered: userId => `${apiSiclikComercio}/convention/registration/${userId}`,
  getUsersRegistered: () => `${apiSiclikComercio}/convention/users-registered`,
  updateUserRegistered: () => `${apiSiclikComercio}/registration/convention/user`,
};

const apiRoutes = {
  ...authentication,
  ...siclik,
  ...comercio,
  ...chat,
  ...notifications,
  ...convencion,
  ...siclikAuth,
};

export default apiRoutes;
