import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { translate } from 'react-i18next';
import {
  Grid, Image, Header, Segment, Button,
} from 'semantic-ui-react';
import { callError } from '@compusoluciones-pdigitales/status-codes';

import toast from '../common/components/toast';

import imagesSiclikCS from './images';
import './style.css';

function LoginCompusoluciones(props) {
  const { t, history } = props;
  const onlyLocalFrontend = window.location.hostname === 'localhost' && process.env.REACT_APP_ENV !== 'development';

  useEffect(() => {
    const queryParam = new URLSearchParams(window.location.search);
    const error = queryParam.get('error');
    const errorCode = Number.isInteger(Number(error)) ? Number(error) : null;
    const errors = {
      [callError.customers.INVALID_RESELLER]: t('login.error.invalidCUstomerPlatform'),
    };

    if (errorCode) {
      toast(errors[errorCode] || t('login.error.unknown'), 'error');
    }
  }, [t]);

  return (
    <Grid padded centered>
      <Helmet
        title="siclik - inicia sesión"
        meta={[
          {
            name: 'description',
            content: 'Es la plataforma que concentra todos nuestros comercios electrónicos para comprar y rentar productos y servicios tecnológicos.',
          },
        ]}
      />
      <Grid.Row centered>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
          <Header className="titles-news-gothic" as="h4">{t('login.selectCompany')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {imagesSiclikCS.map(images => (
          <Grid.Column key={images.name} textAlign="center" verticalAlign="bottom" mobile={15} tablet={5} computer={4} largeScreen={4} className="margin-mobile">
            <Segment className={`${images.name === 'cesa' ? 'card-login-cesa' : 'card-login'} pointer`}>
              <Image
                src={images.image}
                alt={images.name}
                size="medium"
                centered
                verticalAlign="bottom"
                onClick={() => {
                  if (!!images?.redirectLogin && !onlyLocalFrontend) {
                    window.location.href = images.redirectLogin();
                  } else if (images.redirect) {
                    history.push(images.redirect);
                  } else {
                    window.open(images.redirectWeb, '_blank');
                  }
                }}
              />
              <p className="fullBlackText">{images.text}</p>
            </Segment>
          </Grid.Column>
        ))}
      </Grid.Row>
      <Grid.Row centered className="padding-btw-text general-padding-children">
        <Grid.Column only="computer" largeScreen={4} computer={4} />
        <Grid.Column className="no-padding-x margin-mobile" largeScreen={4} computer={4} tablet={7} mobile={16} textAlign="center">
          <Button
            color="grey"
            size="small"
            onClick={() => (window.open('https://www.youtube.com/watch?v=5GMP0u3dQ7k&ab_channel=CanalCompuSoluciones', '_blank'))}
          >
            {t('login.watchVideo')}
          </Button>
        </Grid.Column>
        <Grid.Column className="no-padding-x" largeScreen={4} computer={4} tablet={7} mobile={16} textAlign="center">
          <Button
            color="grey"
            size="small"
            onClick={() => (window.open('https://appsd.compusoluciones.com/csAltaClientesVer2/Cliente/SolicitudAlta.aspx', '_blank'))}
          >
            {t('login.singUp')}
          </Button>
        </Grid.Column>
        <Grid.Column only="computer" largeScreen={4} computer={4} />
        <Grid.Column width={16} className="padding-btw-text" />
      </Grid.Row>
    </Grid>
  );
}

export default translate('common', { wait: true })(LoginCompusoluciones);
