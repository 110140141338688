/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import {
  Dimmer,
  Loader,
  Grid,
  Image,
  Button,
  List,
  Dropdown,
  Segment,
  Pagination,
  Icon,
  Label,
} from 'semantic-ui-react';

import ProductList from './components/product-list';
import BreadCrumb from './components/breadcrumb';
import NotFound from './components/not-found';
import OcVeeam from './components/oc-veeam';
import Filters from './components/filters';

import assets from './images';
import './style.css';
import {
  DEFAULT_SEARCH_TYPE,
  AVAILABILITY_FILTER,
  OUTLET_PARAM,
  BUNDLE_PARAM,
  breadcrumbTypes,
  PAGE_SIZE,
  DEFAULT_PAGE,
  VIEW_GRID,
  VIEW_LIST,
  SORT_OPTIONS,
  DEFAULT_SORT,
  PRICE_FILTER,
} from './const';

import * as session from '../common/sessions';
import fetchService from './services';
// import { analyticsEvent } from '../common/utils';

function addGAEvent() {
  // analyticsEvent('Comercio', 'Búsqueda', `${search.type}-${search.value}`);
}

function goToTop() {
  window.scrollTo(0, 0);
}

function getMainFilter(urlFilter) {
  const validFilters = [OUTLET_PARAM, BUNDLE_PARAM];
  return validFilters.find(filter => filter === urlFilter);
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getCurrentUrlParams(urlParams, resetPage) {
  const queryParams = new URLSearchParams(window.location.search);
  const allParams = {};
  if (resetPage) queryParams.delete('page');
  queryParams.forEach((value, key) => {
    const keyValues = value.split(',');
    const keyName = key === 'type' || key === 'value' ? `search${capitalize(key)}` : key;
    if (keyValues.length > 1) {
      allParams[keyName] = keyValues;
    } else {
      allParams[keyName] = value;
    }
  });

  const sectionFilter = getMainFilter(urlParams?.availability);

  if (sectionFilter) {
    allParams.searchType = DEFAULT_SEARCH_TYPE;
    allParams.searchValue = sectionFilter;
    allParams[AVAILABILITY_FILTER] = sectionFilter;
  }
  return allParams;
}

function sanitizeOptionFilters(options = {}) {
  return Object.entries(options).reduce((params, [key, value]) => {
    if (value !== null && value !== '') {
      params[key] = value;
      return params;
    }
    if (Array.isArray(value)) {
      params[key] = value.map(v => sanitizeOptionFilters({ [key]: v }));
      return params;
    }
    return params;
  }, {});
}

async function fetchProductsWithParams({ params, options }) {
  const filters = {
    page: params?.page || DEFAULT_PAGE,
    searchValue: params.searchValue,
    searchType: params.searchType,
  };
  return fetchService.fetchProducts(filters, sanitizeOptionFilters(options));
}

function setUrlParams(objectParams, resetPage) {
  const currentParams = getCurrentUrlParams({}, resetPage);
  const parseParams = Object.entries(objectParams)
    .reduce((params, [key, value]) => {
      if (Array.isArray(value) && value.length) {
        params[key] = value.join(',');
      } else if (value) {
        params[key] = value;
      }
      return params;
    }, {});
  const { searchType, searchValue, ...otherParams } = currentParams;
  const newParams = {
    type: searchType,
    value: searchValue,
    ...otherParams,
    ...parseParams,
  };
  const queryParams = new URLSearchParams(newParams);
  const url = `${window.location.pathname}?${queryParams}`;
  window.history.pushState(null, null, url);
}

function isSameFilter(urlParamsFilters, stateFilter) {
  return Array.isArray(urlParamsFilters)
    ? urlParamsFilters.some(filter => filter === stateFilter)
    : urlParamsFilters === stateFilter;
}

function setCheckeFilters(filters, checkedFilters) {
  function getFilterValue(filterType, value) {
    if (filterType === PRICE_FILTER) {
      return checkedFilters[value?.name]
        ? Number(checkedFilters[value.name])
        : value.count;
    }
    return null;
  }
  return filters.map(filter => ({
    ...filter,
    values: filter.values.map(value => ({
      ...value,
      checked: filter.type === PRICE_FILTER
        ? getFilterValue(filter.type, value)
        : isSameFilter(checkedFilters[filter.type], value.name),
    })),
  }));
}

function getLsViewMode() {
  const viewMode = window.localStorage.getItem('viewMode');
  return viewMode || VIEW_GRID;
}

function setLsViewMode(viewMode) {
  window.localStorage.setItem('viewMode', viewMode);
}

function isMobile(windowWidth) {
  return windowWidth <= 1200;
}

function isVeeamMainSearch() {
  const VEEAM = 'veeam';
  const queryParams = new URLSearchParams(window.location.search);
  const mainSearch = queryParams.get('value');
  return mainSearch && mainSearch.toLowerCase() === VEEAM;
}

function ProductCatalog(props) {
  const { match, history } = props;
  const { params: urlParams } = match;
  const [banner, setBanner] = useState('');
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState(DEFAULT_SORT);
  const [itemsCount, setItemsCount] = useState(0);
  const [makersPercents, setMakersPercents] = useState({});
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [notFound, setNotFound] = useState(false);
  const [queryOptions, setQueryOptions] = useState(null);
  const [viewMode, setViewMode] = useState(getLsViewMode());
  const [isAvailableRedhat, setIsAvailableRedhat] = useState(false);
  const [lockViewMode, setLockViewMode] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const [isHpmaker, setIsHpMaker] = useState(false);

  const getBannerImage = searchValue => {
    const bannerImage = assets.banners.find(({ name }) => (
      name.includes(String(searchValue).toLowerCase())
    ));
    setBanner(bannerImage);
  };

  const getMakerSearch = searchValue => {
    const makerName = searchValue;
    return makerName === 'HP' ? setIsHpMaker(true) : setIsHpMaker(false);
  };

  const setBreadcrumbSections = (searchType, searchValue) => {
    const breadcrumbSections = [];

    if (searchValue && searchType) {
      const currentBreadcrumb = breadcrumbTypes[searchType];
      breadcrumbSections.push(currentBreadcrumb);
      breadcrumbSections.push({
        content: searchValue,
        link: false,
      });
    }

    setBreadcrumb(breadcrumbSections);
  };

  const handlePageChange = async pageNumber => {
    try {
      setLoading(true);
      setNotFound(false);
      setUrlParams({ page: pageNumber });
      const params = getCurrentUrlParams(urlParams);
      const response = await fetchProductsWithParams({
        params: { ...params, page: pageNumber },
        options: queryOptions,
      });
      if (response.items.length > 0) {
        setPage(pageNumber);
        setItems(response.items);
        goToTop();
      } else {
        setNotFound(true);
      }
    } catch (error) {
      setNotFound(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = async checkedFilters => {
    try {
      const options = { ...queryOptions, ...checkedFilters };
      setLoading(true);
      setNotFound(false);
      setPage(DEFAULT_PAGE);
      setUrlParams(checkedFilters, true);
      setQueryOptions(options);
      const params = getCurrentUrlParams(urlParams);
      const response = await fetchProductsWithParams({ params, options });
      setFilters(setCheckeFilters(response.filters, options));
      setItems(response.items);
      setItemsCount(response.totalItems);
      setNotFound(response.items.length === 0);
      goToTop();
    } catch (error) {
      setNotFound(true);
    } finally {
      setLoading(false);
    }
  };

  const initFilters = (itemFilters, urlFilters) => {
    const sortFilter = urlFilters?.orderby || DEFAULT_SORT;
    const checkedFilters = setCheckeFilters(itemFilters, urlFilters);
    setSort(sortFilter);
    setFilters(checkedFilters);
  };

  const handleViewModeChange = selectedViewMode => {
    setLsViewMode(selectedViewMode);
    setViewMode(selectedViewMode);
  };

  const handleLockViewMode = state => {
    setLockViewMode(state);
    if (state) {
      handleViewModeChange(VIEW_GRID);
    }
  };

  const handleSortChange = (e, { value }) => {
    setSort(value);
    setUrlParams({ orderby: value });
    handleFilterChange({ orderby: value });
  };

  useEffect(() => {
    const { match: matchProp } = props;
    const { searchValue, searchType, ...options } = getCurrentUrlParams(matchProp.params);

    async function fetchProducts() {
      try {
        const params = {
          page: Number(options?.page || DEFAULT_PAGE),
          searchType,
          searchValue,
        };
        setLoading(true);
        const response = await fetchProductsWithParams({ params, options });
        setClearFilters(true);
        setNotFound(response.items.length === 0);
        getBannerImage(params.searchValue);
        getMakerSearch(params.searchValue);
        setQueryOptions(options);
        initFilters(response.filters, options);
        setItems(response.items);
        setBreadcrumbSections(params.searchType, params.searchValue);
        setItemsCount(response.totalItems);
        setPage(params.page);
      } catch (error) {
        setNotFound(true);
      } finally {
        setLoading(false);
        setClearFilters(false);
      }
    }

    setNotFound(false);
    addGAEvent({ type: searchType, value: searchValue });
    fetchProducts();
  }, [props]);

  useEffect(() => {
    async function getAvailableRedhat() {
      try {
        const { customerId } = session.get();
        const response = await fetchService.getAvailableRedhatByCustomer(customerId);
        setIsAvailableRedhat(response);
      } catch (error) {
        setIsAvailableRedhat(false);
      }
    }

    async function getMakersTable() {
      const { makers } = await fetchService.getAllMakers();
      const response = makers.reduce((hashMakers, { minPercent, name }) => ({
        ...hashMakers,
        [name]: minPercent,
      }), {});
      setMakersPercents(response);
    }

    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    getMakersTable();
    getAvailableRedhat();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Grid padded>
      {loading && (
        <Dimmer
          active
          inverted
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            zIndex: 3000,
          }}
        >
          <Loader
            size="large"
            style={{ display: 'inline-block' }}
          />
        </Dimmer>
      )}

      {banner && (
        <Grid.Row>
          <Grid.Column
            computer={16}
            verticalAlign="middle"
            style={{ padding: '0' }}
          >
            <Image
              alt={banner.imageWeb}
              fluid
              src={windowWidth < 1000 ? banner.imageMobile : banner.imageWeb}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      <Grid.Row style={{ padding: '1rem' }}>
        <Grid.Column computer={16}>
          <BreadCrumb sections={breadcrumb} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row style={{ padding: '1rem' }}>
        <Grid.Column widescreen={3} computer={4} tablet={5} only={isMobile(windowWidth) ? 'computer' : ''}>
          <Filters
            filters={filters}
            clearFilters={clearFilters}
            onFiltersChange={handleFilterChange}
            isMainFilter={!!getMainFilter(match.params?.availability)}
          />
        </Grid.Column>

        <Grid.Column widescreen={13} computer={12} tablet={11}>
          <Grid>
            <Grid.Row>
              <Grid.Column computer={8} className="left aligned" only="computer">
                <Segment basic>
                  {items.length > 0 && (
                    `Se muestran ${items.length} - ${page} de ${itemsCount} resultados`
                  )}
                </Segment>
              </Grid.Column>
              <Grid.Column computer={isMobile(windowWidth) ? 16 : 8}>
                {windowWidth < 768 && (
                  <>
                    <Button fluid onClick={() => setShowMobileFilters(!showMobileFilters)}>
                      <Icon name="filter" />
                      Filtrar resultados
                    </Button>
                    {showMobileFilters && (
                      <div style={{ backgroundColor: '#F2F2F2', padding: '1rem' }}>
                        <Filters
                          filters={filters}
                          onFiltersChange={handleFilterChange}
                        />
                        <Button
                          fluid
                          color="red"
                          onClick={() => setShowMobileFilters(false)}
                        >
                          Cerrar filtros
                        </Button>
                      </div>
                    )}
                  </>
                )}
                <div style={{ float: 'right' }}>
                  <List horizontal>
                    <List.Item style={lockViewMode ? { display: 'none' } : {}}>
                      <b>Seleccionar vista: </b>
                      <Button.Group>
                        <Button
                          toggle
                          icon="list layout"
                          active={viewMode === VIEW_LIST}
                          className={viewMode === VIEW_LIST ? 'active-button' : ''}
                          onClick={() => handleViewModeChange(VIEW_LIST)}
                        />
                        <Button
                          toggle
                          icon="grid layout"
                          active={viewMode === VIEW_GRID}
                          className={viewMode === VIEW_GRID ? 'active-button' : ''}
                          onClick={() => handleViewModeChange(VIEW_GRID)}
                        />
                      </Button.Group>
                    </List.Item>
                    <List.Item>
                      <b>
                        Ordenar por
                        {': '}
                        <Dropdown
                          inline
                          value={sort}
                          options={SORT_OPTIONS}
                          style={{ color: '#0054A3' }}
                          direction="left"
                          onChange={handleSortChange}
                        />
                      </b>
                    </List.Item>
                  </List>
                </div>
              </Grid.Column>
            </Grid.Row>
            {isHpmaker && (
            <Grid.Row>
              <Grid.Column widescreen={4} computer={8} tablet={12} mobile={16}>
                <Label as="a" color="yellow">
                  Algunos productos necesitan HP Connect desde 1 pieza
                </Label>
              </Grid.Column>
            </Grid.Row>
            )}
            {isVeeamMainSearch() && (
              <Grid.Row>
                <Grid.Column>
                  <OcVeeam history={history} />
                </Grid.Column>
              </Grid.Row>
            )}
            {notFound ? (
              <Grid.Row>
                <Grid.Column computer={16}>
                  <NotFound />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row className="full-width">
                <Grid.Column>
                  <ProductList
                    items={items}
                    viewMode={viewMode}
                    windowWidth={windowWidth}
                    lockViewMode={handleLockViewMode}
                    makersPercents={makersPercents}
                    isAvailableRedhat={isAvailableRedhat}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={16} className="center aligned">
          {itemsCount > PAGE_SIZE && (
            <Pagination
              activePage={page}
              ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
              firstItem={null}
              lastItem={null}
              onPageChange={(e, { activePage }) => handlePageChange(activePage)}
              prevItem={page === 1 ? null : {
                icon: true,
                content: (
                  !isMobile(windowWidth) ? (
                    <div style={{ color: '#0054A3' }}>
                      <Icon name="angle left" style={{ marginRight: '0.5rem' }} />
                      <span>Anterior</span>
                    </div>
                  ) : <Icon name="angle left" style={{ marginRight: '0.5rem' }} />
                ),
              }}
              nextItem={page === Math.ceil(itemsCount / PAGE_SIZE) ? null : {
                icon: true,
                content: (
                  !isMobile(windowWidth) ? (
                    <div style={{ color: '#0054A3' }}>
                      <span>Siguiente</span>
                      <Icon name="angle right" style={{ marginLeft: '0.5rem' }} />
                    </div>
                  ) : <Icon name="angle right" style={{ marginLeft: '0.5rem' }} />
                ),
              }}
              totalPages={Math.ceil(itemsCount / PAGE_SIZE)}
              size={isMobile(windowWidth) ? 'mini' : 'large'}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default ProductCatalog;
