const bucket = `${process.env.REACT_APP_URL_AWS}`;
const assetUrl = `${bucket}/imagenes/assets/comercio/`;
const assetUrlLogos = `${bucket}/imagenes/assets/brands/logos/`;
const assetAgents = `${bucket}/agentes/`;

const banners = [
  {
    name: 'accesorios',
    imageWeb: `${assetUrl}banner-acc-web.png`,
    imageMobile: `${assetUrl}banner-acc-mobile.png`,
  },
  {
    name: 'computo',
    imageWeb: `${assetUrl}banner-comp-web.png`,
    imageMobile: `${assetUrl}banner-comp-mobile.png`,
  },
  {
    name: 'gaming',
    imageWeb: `${assetUrl}banner-gam-web.png`,
    imageMobile: `${assetUrl}banner-gam-web-mobile.png`,
  },
  {
    name: 'impresora',
    imageWeb: `${assetUrl}banner-imp-web.png`,
    imageMobile: `${assetUrl}banner-imp-web.png`,
  },
  {
    name: 'redes',
    imageWeb: `${assetUrl}banner-redes-web.png`,
    imageMobile: `${assetUrl}banner-redes-web.png`,
  },
  {
    name: 'servidores',
    imageWeb: `${assetUrl}banner-serv-web.png`,
    imageMobile: `${assetUrl}banner-serv-web.png`,
  },
  {
    name: 'apple',
    imageWeb: `${assetUrl}apple-web.jpg`,
    imageMobile: `${assetUrl}apple-mobile.jpg`,
  },
  {
    name: 'aruba',
    imageWeb: `${assetUrl}aruba-web.png`,
    imageMobile: `${assetUrl}aruba-mobile.png`,
  },
  {
    name: 'asus',
    imageWeb: `${assetUrl}asus-web.jpg`,
    imageMobile: `${assetUrl}asus-mobile.jpg`,
  },
  {
    name: 'blancco',
    imageWeb: `${assetUrl}blancco-web.png`,
    imageMobile: `${assetUrl}blancco-mobile.png`,
  },
  {
    name: 'check point',
    imageWeb: `${assetUrl}check-point-web.png`,
    imageMobile: `${assetUrl}check-point-mobile.png`,
  },
  {
    name: 'hp',
    imageWeb: `${assetUrl}hp-web.png`,
    imageMobile: `${assetUrl}hp-mobile.png`,
  },
  {
    name: 'hpe',
    imageWeb: `${assetUrl}hpe-web.png`,
    imageMobile: `${assetUrl}hpe-mobile.png`,
  },
  {
    name: 'lenovo',
    imageWeb: `${assetUrl}lenovo-web.png`,
    imageMobile: `${assetUrl}lenovo-mobile.png`,
  },
  {
    name: 'logitech',
    imageWeb: `${assetUrl}logitech-web.png`,
    imageMobile: `${assetUrl}logitech-mobile.png`,
  },
  {
    name: 'perfectchoice',
    imageWeb: `${assetUrl}perfectchoice-web.png`,
    imageMobile: `${assetUrl}perfectchoice-mobile.png`,
  },
  {
    name: 'safetica',
    imageWeb: `${assetUrl}safetica-web.png`,
    imageMobile: `${assetUrl}safetica-mobile.png`,
  },
  {
    name: 'redhat',
    imageWeb: `${assetUrl}red-hat-web.png`,
    imageMobile: `${assetUrl}red-hat-mobile.png`,
  },
  {
    name: 'screenbeam',
    imageWeb: `${assetUrl}screenbeam-mobile.png`,
    imageMobile: `${assetUrl}screenbeam-web.png`,
  },
  {
    name: 'techzone',
    imageWeb: `${assetUrl}techzone-web.png`,
    imageMobile: `${assetUrl}techzone-mobile.png`,
  },
  {
    name: 'trellix',
    imageWeb: `${assetUrl}trellix-web.png`,
    imageMobile: `${assetUrl}trellix-mobile.png`,
  },
  {
    name: 'tripp lite',
    imageWeb: `${assetUrl}tripplite-web.png`,
    imageMobile: `${assetUrl}tripplite-mobile.png`,
  },
  {
    name: 'veeam',
    imageWeb: `${assetUrl}veeam-web.jpg`,
    imageMobile: `${assetUrl}veeam-mobile.jpg`,
  },
  {
    name: 'vmware',
    imageWeb: `${assetUrl}vmware-web.png`,
    imageMobile: `${assetUrl}vmware-mobile.png`,
  },

];

const logos = [
  {
    logoMaker: `${assetUrlLogos}apple.png`,
    maker: 'Apple',
  },
  {
    logoMaker: `${assetUrlLogos}aruba.png`,
    maker: 'Aruba',
  },
  {
    logoMaker: `${assetUrlLogos}checkpoint.png`,
    maker: 'Check Point',
  },
  {
    logoMaker: `${assetUrlLogos}hpe.png`,
    maker: 'HP',
  },
  {
    logoMaker: `${assetUrlLogos}hp.png`,
    maker: 'HP Inc',
  },
  {
    logoMaker: `${assetUrlLogos}lenovo.png`,
    maker: 'Lenovo',
  },
  {
    logoMaker: `${assetUrlLogos}isotipologitech.png`,
    maker: 'Logitech',
  },
  {
    logoMaker: `${assetUrlLogos}microsoft.png`,
    maker: 'Microsoft',
  },
  {
    logoMaker: `${assetUrlLogos}perfectchoice.png`,
    maker: 'Perfect Choice',
  },
  {
    logoMaker: `${assetUrlLogos}redhat.png`,
    maker: 'CS-REDHAT-SP',
  },
  {
    logoMaker: `${assetUrlLogos}screenbeam.png`,
    maker: 'Actiontec',
  },
  {
    logoMaker: `${assetUrlLogos}techzone.png`,
    maker: 'Tech Zone',
  },
  {
    logoMaker: `${assetUrlLogos}trellix-logo.png`,
    maker: 'trellix',
  },
  {
    logoMaker: `${assetUrlLogos}veeam.png`,
    maker: 'VEEAM',
  },
  {
    logoMaker: `${assetUrlLogos}vmware.png`,
    maker: 'VMWARE',
  },

];

const accesories = [
  {
    text: 'Bocinas',
    redirect: 'bocinas',
  },
  {
    text: 'Cámaras Digitales',
    redirect: 'camara',
  },
  {
    text: 'Cómputo',
    redirect: 'accesorios',
  },
  {
    text: 'Monitores',
    redirect: 'monitores',
  },
];

const computer = [
  {
    text: 'Escritorio',
    redirect: 'computo%20Computadora',
  },
  {
    text: 'Laptop',
    redirect: 'laptop',
  },
  {
    text: 'Tableta',
    redirect: 'tableta',
  },
];

const printers = [
  {
    text: 'Consumibles',
    redirect: 'consumibles',
  },
  {
    text: 'Escaner',
    redirect: 'scanner',
  },
  {
    text: 'Láser',
    redirect: 'laser',
  },
  {
    text: 'Miniprinters',
    redirect: 'receipt%20printer',
  },
  {
    text: 'Plotter',
    redirect: 'ploter%20HP-BIG%20IMPRESION',
  },
];

const networks = [
  {
    text: 'Conmutadores',
    redirect: 'Conmutador',
  },
  {
    text: 'Router',
    redirect: 'router',
  },
  {
    text: 'Switch',
    redirect: 'switch',
  },
];

const servers = [
  {
    text: 'Accesorios',
    redirect: 'servidores',
  },
  {
    text: 'Almacenamiento',
    redirect: 'almacenamiento',
  },
  {
    text: 'Reguladores de energía',
    redirect: 'energia',
  },
];

const software = [
  {
    text: 'Colaboración',
    redirect: 'colaboracion',
  },
  {
    text: 'Nube',
    redirect: 'nube',
  },
  {
    text: 'Office 365',
    redirect: 'office',
  },
  {
    text: 'Seguridad',
    redirect: 'seguridad',
  },
  {
    text: 'Sistemas operativos',
    redirect: 'sistemas operativos',
  },
  {
    text: 'Virtualización',
    redirect: 'virtualizacion',
  },
];

const agentsDummy = [
  {
    maker: 'Safetica',
    agent: 'Alejandra Bolaños',
    email: 'abolan@compusoluciones.com',
    cellPhone: '5561671993',
    image: `${assetAgents}ABOLAN.jpg`,
  },
  {
    maker: 'Blancco',
    agent: 'Alejandra Bolaños',
    email: 'abolan@compusoluciones.com',
    cellPhone: '5561671993',
    image: `${assetAgents}ABOLAN.jpg`,
  },
  {
    maker: 'Apple',
    agent: 'Jennifer Zepeda',
    email: 'jzepeda@compusoluciones.com',
    cellPhone: '3336768395',
    image: `${assetAgents}JZEPEDA.jpg`,
  },
  {
    maker: 'Logitech',
    agent: 'Angélica De La Mora',
    email: 'adelamor@compusoluciones.com',
    cellPhone: '3322572058',
    image: `${assetAgents}ADELAMOR.jpg`,
  },
  {
    maker: 'Tripplite',
    agent: 'Angélica De La Mora',
    email: 'adelamor@compusoluciones.com',
    cellPhone: '3322572058',
    image: `${assetAgents}ADELAMOR.jpg`,
  },
  {
    maker: 'TechZone',
    agent: 'Angélica De La Mora',
    email: 'adelamor@compusoluciones.com',
    cellPhone: '3322572058',
    image: `${assetAgents}ADELAMOR.jpg`,
  },
  {
    maker: 'HP Inc',
    agent: 'Angélica De La Mora',
    email: 'adelamor@compusoluciones.com',
    cellPhone: '3322572058',
    image: `${assetAgents}ADELAMOR.jpg`,
  },
  {
    maker: 'Asus',
    agent: 'Angélica De La Mora',
    email: 'adelamor@compusoluciones.com',
    cellPhone: '3322572058',
    image: `${assetAgents}ADELAMOR.jpg`,
  },
  {
    maker: 'VMWARE',
    agent: 'Edgar Cuevas',
    email: 'ecuevas@compusoluciones.com',
    cellPhone: '5568179877',
    image: `${assetAgents}ECUEVAS.jpg`,
  },
  {
    maker: 'Lenovo',
    agent: 'Eduardo Molina',
    email: 'emolina@compusoluciones.com',
    cellPhone: '3350007804',
    image: `${assetAgents}EMOLINA.jpg`,
  },
  {
    maker: 'Trellix',
    agent: 'Esteban Solache',
    email: 'esolache@compusoluciones.com',
    cellPhone: '5568036884',
    image: `${assetAgents}ESOLACHE.jpg`,
  },
  {
    maker: 'Red Hat',
    agent: 'Fernanda Robles',
    email: 'frobles@compusoluciones.com',
    cellPhone: '3338081235',
    image: `${assetAgents}FROBLES.jpg`,
  },
  {
    maker: 'Rocketbot',
    agent: 'Grecia Inda',
    email: 'ginda@compusoluciones.com',
    cellPhone: '5591440095',
    image: `${assetAgents}GINDA.jpg`,
  },
  {
    maker: 'Matterport',
    agent: 'Marcela Aldaco',
    email: 'maldaco@compusoluciones.com',
    cellPhone: '3350007777',
    image: `${assetAgents}MALDACO.jpg`,
  },
  {
    maker: 'VEEAM',
    agent: 'Zuleima Santiago',
    email: 'zsantiag@compusoluciones.com',
    cellPhone: '5580006949',
    image: `${assetAgents}ZSANTIAG.jpg`,
  },
  {
    maker: 'HPE',
    agent: 'Zuleima Santiago',
    email: 'zsantiag@compusoluciones.com',
    cellPhone: '5580006949',
    image: `${assetAgents}ZSANTIAG.jpg`,
  },
];

const responseDummy = {
  items: [
    {
      images: [],
      inventory: [
        {
          warehouse: 'GI01',
          quantity: 10,
        },
        {
          warehouse: 'MI01',
          quantity: 12,
        },
        {
          warehouse: 'YI01',
          quantity: 12,
        },
      ],
      outlet: [],
      variants: [],
      sku: 'MPHK3E/A',
      sku2: '',
      title: 'Apple MacBook Pro Computadora portátil 36.1 cm (14.2") Apple M M2 Max 32 GB 1 TB SSD Wi-Fi 6E (802.11ax) macOS Ventura Plata',
      category: 'Laptops',
      family: 'MacBook Pro',
      type: 'Serie',
      unit: 'Normal',
      branch: 'APPLE-MAC',
      line: 'Mac',
      pl: '',
      hasShipping: true,
      isVirtual: false,
      maker: {
        id: 'd9eba943-a8d0-4230-8312-5f66e5d2407a',
        brand: 'Apple',
        vendor: 'Apple',
        logo: 'https://s3.amazonaws.com/aws.cs-siclick-staging/imagenes/assets/brands/logos/apple-reseller.png',
        uen: 34,
      },
      prices: {
        currency: 'MXN',
        listPrice: 60747.5745,
        promotionPrice: 60108.1263,
        expPromotionPrice: '2024-10-31T23:59:00.000Z',
        specialPrice: 60108.13,
        customerPrice: 0,
        outletPrice: 0,
        shippingQuotePrice: 0,
      },
      status: 'active',
      realCategory: 'Portatil',
      realFamily: 'MacBook Pro',
      stock: 0,
      outletStock: 0,
      minQuantity: 0,
      maxQuantity: 0,
    },
    {
      images: [],
      inventory: [
        {
          warehouse: 'GI01',
          quantity: 5,
        },
        {
          warehouse: 'MI01',
          quantity: 14,
        },
        {
          warehouse: 'YI01',
          quantity: 9,
        },
      ],
      outlet: [],
      variants: [],
      sku: 'MPHF3E/A',
      sku2: '',
      title: 'Apple MacBook Pro Computadora portátil 36.1 cm (14.2") Apple M M2 Pro 16 GB 1 TB SSD Wi-Fi 6E (802.11ax) macOS Ventura Gris',
      category: 'Laptops',
      family: 'MacBook Pro',
      type: 'Serie',
      unit: 'Normal',
      branch: 'APPLE-MAC',
      line: 'Mac',
      pl: '',
      hasShipping: true,
      isVirtual: false,
      maker: {
        id: 'd9eba943-a8d0-4230-8312-5f66e5d2407a',
        brand: 'Apple',
        vendor: 'Apple',
        logo: 'https://s3.amazonaws.com/aws.cs-siclick-staging/imagenes/assets/brands/logos/apple-reseller.png',
        uen: 34,
      },
      prices: {
        currency: 'MXN',
        listPrice: 48597.4468,
        promotionPrice: 48085.8947,
        expPromotionPrice: '2024-10-31T23:59:00.000Z',
        specialPrice: 48085.89,
        customerPrice: 0,
        outletPrice: 0,
        shippingQuotePrice: 0,
      },
      status: 'active',
      realCategory: 'Portatil',
      realFamily: 'MacBook Pro',
      stock: 0,
      outletStock: 0,
      minQuantity: 0,
      maxQuantity: 0,
    },
  ],
  // eslint-disable-next-line max-len
  resultText: 'He encontrado dos computadoras que podrían interesarte:\n\n- SKU: MPHK3E/A es una Apple MacBook Pro Computadora portátil 36.1 cm (14.2") Apple M M2 Max 32 GB 1 TB SSD Wi-Fi 6E (802.11ax) macOS Ventura Plata.\n\n- SKU: MPHF3E/A es una Apple MacBook Pro Computadora portátil 36.1 cm (14.2") Apple M M2 Pro 16 GB 1 TB SSD Wi-Fi 6E (802.11ax) macOS Ventura Gris.\n\nPor favor, revisa estos productos y déjame saber si necesitas más información.',
};

const productsDummy = [
  {
    brand: {
      id: '503b8587-4a9f-4b50-a2f6-553a7d9c33bb',
      logo: 'https://s3.amazonaws.com/aws.cs-siclick-staging/imagenes/assets/brands/logos/hp.png',
      name: 'HP',
    },
    category: 'On Premise',
    family: 'Computadora',
    hasVariants: false,
    image: 'https://s3.amazonaws.com/aws.cs-siclick-staging/imagenes/assets/brands/logos/hp.png',
    inventory: [{ warehouse: 'MI01', quantity: 70 }],
    isVirtual: false,
    maxQuantity: 0,
    minQuantity: 0,
    outlet: [],
    prices: {
      currency: 'MXN',
      expPromotionPrice: '2024-12-31T12:35:10.043Z',
      listPrice: 6390,
      outletPrice: 0,
      promotionPrice: 6203.88,
    },
    sku: '1FH50AA',
    sku2: ' ',
    title: 'Desktop LN V510z All-in-One 23", Intel Core i5 7400T, 4GB, 1TB W10P',
  },
  // {
  //   brand: {
  //     id: '503b8587-4a9f-4b50-a2f6-553a7d9c33bb',
  //     logo: 'https://s3.amazonaws.com/aws.cs-siclick-staging/imagenes/assets/brands/logos/hp.png',
  //     name: 'HP',
  //   },
  //   category: 'On Premise',
  //   family: 'Computadora',
  //   hasVariants: false,
  //   image: 'https://s3.amazonaws.com/aws.cs-siclick-staging/imagenes/assets/brands/logos/hp.png',
  //   inventory: [{ warehouse: 'MI01', quantity: 70 }],
  //   isVirtual: false,
  //   maxQuantity: 0,
  //   minQuantity: 0,
  //   outlet: [],
  //   price: {
  //     currency: 'MXN',
  //     expPromotionPrice: '2024-12-31T12:35:10.043Z',
  //     listPrice: 6390,
  //     outletPrice: 0,
  //     promotionPrice: 6203.88,
  //   },
  //   sku: '1FH50AA',
  //   sku2: ' ',
  //   title: 'Desktop LN V510z All-in-One 23", Intel Core i5 7400T, 4GB, 1TB W10P',
  // },
  // {
  //   brand: {
  //     id: '503b8587-4a9f-4b50-a2f6-553a7d9c33bb',
  //     logo: 'https://s3.amazonaws.com/aws.cs-siclick-staging/imagenes/assets/brands/logos/hp.png',
  //     name: 'HP',
  //   },
  //   category: 'On Premise',
  //   family: 'Computadora',
  //   hasVariants: false,
  //   image: 'https://s3.amazonaws.com/aws.cs-siclick-staging/imagenes/assets/brands/logos/hp.png',
  //   inventory: [{ warehouse: 'MI01', quantity: 70 }],
  //   isVirtual: false,
  //   maxQuantity: 0,
  //   minQuantity: 0,
  //   outlet: [],
  //   prices: {
  //     currency: 'MXN',
  //     expPromotionPrice: '2024-12-31T12:35:10.043Z',
  //     listPrice: 6390,
  //     outletPrice: 0,
  //     promotionPrice: 6203.88,
  //   },
  //   sku: '1FH50AA',
  //   sku2: ' ',
  //   title: 'Desktop LN V510z All-in-One 23", Intel Core i5 7400T, 4GB, 1TB W10P',
  // },
  // {
  //   brand: {
  //     id: '503b8587-4a9f-4b50-a2f6-553a7d9c33bb',
  //     logo: 'https://s3.amazonaws.com/aws.cs-siclick-staging/imagenes/assets/brands/logos/hp.png',
  //     name: 'HP',
  //   },
  //   category: 'On Premise',
  //   family: 'Computadora',
  //   hasVariants: false,
  //   image: 'https://s3.amazonaws.com/aws.cs-siclick-staging/imagenes/assets/brands/logos/hp.png',
  //   inventory: [{ warehouse: 'MI01', quantity: 70 }],
  //   isVirtual: false,
  //   maxQuantity: 0,
  //   minQuantity: 0,
  //   outlet: [],
  //   prices: {
  //     currency: 'MXN',
  //     expPromotionPrice: '2024-12-31T12:35:10.043Z',
  //     listPrice: 6390,
  //     outletPrice: 0,
  //     promotionPrice: 6203.88,
  //   },
  //   sku: '1FH50AA',
  //   sku2: ' ',
  //   title: 'Desktop LN V510z All-in-One 23", Intel Core i5 7400T, 4GB, 1TB W10P',
  // },
  // {
  //   brand: {
  //     id: '503b8587-4a9f-4b50-a2f6-553a7d9c33bb',
  //     logo: 'https://s3.amazonaws.com/aws.cs-siclick-staging/imagenes/assets/brands/logos/hp.png',
  //     name: 'HP',
  //   },
  //   category: 'On Premise',
  //   family: 'Computadora',
  //   hasVariants: false,
  //   image: 'https://s3.amazonaws.com/aws.cs-siclick-staging/imagenes/assets/brands/logos/hp.png',
  //   inventory: [{ warehouse: 'MI01', quantity: 70 }],
  //   isVirtual: false,
  //   maxQuantity: 0,
  //   minQuantity: 0,
  //   outlet: [],
  //   prices: {
  //     currency: 'MXN',
  //     expPromotionPrice: '2024-12-31T12:35:10.043Z',
  //     listPrice: 6390,
  //     outletPrice: 0,
  //     promotionPrice: 6203.88,
  //   },
  //   sku: '1FH50AA',
  //   sku2: ' ',
  //   title: 'Desktop LN V510z All-in-One 23", Intel Core i5 7400T, 4GB, 1TB W10P',
  // },
];

const assets = {
  banners,
  logos,
  accesories,
  computer,
  printers,
  networks,
  servers,
  software,
  productsDummy,
  agentsDummy,
  responseDummy,
};

export default assets;
