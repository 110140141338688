/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid, Button, Icon, Header, Input, Dropdown, Checkbox, Placeholder, Image, Form, Label,
} from 'semantic-ui-react';
import { callError } from '@compusoluciones-pdigitales/status-codes';
import ProductDetails from './product-details';
import TableWholesaleprice from './wholeSale-Price';
import TableWholesalepriceAsus from './wholeSale-PriceAsus';
import ShareProductDetails from './shareProductDetails';
import CustomDropdown from '../CustomDropdown';
import Toast from '../../../common/components/toast';
import { controlStringLength } from '../../../common/utils';
import products from '../../services';
import routes from '../../../route-names';
import '../../styles.css';

function getMainSku(item) {
  const SEPARATOR = '->';
  const [mainSku, isVariant] = item.split(SEPARATOR);
  return isVariant ? mainSku : item;
}

function setWishListFormat(wishList) {
  return wishList.map(list => ({
    key: list.id,
    value: list.id,
    text: list.name,
  }));
}

const placeHolderItems = [{}, {}, {}, {}, {}];
const EMPTY_LIST_ID = 'deafult';
const baseUrl = process.env.REACT_APP_ENDPOINT;
class DescriptionComponent extends Component {
  constructor(props) {
    super(props);
    const {
      productId,
      sku,
      requiredId,
      product,
    } = this.props;
    this.state = {
      wishList: [],
      quantity: product.minQuantity || 1,
      wishListLoader: false,
      productId,
      sku,
      adding: false,
      steppedPriceSelection: false,
      steppedPriceHpMapId: '',
      emtyDataErrors: false,
      requiredId,
      itemVariant: null,
    };
  }

  componentDidUpdate(prevProps) {
    this.setProductQuantity(prevProps);
  }

  setProductQuantity = prevProps => {
    const { product } = this.props;
    if (prevProps.product.minQuantity !== product.minQuantity) {
      this.setState({
        quantity: product.minQuantity || 1,
      });
    }
  }

  emptyList = () => [{
    key: EMPTY_LIST_ID,
    text: 'No se encontraron listas.',
  }]

  handleWishList = async () => {
    const { t } = this.props;
    try {
      this.setState({ wishListLoader: true });
      const { wishList } = await products.getWishList();
      const lists = setWishListFormat(wishList);
      this.setState({ wishListLoader: false, wishList: lists });
    } catch (error) {
      this.setState({ wishListLoader: false, wishList: this.emptyList() });
      if (error && error.code !== callError.products.NO_WISH_LIST_FOUND) {
        Toast(t(`error-codes.${error.code}`), 'error');
      }
      if (!error) Toast(t('error-codes.default'), 'error');
    }
  }

  handleSelectedList = async selectedListId => {
    const { t } = this.props;
    const { sku } = this.state;
    try {
      if (selectedListId === EMPTY_LIST_ID) return;
      await products.addItemToWishList(selectedListId, [{
        sku,
        quantity: 1,
      }]);
      Toast(t('wishList.itemDetails.itemAdded'), 'success');
    } catch (error) {
      if (error.code === callError.products.ITEM_ALREADY_EXISTS_IN_WISH_LIST) {
        Toast(t('wishList.itemDetails.alreadyExists'), 'warning');
      } else Toast(t('error-codes.default'), 'error');
    }
  }

  handleAddList = async wishListname => {
    const { sku, wishList: stateWishlist } = this.state;
    const { t } = this.props;
    try {
      this.setState({ wishListLoader: true });
      const { id: wishListId } = await products.createWishList(wishListname, [{
        sku,
        quantity: 1,
      }]);
      const newList = setWishListFormat([{ id: wishListId, name: wishListname }]);
      const currentLists = stateWishlist.filter(list => list.key !== EMPTY_LIST_ID);
      const wishList = [...currentLists, ...newList];
      this.setState({ wishList, wishListLoader: false });
      Toast(t('wishList.itemDetails.itemAddedInNewList'), 'success');
    } catch (error) {
      this.setState({ wishListLoader: false });
      Toast(t('error-codes.default'), 'error');
    }
  }

  addItems = async () => {
    const {
      productId, steppedPriceHpMapId, quantity, requiredId,
    } = this.state;
    const {
      t, productName, sku: propSku, price, product, currencyPrice: currency, maker, updateCartCount,
    } = this.props;
    const { branch } = product;
    const isArubaSupport = branch === 'ARUBA-SERVICES' || branch === 'ARUBA-SOFTWARE';
    const validatedQuantity = this.validateQuantity(Number(quantity), false);
    if (validatedQuantity < 1) {
      Toast(t('productDetails.invalidQuantity'), 'warning');
      return;
    }
    const body = {
      items: [
        {
          sku: propSku || decodeURIComponent(productId),
          quantity: validatedQuantity,
          hpMapId: steppedPriceHpMapId,
        },
      ],
    };
    if ((maker === 'Aruba') && requiredId && requiredId.length > 0) {
      body.items.push({
        sku: decodeURIComponent(requiredId),
        quantity: validatedQuantity,
        hpMapId: steppedPriceHpMapId,
      });
    }
    this.setState({ adding: true });
    if ((isArubaSupport) && (!requiredId || requiredId.length === 0)) {
      Toast('Es necesario seleccionar el soporte a comprar con la licencia', 'warning');
      this.setState({ adding: false });
    } else {
      await products.addItems(body)
        .then(response => {
          const id = response.orderId;
          if (id.length > 0) {
            Toast(t('productDetails.productInCart'), 'success');
            // analyticsEvent('ProductCatalog', 'Agregar al carrito', propSku);
            this.setState({ adding: false });
          }
        })
        .then(() => updateCartCount())
        .catch(error => {
          let messageError = '';
          if (!error) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        });
    }
  }

  validateQuantity = (value, isOnChange = true) => {
    if (value > 0) return value;
    if (value === 0 && isOnChange) return value;
    if (value === 0 || value < 0) return 1;
    return value;
  }

  handleQuantity = (e, { value }) => {
    let validValue = '';
    if (value !== '') {
      const roundedQuantity = Math.round(value);
      validValue = this.validateQuantity(roundedQuantity);
    }
    this.setState({ quantity: validValue });
  }

  handleIncrement = () => {
    const { quantity } = this.state;
    const roundedQuantity = Math.round(quantity);
    this.validateQuantity(roundedQuantity);
    this.setState({ quantity: roundedQuantity + 1 });
  };

  handleDecrement = () => {
    const { quantity } = this.state;
    if (quantity > 1) {
      this.setState({ quantity: quantity - 1 });
      return this.validateQuantity(quantity - 1);
    }
    this.setState({ quantity: 1 });
    return this.validateQuantity(1);
  }

  handleQuantityBlur = () => {
    const { quantity: quantityState } = this.state;
    const roundedQuantity = Math.round(quantityState);
    const quantity = this.validateQuantity(roundedQuantity, false);
    this.setState({ quantity });
  }

  handleGetHpMapId = (mapHpId, errors) => {
    this.setState({ steppedPriceHpMapId: mapHpId, steppedPriceHpErros: errors });
  }

  handleGetsteppedPriceSelection = mapIdSelectedOption => {
    this.setState({ steppedPriceSelection: mapIdSelectedOption });
  }

  handleHpIdValidations = () => {
    const { t } = this.props;
    const { steppedPriceSelection, steppedPriceHpMapId, steppedPriceHpErros } = this.state;
    let { emtyDataErrors } = this.state;
    if (steppedPriceSelection) {
      if ((steppedPriceHpMapId.trim()).length === 0) {
        emtyDataErrors = true;
      }
      if (emtyDataErrors) {
        Toast(t('productDetails.errors.incompleteData'), 'warning');
      }
      if (steppedPriceHpErros) {
        Toast(t('productDetails.errors.dataErrors'), 'error');
      }
      if (!emtyDataErrors && !steppedPriceHpErros) {
        this.addItems();
      }
    } else this.addItems();
  }

  handleChangeRequiredId = (e, { value }) => {
    this.setState({ requiredId: value });
  }

  handleChangeItem = (sku, allowVariants = false) => {
    const { onChangeItem } = this.props;
    this.setState({ itemVariant: sku });
    onChangeItem({ sku, allowVariants });
  }

  render() {
    const {
      productId, adding, wishList, wishListLoader, quantity, requiredId, products: productsState, itemVariant,
    } = this.state;
    const {
      t, price, promotionPrice, productName, sku, sku2, promotionDeadline, arrayObjectStock, isVirtual, configurations, promotion,
      arrayObjectStockOc, deliverDate, currencyPrice, steppedPriceHp, makersPercents, maker, arrayRequiredProducts, handleChangeConfig,
      hasRequiredProduct, promotionalMessage, loadingVmware, product, arraySubstituteProducts, hasNotStock, updateCartCount,
      isAvailableRedhat, variants, descriptionBySku,
    } = this.props;
    const MINPERCENT = makersPercents[maker] || 0.01;
    const userText = quantity === 1 ? 'Usuario' : 'Usuarios';
    const minPrice = price * MINPERCENT;
    const isValidPrice = (price.toFixed(2) > 0 && (!promotionPrice || (promotionPrice > minPrice)));
    const isArubaSupport = product.branch === 'ARUBA-SERVICES' || product.branch === 'ARUBA-SOFTWARE';
    let { category } = '';
    if (product.category === 'Licenciamiento') category = 'soporte';
    if (product.category === 'Soporte') category = 'licencia';
    if (isArubaSupport) category = 'dispositivo';
    if (maker === 'Aruba' && !isArubaSupport) category = 'servicio';
    if (sku === 'VS7-ESSL-KIT-C') category = 'suscripción';
    const urlProductDetails = `${baseUrl}/productos?type=sku&value=${sku}`;
    return (
      <Grid padded>
        <Grid.Row centered>
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={10} stretched>
            {productName ? (
              <Header as="h3" color="blue">{controlStringLength(productName, 45)}</Header>
            ) : (
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            )}
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={3} className="no-padding">
            <Button
              color="blue"
              onClick={() => window.open(`${routes.compareProducts.route}?first=${encodeURIComponent(sku)}`, '_blank')}
              icon
              basic
              fluid
              compact
              labelPosition="right"
              className="padding-left-6"
            >
              {t('Comparar')}
              <Icon name="external alternate" />
            </Button>
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={3} textAlign="center">
            <ShareProductDetails url={urlProductDetails} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered className="no-padding-top">
          <Grid.Column largeScreen={steppedPriceHp && steppedPriceHp.length > 0 ? 8 : 16} computer={steppedPriceHp && steppedPriceHp.length > 0 ? 8 : 16} tablet={steppedPriceHp && steppedPriceHp.length > 0 ? 8 : 16} mobile={16}>
            {productName ? (
              <ProductDetails
                products={productsState}
                productId={productId}
                sku={getMainSku(sku)}
                sku2={sku2}
                price={price}
                promotionPrice={promotionPrice}
                promotionDeadline={promotionDeadline}
                isValidPrice={isValidPrice}
                arrayObjectStock={arrayObjectStock}
                arrayObjectStockOc={arrayObjectStockOc}
                deliverDate={deliverDate}
                maker={maker}
                currencyPrice={currencyPrice}
                promotionalMessage={promotionalMessage}
                arraySubstituteProducts={arraySubstituteProducts}
                updateCartCount={updateCartCount}
                hasNotStock={hasNotStock}
                configurations={configurations}
                isVirtual={isVirtual}
                handleChangeConfig={handleChangeConfig}
                promotion={promotion}
                isAvailableRedhat={isAvailableRedhat}
                descriptionBySku={descriptionBySku}
                steppedPriceHp={steppedPriceHp}
              />
            ) : (
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="short" />
                </Placeholder.Paragraph>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                </Placeholder.Paragraph>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                </Placeholder.Paragraph>
              </Placeholder>

            )}
          </Grid.Column>
          <Grid.Column largeScreen={steppedPriceHp && steppedPriceHp.length > 0 ? 8 : 0} computer={steppedPriceHp && steppedPriceHp.length > 0 ? 8 : 0} tablet={steppedPriceHp && steppedPriceHp.length > 0 ? 8 : 0} mobile={15}>
            {
              (steppedPriceHp && steppedPriceHp.length > 0 && maker === 'HP')
                ? (
                  <TableWholesaleprice
                    steppedPriceHp={steppedPriceHp}
                    currencyPrice={currencyPrice}
                    handleGetHpMapId={(hpId, errors) => this.handleGetHpMapId(hpId, errors)}
                    handleGetsteppedPriceSelection={steppedPriceHpCheck => this.handleGetsteppedPriceSelection(steppedPriceHpCheck)}
                  />
                )
                : ''
            }
            {
              (steppedPriceHp && steppedPriceHp.length > 0 && maker === 'Asus')
                ? (
                  (
                    <TableWholesalepriceAsus
                      steppedPriceHp={steppedPriceHp}
                      currencyPrice={currencyPrice}
                    />
                  )
                )
                : ''
            }
          </Grid.Column>
        </Grid.Row>
        {maker === 'Red Hat' && variants.length > 0 && (
          <Grid.Row centered className="no-padding-top">
            <Grid.Column largeScreen={steppedPriceHp && steppedPriceHp.length > 0 ? 8 : 16} computer={steppedPriceHp && steppedPriceHp.length > 0 ? 8 : 16} tablet={steppedPriceHp && steppedPriceHp.length > 0 ? 8 : 16} mobile={16}>
              <Form>
                <Form.Field>
                  <label>Elige el tipo de esquema</label>
                  <Dropdown
                    placeholder="Esquema"
                    selection
                    onChange={(e, { value }) => this.handleChangeItem(value, true)}
                    options={variants.map(variant => ({
                      key: variant.sku,
                      value: variant.sku,
                      text: variant.name,
                    }))}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        )}
        {(maker === 'Aruba') && arrayRequiredProducts && arrayRequiredProducts.length > 0 && category
          ? (
            <Grid.Row>
              <Grid.Column className="no-padding-right" mobile={1} tablet={1} computer={1} widescreen={1}>
                <Checkbox checked={hasRequiredProduct} style={{ paddingLeft: '1rem' }} />
              </Grid.Column>
              <Grid.Column mobile={14} tablet={14} computer={14} widescreen={14}>
                <Header size="tiny">
                  {t(`Añadir ${category}`)}
                  :
                  {' '}
                </Header>
              </Grid.Column>
            </Grid.Row>
          )
          : ''}
        {(maker === 'Aruba') && arrayRequiredProducts && arrayRequiredProducts.length > 0 && !loadingVmware
          ? (
            <Grid.Row>
              <Grid.Column className="no-padding-right" mobile={14} tablet={14} computer={14} widescreen={14}>
                <Dropdown
                  placeholder={`Seleccionar ${category}`}
                  fluid
                  upward
                  direction="top"
                  selection
                  clearable={maker === 'Aruba' && !isArubaSupport}
                  options={arrayRequiredProducts}
                  onChange={this.handleChangeRequiredId}
                  value={requiredId}
                />
              </Grid.Column>
            </Grid.Row>
          )
          : ''}
        {configurations.Color && configurations.Color.length > 0
          ? (
            <Grid.Row>
              {configurations.Color.map(item => (
                <Grid.Column largeScreen={1} computer={2} tablet={2} mobile={3}>
                  <Button
                    circular
                    icon={item.checked ? 'check' : 'null'}
                    style={{
                      marginBottom: '5px', backgroundColor: item.value, borderStyle: 'solid', borderWidth: '1px', borderColor: 'black',
                    }}
                    color={(item.value.substring(0, 4) === '#FFFF' || item.value === '#FCFCFC') ? 'white' : null}
                    onClick={() => handleChangeConfig(item)}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          )
          : null}
        {Number(isVirtual) === 1 && configurations.length === 0
          ? (
            <Grid.Row>
              {placeHolderItems.map(() => (
                <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={3}>
                  <Placeholder fluid>
                    <Placeholder.Header image>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Grid.Column>
              ))}
            </Grid.Row>
          ) : null}
        {configurations.Texto && configurations.Texto.length > 0
          ? (
            <Grid.Row>
              {configurations.Texto.map(item => (
                <Grid.Column largeScreen={4} computer={2} tablet={4} mobile={8}>
                  <Button
                    basic
                    onClick={() => handleChangeConfig(item)}
                    style={{ marginBottom: '5px' }}
                  >
                    <Icon name={item.checked ? 'check' : 'null'} />
                    {item.value}
                  </Button>
                </Grid.Column>
              ))}
            </Grid.Row>
          )
          : null}
        {configurations.Imagen && configurations.Imagen.length > 0
          ? (
            <Grid.Row>
              {configurations.Imagen.map(item => (
                <Grid.Column largeScreen={2} computer={4} tablet={3} mobile={8}>
                  <Image
                    style={{
                      border: item.checked ? '1px solid #9b9b9b' : '2px solid #FFF',
                      borderRadius: '4px',
                      padding: '5px',
                      maxHeight: '5em',
                    }}
                    onClick={() => handleChangeConfig(item)}
                    src={item.value}
                    size="tiny"
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          )
          : null}
        {!loadingVmware
          ? (
            <Grid.Row>
              <Grid.Column widescreen={7} largeScreen={7} computer={7} tablet={7} mobile={16}>
                <CustomDropdown
                  loadItems={this.handleWishList}
                  items={wishList}
                  loader={wishListLoader}
                  selectedItem={this.handleSelectedList}
                  addItem={this.handleAddList}
                  isAvailableRedhat={isAvailableRedhat}
                  maker={maker}
                />
              </Grid.Column>
              <Grid.Column width={16} style={{ padding: '1em' }} only="mobile" />
              <Grid.Column widescreen={8} largeScreen={8} computer={7} tablet={7} mobile={16}>
                {
                  isValidPrice
                    ? (
                      <Grid>
                        <Grid.Row className="no-padding-bottom">
                          <Grid.Column width={16}>
                            {product.category === 'Curso' && maker === 'Autodesk' ? (
                              <>
                                <Label style={{ marginBottom: '0.5em' }}>{t('productDetails.numParticipantsCourse')}</Label>
                                <Grid.Column style={{ display: 'flex', alignItems: 'center', borderRadius: '10px' }}>
                                  <Grid.Column style={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '13.5em', border: '1px solid #00549f', borderRadius: '5px', borderColor: 'gray',
                                  }}
                                  >
                                    <Grid.Column style={{ margin: '.5em', borderColor: 'yellow', borderRight: 'none' }}>
                                      <Icon name="user" />
                                      <Input
                                        style={{ marginLeft: '.5em' }}
                                        type="number"
                                        value={quantity}
                                        onChange={this.handleQuantity}
                                        action
                                        focus
                                      >
                                        {quantity}
                                        {' '}
                                        {userText}
                                      </Input>
                                    </Grid.Column>
                                  </Grid.Column>
                                  <Button style={{ marginLeft: '.5em' }} onClick={this.handleIncrement}>+</Button>
                                  <Button onClick={this.handleDecrement}>-</Button>
                                </Grid.Column>
                                <Grid.Column>
                                  <Button
                                    color="blue"
                                    onClick={() => this.addItems()}
                                    loading={adding}
                                    disabled={adding || (!isAvailableRedhat && maker === 'Red Hat') || (maker === 'Red Hat' && variants.length && !itemVariant)}
                                    icon
                                    labelPosition="right"
                                    style={{ marginTop: '0.5em' }}
                                  >
                                    <Icon name="shop" />
                                    {t('productDetails.addToCart')}
                                  </Button>
                                </Grid.Column>
                              </>
                            )
                              : (
                                <Input type="number" value={quantity} onChange={this.handleQuantity} onBlur={this.handleQuantityBlur} action focus>
                                  <input style={{
                                    width: '4em', paddingRight: '0.1em', paddingLeft: '1em', boder: '1px solid #00549f',
                                  }}
                                  />
                                  <Button
                                    color="blue"
                                    onClick={() => this.addItems()}
                                    loading={adding}
                                    disabled={adding || (!isAvailableRedhat && maker === 'Red Hat') || (maker === 'Red Hat' && variants.length && !itemVariant)}
                                    icon
                                    labelPosition="right"
                                  >
                                    <Icon name="shop" />
                                    {t('productDetails.addToCart')}
                                  </Button>
                                </Input>
                              ) }
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    )
                    : null
                }
              </Grid.Column>
            </Grid.Row>
          )
          : null}
      </Grid>
    );
  }
}
DescriptionComponent.defaultProps = {
  t: () => { },
  isAvailableRedhat: false,
  variants: [],
  descriptionBySku: '',
};

DescriptionComponent.propTypes = {
  arrayObjectStock: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  arrayObjectStockOc: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deliverDate: PropTypes.string.isRequired,
  arrayRequiredProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  arraySubstituteProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  configurations: PropTypes.shape({
    Color: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Imagen: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Texto: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    length: PropTypes.number,
  }).isRequired,
  currencyPrice: PropTypes.string.isRequired,
  updateCartCount: PropTypes.func.isRequired,
  handleChangeConfig: PropTypes.func.isRequired,
  hasNotStock: PropTypes.bool.isRequired,
  hasRequiredProduct: PropTypes.bool.isRequired,
  isVirtual: PropTypes.bool.isRequired,
  loadingVmware: PropTypes.bool.isRequired,
  maker: PropTypes.string.isRequired,
  makersPercents: PropTypes.shape({}).isRequired,
  price: PropTypes.number.isRequired,
  product: PropTypes.shape({
    category: PropTypes.string,
    maker: PropTypes.string,
    branch: PropTypes.string,
    minQuantity: PropTypes.number,
  }).isRequired,
  productId: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  promotion: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  promotionDeadline: PropTypes.string.isRequired,
  promotionPrice: PropTypes.number.isRequired,
  promotionalMessage: PropTypes.string.isRequired,
  requiredId: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  steppedPriceHp: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sku2: PropTypes.string.isRequired,
  t: PropTypes.func,
  isAvailableRedhat: PropTypes.bool,
  variants: PropTypes.arrayOf(PropTypes.shape({})),
  onChangeItem: PropTypes.func.isRequired,
  descriptionBySku: PropTypes.string,
};

export default translate('common', { wait: true })(DescriptionComponent);
