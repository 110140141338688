import React, {
  useEffect,
  useReducer,
  useState,
  useContext,
} from 'react';
import {
  Dimmer, Loader, Modal, Button, Input, Form, TextArea, Grid,
} from 'semantic-ui-react';
import validator from 'validator';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import services from './services';
import { isSingleDelivery, formatDeliveryConfig, getBrands } from './special-validation';
import { initialState, reducer } from './state-managment';
import { escapeAccents, analyticsEvent } from '../common/utils';
import Toast from '../common/components/toast';
import * as session from '../common/sessions';
import routes from '../route-names';

import { CartContext } from '../context/cart-context';

import OrderContent from './components/order-content';
import UserOrder from './components/user-order';
import Chat from '../chat';

function eventQuotationGA() {
  analyticsEvent('Comercio', 'Guardar como cotización', 'Guardar como cotización');
}

const ACCESS = '110000110000';

const PAYMENTIDS = [1, 2, 3];

const Loading = ({ disable, message }) => (
  <Dimmer active={!disable} inverted>
    <Loader active>
      {' '}
      {message}
      {' '}
    </Loader>
  </Dimmer>
);

const TextAreaControlled = props => {
  const {
    initialData = '', fieldName, placeholder, handleInputFieldChange,
  } = props;

  const [localData, setData] = useState('');

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const handleBlur = () => {
    handleInputFieldChange(localData, fieldName, true);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column onBlur={handleBlur} largeScreen={16} computer={16} tablet={16} mobile={16}>
          <Form>
            <TextArea
              onChange={(e, { value }) => setData(value)}
              value={localData}
              placeholder={placeholder}
              rows={3}
            />
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const InputControlled = props => {
  const {
    initialData = '', fieldName, placeholder, handleInputFieldChange,
  } = props;

  const [localData, setData] = useState('');

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const handleBlur = () => {
    handleInputFieldChange(localData, fieldName);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column onBlur={handleBlur} largeScreen={16} computer={16} tablet={16} mobile={16}>
          <Input
            placeholder={placeholder}
            fluid
            value={localData}
            onChange={(e, { value }) => setData(value)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

function ShoppingCart(props) {
  const { t, history } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { updateCartCount } = useContext(CartContext);
  const {
    orderId,
    hpeIquoteId,
    actionLoader,
    comment,
    currency,
    stockId,
    discount,
    iva,
    subtotal,
    contactAgentMessage,
    payment,
    courierServiceId,
    cfdi,
    currentBranchOfficeIndex,
    addressId,
    loader,
    endUserId,
    items,
    singleDelivery,
    noDeliveryProducts,
    errorExist,
    shippingCost,
    total,
    itemsError,
    finalUserError,
    quantityError,
    deliveryError,
    paymentError,
    currencyError,
    CFDIError,
    formId,
    needForm,
    formError,
    commentError,
    openNameModal,
    openChatModal,
    openAgentModal,
    saveAsQuote,
    isGoToPayment,
    quotationName,
    purchaseOrder,
    purchaseOrderError,
    allBranchOffices,
    needUsdCurrency,
    CFDIArray,
    attachments,
    branchOffices,
    buyToMe,
    credit,
    creditAvailable,
    customerId,
    endUsers,
    errorCode,
    exchangeRate,
    existingBrands,
    fileSelected,
    hasAgentPrice,
    openAttach,
    openConfirm,
    openMultiBrand,
    pickWarehouse,
    userName,
    steppedPrices,
    chosenDelivery,
    warehouseSelected,
    updateQuantityWarehouse,
    doNotShowWarning,
    initialLoader,
    cartError,
    collectorName,
    collectorNameError,
  } = state;

  const handleModalState = (fieldName, modalState = true) => dispatch({
    type: 'fieldChange',
    payload: { field: fieldName, value: modalState },
  });

  function validateFields(fromQuotation = false, formIdGoToPayment = false) {
    let exist = false;
    const fields = {
      itemsError: '',
      finalUserError: '',
      quantityError: '',
      deliveryError: '',
      paymentError: '',
      currencyError: '',
      CFDIError: '',
      commentError: '',
      purchaseOrderError: '',
      formError: '',
      collectorNameError: '',
    };
    const customersToAddInput = [{ idCustomer: 'G103063' }];
    const foundCustomerToAddInput = customersToAddInput.filter(customer => customer.idCustomer === customerId);
    const collectorData = collectorName === undefined ? '' : collectorName;
    if (!PAYMENTIDS.includes(payment)) {
      exist = true;
      fields.paymentError = t('shoppingCart.errors.noPayment');
    }
    if (!endUserId) {
      exist = true;
      fields.finalUserError = t('shoppingCart.errors.noFinalUser');
    }
    if (!cfdi) {
      exist = true;
      fields.CFDIError = t('shoppingCart.errors.noCFDI');
    }
    if (singleDelivery || fromQuotation) {
      if (fromQuotation) {
        dispatch({
          type: 'fieldChange',
          payload: { field: 'singleDelivery', value: true },
        });
      }
      const existApple = existingBrands.find(brand => brand.name.toLowerCase() === 'apple');

      if (!courierServiceId && !noDeliveryProducts) {
        exist = true;
        fields.deliveryError = t('shoppingCart.errors.noCourier');
      } else if (courierServiceId === 4 && !pickWarehouse && chosenDelivery === 'warehousePickup') {
        exist = true;
        fields.deliveryError = t('shoppingCart.errors.noPickWarehouse');
      } else if (!currentBranchOfficeIndex && chosenDelivery === 'singleDelivery') {
        exist = true;
        fields.deliveryError = t('shoppingCart.errors.noBranchOffice');
      } else if (existApple && !(
        (courierServiceId === 2 && state.UPSinsure)
        || courierServiceId === 5
        || courierServiceId === 4
        || courierServiceId === 1
        || courierServiceId === 24
        || courierServiceId === 25
      )) {
        exist = true;
        fields.deliveryError = t('shoppingCart.errors.appleDelivery');
      }
    }
    if (
      comment.trim().length > 0
      && !validator.isAlphanumeric(escapeAccents(validator.blacklist(comment, ' .,')), 'es-ES')
    ) {
      exist = true;
      fields.commentError = t('shoppingCart.errors.comment');
    }
    if (pickWarehouse && chosenDelivery === 'warehousePickup') {
      if (!validator.isAlpha(escapeAccents(validator.blacklist(collectorData, ' .,')), 'es-ES')) {
        exist = true;
        fields.collectorNameError = t('shoppingCart.errors.collectorNameError');
      }
    }
    if (foundCustomerToAddInput && foundCustomerToAddInput.length > 0) {
      if (!validator.isNumeric(purchaseOrder.trim())) {
        exist = true;
        fields.purchaseOrderError = t('shoppingCart.errors.purchaseOrderCompucad');
      }
    } else if (purchaseOrder.trim().length > 0
      && !validator.isAlphanumeric(escapeAccents(validator.blacklist(purchaseOrder)), 'es-ES')) {
      exist = true;
      fields.purchaseOrderError = t('shoppingCart.errors.purchaseOrder');
    }
    if (needForm && !formId && formIdGoToPayment !== true) {
      exist = true;
      // fields.formError = t('shoppingCart.errors.needForm');
      handleModalState('openMultiBrand');
    }
    return { exist, fields };
  }

  async function getDeliveryConfig(orderItems) {
    try {
      const response = await services.getDeliveryConfig().catch(() => { });
      const noDeliverySkus = orderItems.filter(item => !item.hasDelivery).map(item => item.sku);
      const onlyDeliveryItems = response ? response.items.filter(item => !noDeliverySkus.includes(item.sku)) : orderItems.filter(item => !noDeliverySkus.includes(item.sku));
      const noDeliveryItems = !onlyDeliveryItems.length;
      const { singleDelivery: isSingleD, deliveryConfig, hasAgentPrice: responseHasAgentPrice } = isSingleDelivery(onlyDeliveryItems);
      dispatch({
        type: 'fieldChange',
        payload: { field: 'singleDelivery', value: isSingleD },
      });
      dispatch({
        type: 'fieldChange',
        payload: { field: 'hasAgentPrice', value: responseHasAgentPrice },
      });
      dispatch({
        type: 'fieldChange',
        payload: { field: 'noDeliveryProducts', value: noDeliveryItems },
      });
      if (isSingleD) {
        dispatch({
          type: 'fieldChange',
          payload: { field: 'courierServiceId', value: Number(!deliveryConfig.courierServiceId ? 0 : deliveryConfig.courierServiceId) },
        });
        dispatch({
          type: 'fieldChange',
          payload: {
            field: 'currentBranchOfficeIndex',
            value: !deliveryConfig.branchOfficeId ? currentBranchOfficeIndex : deliveryConfig.branchOfficeId,
          },
        });
        dispatch({
          type: 'fieldChange',
          payload: { field: 'UPSinsure', value: deliveryConfig.UPSinsure },
        });
        dispatch({
          type: 'fieldChange',
          payload: { field: 'pickWarehouse', value: deliveryConfig.pickWarehouse },
        });
        if (deliveryConfig.courierServiceId === 4) {
          dispatch({
            type: 'fieldChange',
            payload: { field: 'chosenDelivery', value: 'warehousePickup' },
          });
          dispatch({
            type: 'fieldChange',
            payload: { field: 'warehouseSelected', value: deliveryConfig.pickWarehouse },
          });
        }
      }
      if (responseHasAgentPrice) {
        dispatch({
          type: 'fieldChange',
          payload: { field: 'chosenDelivery', value: 'multiDelivery' },
        });
      }
    } catch (error) {
      // console.log(error);
    }
  }

  const handleCurrencyChange = value => {
    dispatch({
      type: 'currencyChange',
      payload: { value },
    });
  };

  const validateQuantityLimit = cartItems => {
    const hasQuantityError = cartItems.some(item => (
      !item.outlet
      && ((item.minQuantity > 0 && item.quantity < item.minQuantity)
        || (item.maxQuantity > 0 && item.quantity > item.maxQuantity))
    ));
    dispatch({
      type: 'fieldChange',
      payload: { field: 'cartError', value: hasQuantityError },
    });
  };

  async function getActiveOrder() {
    try {
      await updateCartCount();
      const userOrder = await services.getActiveOrder();
      validateQuantityLimit(userOrder.items);
      const existingBrandsArray = getBrands(userOrder.items);
      const needUsdCurrencyResponse = existingBrandsArray.some(brand => (['Microsoft', 'VEEAM', 'VMware', 'VMWARE', 'Red Hat', 'Trellix'].indexOf(brand.name) !== -1));
      if (needUsdCurrencyResponse || userOrder.hpeQuoteId) handleCurrencyChange('USD');
      const form = await services.getDataFormMakers(userOrder.id)
        .then(response => response.makerFormId)
        .catch(() => '');
      dispatch({
        type: 'fieldChange',
        payload: { field: 'initialLoader', value: false },
      });
      dispatch({
        type: 'orderLoadDone',
        payload: { order: userOrder, loader: false },
      });
      dispatch({
        type: 'fieldChange',
        payload: { field: 'formId', value: form },
      });
      dispatch({
        type: 'fieldChange',
        payload: { field: 'needUsdCurrency', value: needUsdCurrencyResponse },
      });
      getDeliveryConfig(userOrder.items);
      return userOrder;
    } catch (error) {
      dispatch({
        type: 'orderLoadFail',
        payload: { errorCode: error.code, loader: false },
      });
      await updateCartCount();
      return ({});
    }
  }

  async function getSteppedPrices(userOrder) {
    if (!userOrder || !userOrder.items) return;
    const steppedPrice = userOrder.items.reduce((prices, item) => {
      const newPrice = { [item.sku]: item.steppedPrice };
      return ({ ...prices, ...newPrice });
    }, {});
    dispatch({
      type: 'fieldChange',
      payload: { field: 'steppedPrices', value: steppedPrice },
    });
    dispatch({
      type: 'getTotals',
      payload: { steppedPrice },
    });
  }

  async function getExchangeRate() {
    try {
      const response = await services.getExchangeRates();
      dispatch({
        type: 'exchangeRate',
        payload: { exchangeRate: response },
      });
    } catch (error) {
      dispatch({
        type: 'orderLoadFail',
        payload: { errorCode: error.code, loader: false },
      });
    }
  }

  async function validateItemsArubaToDelete(sku, propItems) {
    const { branch, quantity } = propItems.filter(item => item.sku === sku)[0];
    const arubaItemsRelated = await services.getRequiredProductsBySku(encodeURIComponent(sku))
      .catch(() => ({ requiredProducts: [] }));
    const itemsToDelete = [];
    let itemToUpdate = {};
    const isASupport = branch === 'ARUBA-SERVICES' || branch === 'ARUBA-SOFTWARE';
    if (!isASupport) {
      arubaItemsRelated.requiredProducts.forEach(itemRelated => {
        let exist = '';
        const support = propItems.filter(item => item.sku === itemRelated.sku)[0];
        if (support && support.sku) exist = (itemsToDelete.filter(item => item.sku === support.sku)).length > 0;
        if (support && !exist) itemsToDelete.push(support);
      });
    } else {
      const arubaProduct = propItems.filter(item => item.sku === arubaItemsRelated.requiredProducts[0].sku)[0];
      const isSameQuantity = arubaProduct.quantity === quantity;
      if (isSameQuantity) itemsToDelete.push(arubaProduct);
      if (!isSameQuantity) {
        const quantityToUpdate = arubaProduct.quantity - quantity;
        itemToUpdate = { sku: arubaProduct.sku, quantity: quantityToUpdate, outlet: false };
      }
    }
    return ({ itemsToDelete, itemToUpdate });
  }

  async function validateItemsToDelete(sku, propItems) {
    const { category, quantity } = propItems.filter(item => item.sku === sku)[0];
    const vmwareItemsRelated = await services.getRequiredProductsBySku(encodeURIComponent(sku));
    const itemsToDelete = [];
    let itemToUpdate = {};
    const isLicense = category === 'Licenciamiento';
    if (isLicense) {
      vmwareItemsRelated.requiredProducts.forEach(itemRelated => {
        let exist = '';
        const support = propItems.filter(item => item.sku === itemRelated.sku)[0];
        if (support && support.sku) exist = (itemsToDelete.filter(item => item.sku === support.sku)).length > 0;
        if (support && !exist) itemsToDelete.push(support);
      });
    } else {
      const license = propItems.filter(item => item.sku === vmwareItemsRelated.requiredProducts[0].sku)[0];
      const isSameQuantity = license.quantity === quantity;
      if (isSameQuantity) itemsToDelete.push(license);
      if (!isSameQuantity) {
        const quantityToUpdate = license.quantity - quantity;
        itemToUpdate = { sku: license.sku, quantity: quantityToUpdate, outlet: false };
      }
    }
    return ({ itemsToDelete, itemToUpdate });
  }

  async function deleteItems(itemsToDelete, updatedItems) {
    let updatedItemsFiltered = [...updatedItems];
    await Promise.all(itemsToDelete.map(async item => {
      const itemDeleted = await services.deleteShoppingCartProduct(item.sku, false).catch(() => { });
      if (itemDeleted) {
        updatedItemsFiltered = updatedItems.filter(itemToFilter => !(itemToFilter.sku === item.sku && itemToFilter.outlet === false));
        dispatch({
          type: 'deleteSku',
          payload: { sku: item.sku, outlet: false },
        });
      }
    }));
    return updatedItemsFiltered;
  }

  async function updateQuantity(seletedItem, currentItems, isOnBlur = false) {
    const {
      sku, quantity, outlet, mcAfeeQty,
    } = seletedItem;
    const itemsSkus = currentItems.map(item => {
      if (item.sku === sku && item.outlet === outlet) {
        return { ...item, quantity: Number(quantity), mcAfeeQty: Number(mcAfeeQty) };
      }
      return item;
    });
    if (isOnBlur) {
      services.updateProductQuantity(encodeURIComponent(sku), { quantity, outlet, mcAfeeQty })
        .then(() => {
          dispatch({
            type: 'orderItems',
            payload: { itemsSkus },
          });
          dispatch({
            type: 'fieldChange',
            payload: { field: 'actionLoader', value: false },
          });
          updateCartCount();
          Toast(t('shoppingCart.quantityUpdated'), 'success');
        })
        .catch(() => {
          dispatch({
            type: 'fieldChange',
            payload: { field: 'actionLoader', value: false },
          });
        });
    }
  }

  const deleteShoppingCartProduct = async (sku, outlet) => {
    try {
      dispatch({
        type: 'fieldChange',
        payload: { field: 'actionLoader', value: true },
      });
      await services.deleteShoppingCartProduct(encodeURIComponent(sku), outlet);
      let updatedItems = items.filter(item => !(item.sku === sku && item.outlet === outlet));
      const itemFiltered = items.filter(item => item.sku === sku)[0];
      const isVmwareItem = (itemFiltered.maker).toLowerCase().trim() === 'vmware';
      if (isVmwareItem) {
        const { itemsToDelete, itemToUpdate } = await validateItemsToDelete(sku, items);
        if (itemsToDelete && itemsToDelete.length > 0) updatedItems = await deleteItems(itemsToDelete, updatedItems);
        if (itemToUpdate && itemToUpdate.sku) await updateQuantity(itemToUpdate, updatedItems, true);
      }
      const isArubaItem = (itemFiltered.maker).toLowerCase().trim() === 'aruba';
      if (isArubaItem) {
        const { itemsToDelete, itemToUpdate } = await validateItemsArubaToDelete(sku, items);
        if (itemsToDelete && itemsToDelete.length > 0) updatedItems = await deleteItems(itemsToDelete, updatedItems);
        if (itemToUpdate && itemToUpdate.sku) await updateQuantity(itemToUpdate, updatedItems, true);
      }
      const noDeliverySkus = updatedItems
        .filter(item => !item.hasDelivery)
        .map(item => item.sku);
      const onlyDeliveryItems = updatedItems.filter(item => !noDeliverySkus.includes(item.sku));
      const noDeliveryItems = !onlyDeliveryItems.length;
      dispatch({
        type: 'fieldChange',
        payload: { field: 'noDeliveryProducts', value: noDeliveryItems },
      });
      dispatch({
        type: 'deleteSku',
        payload: { sku, outlet },
      });
      dispatch({
        type: 'fieldChange',
        payload: { field: 'actionLoader', value: false },
      });
      await updateCartCount();
      getDeliveryConfig(items);
    } catch (error) {
      dispatch({
        type: 'orderLoadFail',
        payload: { errorCode: error.code, loader: false },
      });
      dispatch({
        type: 'fieldChange',
        payload: { field: 'actionLoader', value: false },
      });
    }
  };

  const deleteShoppingCart = async () => {
    try {
      await services.deleteShoppingCart();
      await updateCartCount().then(() => {
        dispatch({
          type: 'fieldChange',
          payload: {
            field: 'items',
            value: [],
          },
        });
      });
    } catch (error) {
      dispatch({
        type: 'orderLoadFail',
        payload: { errorCode: error.code, loader: false },
      });
    }
  };

  async function updateCart(body, showToast = true) {
    try {
      dispatch({
        type: 'fieldChange',
        payload: { field: 'actionLoader', value: true },
      });
      await services.updateShoppingCart(body);
      if (showToast) Toast(t('shoppingCart.updated'), 'success');
      dispatch({
        type: 'fieldChange',
        payload: { field: 'actionLoader', value: false },
      });
    } catch (error) {
      if (error.code === 1404) {
        dispatch({
          type: 'fieldChange',
          payload: { field: 'actionLoader', value: false },

        });
        Toast(t(`shoppingCart.errors.${error.code}`), 'error');
        dispatch({
          type: 'fieldChange',
          payload: { field: 'endUserId', value: '' },
        });
      }
    }
  }

  async function getEndUsers() {
    try {
      const response = await services.getFinalUser();
      const value = response.endUsers.map(endUser => ({ key: endUser.id, text: endUser.tradingName, value: endUser.id }));
      const stock = value.find(endUser => (endUser.text.toLowerCase() === 'stock'.toLowerCase()));

      dispatch({
        type: 'fieldChange',
        payload: { field: 'stockId', value: stock.value },
      });
      dispatch({
        type: 'fieldChange',
        payload: { field: 'endUsers', value },
      });
      dispatch({
        type: 'fieldChange',
        payload: { field: 'endUsersOriginal', value: response.endUsers },
      });
    } catch (error) {
      dispatch({
        type: 'orderLoadFail',
        payload: { errorCode: error.code, loader: false },
      });
    }
  }

  async function getAvailableCredit() {
    const response = await services.getAvailableCredit()
      .then(result => result)
      .catch(() => false);
    const boolResponse = !!Number(response.availableCredit);
    dispatch({
      type: 'fieldChange',
      payload: { field: 'creditAvailable', value: boolResponse },
    });
    if (!boolResponse) {
      dispatch({
        type: 'paymentChange',
        payload: { value: 3 },
      });
    }
  }

  async function getBranchOfficeAddress() {
    try {
      await services.getBranchOfficeAddress().then(result => {
        const validatedBranchOffices = result.branchOffices.filter(branchOffice => branchOffice.isValidated);
        const hasBranches = validatedBranchOffices.length > 0;
        dispatch({
          type: 'fieldChange',
          payload: { field: 'branchOffices', value: validatedBranchOffices },
        });
        dispatch({
          type: 'fieldChange',
          payload: { field: 'allBranchOffices', value: result.branchOffices },
        });
        if (!hasBranches) {
          dispatch({
            type: 'fieldChange',
            payload: { field: 'singleDelivery', value: false },
          });
        }
        return result;
      });
    } catch (error) {
      dispatch({
        type: 'orderLoadFail',
        payload: { errorCode: error.code, loader: false },
      });
    }
  }

  async function getCFDI() {
    try {
      const response = await services.getCFDI();
      const value = response.cfdi.map(currentCFDI => (
        { key: currentCFDI.id, text: currentCFDI.description, value: currentCFDI.id }
      ));
      dispatch({
        type: 'fieldChange',
        payload: { field: 'CFDIArray', value },
      });
    } catch (error) {
      dispatch({
        type: 'orderLoadFail',
        payload: { errorCode: error.code, loader: false },
      });
    }
  }

  async function addDeliveryConfig(parcel, UPS, currentBranchOfficeId, products, warehouses, pickWarehouseParam) {
    if (hasAgentPrice || !singleDelivery || !parcel || noDeliveryProducts) return;
    if (parcel === 4 && !pickWarehouseParam) return;
    const selectBranchOffice = parcel === 4 ? '' : currentBranchOfficeId;
    const body = {};
    body.deliveryConfig = formatDeliveryConfig(parcel, UPS, selectBranchOffice, products, warehouses, pickWarehouseParam);
    try {
      await services.addDeliveryConfig(body);
    } catch (error) {
      // console.log(error);
    }
  }

  function intialDataLoad() {
    const { access, customerId: id, customerName: name } = session.get();
    dispatch({
      type: 'fieldChange',
      payload: { field: 'customerId', value: (!id ? '' : id) },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'customerName', value: (!name ? '' : name) },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'access', value: access },
    });
    getExchangeRate();
    getEndUsers();
    getAvailableCredit();
    getCFDI();
    getBranchOfficeAddress();
    getActiveOrder()
      .then(getSteppedPrices);
  }

  const cleanErrors = () => {
    const exist = false;
    const fields = {
      itemsError: '',
      finalUserError: '',
      quantityError: '',
      deliveryError: '',
      paymentError: '',
      currencyError: '',
      CFDIError: '',
      commentError: '',
      formError: '',
    };
    dispatch({
      type: 'errors',
      payload: { fields, errorExist: exist },
    });
  };

  async function shoppingCartToQuote(name) {
    const errors = validateFields(true);
    if (errors.exist) {
      dispatch({
        type: 'errors',
        payload: { fields: errors.fields, errorExist: errors.exist },
      });
      return;
    }

    cleanErrors();
    try {
      await services.shoppingCartToQuote({ name });
      eventQuotationGA();
      dispatch({
        type: 'cleanState',
        payload: initialState,
      });
      await updateCartCount();
      intialDataLoad();
      history.push('/cotizaciones');
    } catch (error) {
      dispatch({
        type: 'orderLoadFail',
        payload: { errorCode: error.code, loader: false },
      });
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(intialDataLoad, []);

  const handlePaymentChange = (e, { value }) => {
    dispatch({
      type: 'paymentChange',
      payload: { value },
    });
  };

  const singleFieldAPIUpdate = (field, value, isOnBlur) => {
    const body = {};
    body[field] = value;
    switch (field) {
      case 'endUserId':
        if (value === stockId) {
          dispatch({
            type: 'fieldChange',
            payload: { field: 'buyToMe', value: true },
          });
        }
        updateCart(body);
        analyticsEvent('Compra', 'Usuario final seleccionado', value);
        break;
      case 'cfdi':
        updateCart(body);
        break;
      case 'comment':
        if (isOnBlur) updateCart(body);
        break;
      case 'purchaseOrder':
        if (isOnBlur) updateCart(body);
        break;
      case 'collectorName':
        if (isOnBlur) updateCart(body);
        break;
      default:
        break;
    }
  };

  const handleInputFieldChange = (value, fieldName, isOnBlur = false) => {
    dispatch({
      type: 'fieldChange',
      payload: { field: fieldName, value },
    });
    singleFieldAPIUpdate(fieldName, value, isOnBlur);
  };

  const handleAttachedAdd = value => {
    dispatch({
      type: 'attachedAdd',
      payload: { value },
    });
  };

  const deleteAttachment = async attach => {
    try {
      const breakPoint = process.env.REACT_APP_ENV === 'production' ? 40 : 48;
      const shortUrl = attach.url.slice(breakPoint);
      await services.deleteAWSObject(shortUrl);
      await services.deleteAttachment(orderId, attach.id);
      dispatch({
        type: 'deleteAttach',
        payload: attach,
      });
      const messageSuccess = t('shoppingCart.deleteAttachSuccess');
      Toast(messageSuccess, 'success');
    } catch (error) {
      let messageError;
      if (!error.code) messageError = t('error-codes.default');
      else messageError = error.message;
      handleInputFieldChange(false, 'openConfirm');
      handleInputFieldChange([], 'fileSelected');
      Toast(messageError, 'error');
    }
  };

  const handleWarehouseChange = async warehouseId => {
    const onlyDeliveryItems = items.filter(item => item.hasDelivery);
    const deliveryConfig = onlyDeliveryItems.map(item => {
      const skuConfig = {
        UPSinsure: false,
        branchOfficeId: '',
        courierServiceId: 4,
        pickWarehouse: warehouseId,
        sku: item.sku,
        source: [
          {
            quantity: item.quantity,
            warehouse: warehouseId,
          },
        ],
      };
      return skuConfig;
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'actionLoader', value: true },
    });
    await services.addDeliveryConfig({ deliveryConfig });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'pickWarehouse', value: warehouseId },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'warehouseSelected', value: warehouseId },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'actionLoader', value: false },
    });
  };

  const handleShippingChange = async (shippingConfig, availableCouriers) => {
    dispatch({
      type: 'fieldChange',
      payload: { field: 'actionLoader', value: true },
    });
    const onlyDeliveryItems = items.filter(item => item.hasDelivery);
    await addDeliveryConfig(
      shippingConfig.parcel,
      shippingConfig.UPSinsure,
      shippingConfig.addressId,
      onlyDeliveryItems,
      shippingConfig.origins,
      shippingConfig.warehouseCs,
    );
    dispatch({
      type: 'shippingCost',
      payload: { shippingConfig },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'availableCouriers', value: availableCouriers },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'actionLoader', value: false },
    });
  };

  const handleFormId = id => {
    dispatch({
      type: 'fieldChange',
      payload: { field: 'formId', value: id },
    });
  };

  const handleBoolField = (fieldName, currentValue) => {
    let toDoFieldChange = true;
    switch (fieldName) {
      case 'buyToMe':
        if (!currentValue) {
          if (stockId) {
            dispatch({
              type: 'fieldChange',
              payload: { field: 'endUserId', value: stockId },
            });
            updateCart({ endUserId: stockId });
          } else return;
        }
        break;
      case 'singleDelivery':
        if (noDeliveryProducts || hasAgentPrice) {
          return;
        }
        break;
      case 'chosenDelivery':
        dispatch({
          type: 'fieldChange',
          payload: { field: 'chosenDelivery', value: currentValue },
        });
        break;
      case 'saveAsQuote':
        toDoFieldChange = false;
        dispatch({
          type: 'fieldChange',
          payload: { field: 'saveAsQuote', value: currentValue },
        });
        break;
      case 'isGoToPayment':
        toDoFieldChange = false;
        dispatch({
          type: 'fieldChange',
          payload: { field: 'isGoToPayment', value: currentValue },
        });
        break;
      default:
        break;
    }
    if (toDoFieldChange) {
      dispatch({
        type: 'fieldChange',
        payload: { field: fieldName, value: !currentValue },
      });
    }
  };

  const handleDeliveryChange = delivery => {
    dispatch({
      type: 'fieldChange',
      payload: { field: 'chosenDelivery', value: delivery },
    });
    if (delivery === 'warehousePickup') {
      dispatch({
        type: 'fieldChange',
        payload: { field: 'courierServiceId', value: 4 },
      });
      if (shippingCost > 0) {
        dispatch({
          type: 'fieldChange',
          payload: { field: 'shippingCost', value: 0 },
        });
      }
    }
    if (delivery === 'singleDelivery') {
      dispatch({
        type: 'fieldChange',
        payload: { field: 'courierServiceId', value: 0 },
      });
    }
  };

  const handleWarehouseSelectedChange = warehouse => {
    dispatch({
      type: 'fieldChange',
      payload: { field: 'warehouseSelected', value: warehouse },
    });
    return handleWarehouseChange(warehouse);
  };

  const handleBranchOfficeChange = branchOfficeId => {
    dispatch({
      type: 'fieldChange',
      payload: { field: 'currentBranchOfficeIndex', value: branchOfficeId },
    });
  };

  const handleAddressChange = branchOfficeId => {
    dispatch({
      type: 'fieldChange',
      payload: { field: 'addressId', value: branchOfficeId },
    });
  };

  const handleContactAgent = () => {
    const errors = validateFields(true);
    if (errors.exist) {
      dispatch({
        type: 'errors',
        payload: { fields: errors.fields, errorExist: errors.exist },
      });
      return;
    }
    cleanErrors();
    let messageError = '';
    const body = {
      comment: contactAgentMessage,
      orderId,
    };
    services.contactAgent(body).then(() => {
      // const messageSuccess = t('multi-shipping.sentSuccessfully');
      shoppingCartToQuote(quotationName);
    })
      .catch(error => {
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  };

  const handlegetIquoteToken = async () => {
    try {
      history.push({
        pathname: routes.iquote.route,
        state: { hpeIquoteId },
      });
    } catch (error) {
      dispatch({
        type: 'orderLoadFail',
        payload: { errorCode: error.code, loader: false },
      });
    }
  };

  const handleGoToPayment = async (formIdGoToPayment = false) => {
    const isValid = true;
    // if (hpeIquoteId) {
    //   const resultValidation = await services.validateIquoteCart(hpeIquoteId);
    //   isValid = resultValidation.isValid;
    // }
    if (isValid === true) {
      const errors = validateFields(false, formIdGoToPayment);
      const onlyDeliveryItems = items.filter(item => item.hasDelivery);
      dispatch({
        type: 'fieldChange',
        payload: { field: 'saveAsQuote', value: false },
      });
      dispatch({
        type: 'fieldChange',
        payload: { field: 'isGoToPayment', value: true },
      });
      if (errors.exist) {
        dispatch({
          type: 'errors',
          payload: { fields: errors.fields, errorExist: errors.exist },
        });
        return;
      }
      cleanErrors();
      await updateCart({
        comment,
        cfdi,
        endUserId,
        collectorName,
      }, false);
      if (courierServiceId === 5 && !hasAgentPrice) {
        Toast(t('shoppingCart.errors.contactAgent'), 'warning');
        return;
      }
      await addDeliveryConfig(courierServiceId, state.UPSinsure, addressId, onlyDeliveryItems, state.origins, pickWarehouse);
      analyticsEvent('Compra', 'Envio simple', 'Una dirección');
      const existingBrandsArray = getBrands(items);
      const hasTrellixProducts = existingBrandsArray.some(brand => (['Trellix'].indexOf(brand.name) !== -1));
      history.push((routes.checkout.route).replace(':currency', Number(needUsdCurrency && !hasTrellixProducts)));
    } else {
      handlegetIquoteToken();
    }
  };

  const handleConvertToQuotation = async () => {
    const errors = validateFields();
    dispatch({
      type: 'fieldChange',
      payload: { field: 'saveAsQuote', value: true },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'isGoToPayment', value: false },
    });
    if (errors.exist) {
      dispatch({
        type: 'errors',
        payload: { fields: errors.fields, errorExist: errors.exist },
      });
      return;
    }
    cleanErrors();
    handleModalState('openNameModal', true);
  };

  const handleGetNewAddress = newAddress => {
    dispatch({
      type: 'fieldChange',
      payload: { field: 'branchOffices', value: newAddress },
    });
  };

  const handleGetEndUsers = newUser => {
    dispatch({
      type: 'fieldChange',
      payload: { field: 'endUsers', value: newUser },
    });
  };

  const handleGetSavedForm = async (saved, dataForm) => {
    if (saved) {
      await updateCart({ endUserId: dataForm.endUserId });
      dispatch({
        type: 'fieldChange',
        payload: { field: 'endUserId', value: dataForm.endUserId },
      });
      await getActiveOrder()
        .then(getSteppedPrices);
    }
  };

  if (errorExist) {
    if (itemsError) Toast(itemsError, 'error');
    if (finalUserError) Toast(finalUserError, 'error');
    if (quantityError) Toast(quantityError, 'error');
    if (deliveryError) Toast(deliveryError, 'error');
    if (paymentError) Toast(paymentError, 'error');
    if (currencyError) Toast(currencyError, 'error');
    if (CFDIError) Toast(CFDIError, 'error');
    if (commentError) Toast(commentError, 'error');
    if (formError) Toast(formError, 'error');
    if (purchaseOrderError) Toast(purchaseOrderError, 'error');
    if (collectorNameError) Toast(collectorNameError, 'error');
    cleanErrors();
  }

  if (updateQuantityWarehouse) {
    dispatch({
      type: 'fieldChange',
      payload: { field: 'updateQuantityWarehouse', value: false },
    });
    handleWarehouseSelectedChange(warehouseSelected);
  }

  const ActionButtons = () => (
    <Grid centered>
      <Grid.Row centered>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
          <Button fluid color="blue" onClick={deleteShoppingCart}>{t('shoppingCart.clean')}</Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
          <Button fluid color="blue" onClick={handleConvertToQuotation}>
            {t('shoppingCart.saveQuotation')}
          </Button>
        </Grid.Column>
      </Grid.Row>
      {
        (Number(hpeIquoteId) > 0)
          ? (
            <Grid.Row centered>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                <Button fluid color="blue" onClick={handlegetIquoteToken}>
                  {t('shoppingCart.modifyIquoteCart')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          )
          : null
      }
      <Grid.Row centered>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
          <Button fluid color="green" onClick={handleGoToPayment} disabled={state.access === ACCESS || actionLoader || cartError}>
            {hasAgentPrice ? t('shoppingCart.checkShipment') : t('shoppingCart.continueToPayment')}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  return (
    <OrderContent
      handleGoToPayment={handleGoToPayment}
      handleModalState={handleModalState}
      deleteShoppingCart={deleteShoppingCart}
      ActionButtons={ActionButtons}
      items={items}
      t={t}
      currency={currency}
      loader={loader}
      actionLoader={actionLoader}
      subtotal={subtotal}
      shippingCost={shippingCost}
      iva={iva}
      total={total}
      discount={discount}
      initialLoader={initialLoader}
    >
      {loader
        ? <Loading disable={!loader} message={t('loading')} />
        : (
          <UserOrder
            handleModalState={handleModalState}
            handleBranchOfficeChange={handleBranchOfficeChange}
            handleAddressChange={handleAddressChange}
            handleBoolField={handleBoolField}
            handleInputFieldChange={handleInputFieldChange}
            handleCurrencyChange={handleCurrencyChange}
            handleShippingChange={handleShippingChange}
            handlePaymentChange={handlePaymentChange}
            handleFormId={handleFormId}
            handleGoToPayment={handleGoToPayment}
            handleAttachedAdd={handleAttachedAdd}
            handleAttachedDelete={deleteAttachment}
            deleteShoppingCartProduct={deleteShoppingCartProduct}
            ActionButtons={ActionButtons}
            handleGetNewAddress={handleGetNewAddress}
            handlegetIquoteToken={handlegetIquoteToken}
            dispatch={dispatch}
            updateQuantityWarehouse={updateQuantityWarehouse}
            t={t}
            orderId={orderId}
            hpeIquoteId={hpeIquoteId}
            CFDIArray={CFDIArray}
            addressId={addressId}
            allBranchOffices={allBranchOffices}
            attachments={attachments}
            branchOffices={branchOffices}
            buyToMe={buyToMe}
            cfdi={cfdi}
            comment={comment}
            courierServiceId={courierServiceId}
            credit={credit}
            creditAvailable={creditAvailable}
            currency={currency}
            currentBranchOfficeIndex={currentBranchOfficeIndex}
            customerId={customerId}
            discount={discount}
            endUserId={endUserId}
            endUsers={endUsers}
            handleGetEndUsers={handleGetEndUsers}
            errorCode={errorCode}
            exchangeRate={exchangeRate}
            existingBrands={existingBrands}
            fileSelected={fileSelected}
            hasAgentPrice={hasAgentPrice}
            isGoToPayment={isGoToPayment}
            items={items}
            iva={iva}
            needForm={needForm}
            noDeliveryProducts={noDeliveryProducts}
            openAttach={openAttach}
            openConfirm={openConfirm}
            openMultiBrand={openMultiBrand}
            doNotShowWarning={doNotShowWarning}
            payment={payment}
            pickWarehouse={pickWarehouse}
            saveAsQuote={saveAsQuote}
            shippingCost={shippingCost}
            singleDelivery={singleDelivery}
            subtotal={subtotal}
            total={total}
            userName={userName}
            actionLoader={actionLoader}
            steppedPrices={steppedPrices}
            history={history}
            handleGetSavedForm={(saved, form) => handleGetSavedForm(saved, form)}
            handleDeliveryChange={handleDeliveryChange}
            chosenDelivery={chosenDelivery}
            handleWarehouseSelectedChange={handleWarehouseSelectedChange}
            warehouseSelected={warehouseSelected}
            purchaseOrder={purchaseOrder}
            collectorName={collectorName}
          />
        )}
      <Modal size="mini" open={openNameModal} onClose={() => handleModalState('openNameModal', false)}>
        <Modal.Header>{t('shoppingCart.quotation')}</Modal.Header>
        <Modal.Content>
          <InputControlled
            placeholder={t('shoppingCart.name')}
            value={quotationName}
            handleInputFieldChange={handleInputFieldChange}
            fieldName="quotationName"
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="lightBlack" onClick={() => shoppingCartToQuote(quotationName)}>{t('save')}</Button>
          <Button onClick={() => handleModalState('openNameModal', false)}>{t('back')}</Button>
        </Modal.Actions>
      </Modal>
      <Modal size="fullscreen" open={openChatModal} onClose={() => handleModalState('openChatModal', false)}>
        <Modal.Header>{t('shoppingCart.quotation')}</Modal.Header>
        <Modal.Content>
          <Chat order={orderId} />
        </Modal.Content>
      </Modal>
      <Modal size="mini" open={openAgentModal} onClose={() => handleModalState('openAgentModal', false)}>
        <Modal.Header as="header" color="blue">{t('multi-shipping.contactAgent')}</Modal.Header>
        <Modal.Content>
          <p>{t('multi-shipping.contentMessageAgent')}</p>
          <Form>
            <TextAreaControlled
              placeholder={t('multi-shipping.writeMessage')}
              handleInputFieldChange={handleInputFieldChange}
              fieldName="contactAgentMessage"
            />
          </Form>
          <p style={{ paddingTop: '1rem' }}>Ingrese un nombre para su cotización:</p>
          <InputControlled
            placeholder={t('shoppingCart.name')}
            value={quotationName}
            handleInputFieldChange={handleInputFieldChange}
            fieldName="quotationName"
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            onClick={() => {
              if (!quotationName) {
                Toast('Ingrese un nombre de cotización', 'error');
                return;
              }
              handleContactAgent();
              handleModalState('openAgentModal', false);
            }}
          >
            {t('multi-shipping.send')}
          </Button>
          <Button color="black" onClick={() => handleModalState('openAgentModal', false)}>{t('multi-shipping.cancel')}</Button>
        </Modal.Actions>
      </Modal>
    </OrderContent>
  );
}

Loading.propTypes = {
  message: PropTypes.string.isRequired,
  disable: PropTypes.bool.isRequired,
};

TextAreaControlled.propTypes = {
  fieldName: PropTypes.string.isRequired,
  initialData: PropTypes.string,
  placeholder: PropTypes.string,
  handleInputFieldChange: PropTypes.func.isRequired,
};

TextAreaControlled.defaultProps = {
  initialData: '',
  placeholder: '',
};

InputControlled.propTypes = {
  fieldName: PropTypes.string.isRequired,
  initialData: PropTypes.string,
  placeholder: PropTypes.string,
  handleInputFieldChange: PropTypes.func.isRequired,
};

InputControlled.defaultProps = {
  initialData: '',
  placeholder: '',
};

ShoppingCart.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
};

ShoppingCart.defaultProps = {
};

export default translate('common', { wait: true })(ShoppingCart);
