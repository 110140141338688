import React, { useContext, useState } from 'react';
import { translate } from 'react-i18next';
import {
  Grid,
  Card,
  Image,
  Header,
  Modal,
  Label,
  Button,
  Popup,
  Message,
  List,
  Accordion,
  Icon,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Availability from './availability';
import ProductDetail from '../../product-details';

import { CartContext } from '../../context/cart-context';

import { currencyFormat, controlStringLength } from '../../common/utils';
import Toast from '../../common/components/toast';
import products from '../services';

const fontAvailability = {
  cursor: 'pointer',
  display: 'inline',
  fontSize: '13px',
  fontWeight: 'bold',
};

const fontSizePromotion = {
  fontSize: '16px',
  textAlign: 'center',
  marginBottom: '1rem',
};

const fontSizePrice = {
  fontSize: '18px',
  textAlign: 'center',
  fontWeight: 'bold',
};

const DEFAULT_ITEM_QUANTITY = 1;

function getSizeClass(size) {
  if (size > 120) return 'fontSizeTooLongTitle';
  if (size > 90) return 'fontSizeLongTitle';
  return 'fontSizeTitle';
}

function defineBrandLogoSize(brand) {
  return brand === 'HP'
    ? 'mini'
    : 'tiny';
}

function ProductCardGrid(props) {
  const {
    cardSelected,
    product,
    makersPercents,
    isAvailableRedhat,
    match,
    t,
  } = props;

  const { updateCartCount } = useContext(CartContext);
  const [activeDetail, setActiveDetail] = useState(false);
  const [adding, setAdding] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleAccordionClick = (e, { index }) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleProductClick = sku => {
    setActiveDetail(true);
    if (cardSelected) {
      cardSelected(sku);
    }
  };

  const handleAvailability = sku => (
    <Availability sku={sku} />
  );

  const getQuantityLimitText = () => {
    if (product.minQuantity > 0 && product.maxQuantity > 0) {
      return t('products.minAndMaxQuantity', { max: product.maxQuantity, min: product.minQuantity });
    }
    if (product.minQuantity > 0) {
      return t('products.minQuantity', { min: product.minQuantity });
    }
    return t('products.maxQuantity', { max: product.maxQuantity });
  };

  const addItems = async outlet => {
    const body = {
      items: [
        {
          sku: decodeURIComponent(product.sku),
          quantity: DEFAULT_ITEM_QUANTITY,
          outlet,
        },
      ],
    };
    setAdding(true);
    await products.addItems(body)
      .then(response => {
        const id = response.orderId;
        if (id.length > 0) {
          Toast(t('productDetails.productInCart'), 'success');
          // analyticsEvent('Comercio', 'Agregar al carrito', decodeURIComponent(product.sku));
          setAdding(false);
        }
      })
      .then(updateCartCount)
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
        setAdding(false);
      });
  };

  const extra = () => {
    const MINPERCENT = makersPercents[product.brand] || 0.01;
    const minPrice = product.price * MINPERCENT;
    const hasOutlet = Boolean(product.outletStock && product.outletPrice);
    const realPromotionPrice = product.promotionPrice;
    const isValidPrice = (product.price.toFixed(2) > 0 && (!realPromotionPrice || (realPromotionPrice > minPrice)));
    return (
      <Grid padded>
        <Grid.Row centered>
          <Grid.Column width={5} floated="left">
            <Image
              src={product.brandLogo}
              alt={product.sku}
              size={defineBrandLogoSize(product.brand)}
            />
          </Grid.Column>
          <Grid.Column width={5} floated="right">
            {hasOutlet && (
              <Label color="red" horizontal>
                {t('products.outlet')}
              </Label>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered className="no-padding" verticalAlign="middle">
          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
            <Image
              as="img"
              className="card-image"
              style={{
                cursor: 'pointer',
                objectFit: 'contain',
                backgroundColor: '#ffffff',
              }}
              onClick={() => handleProductClick(product.sku)}
              src={product.image}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding-bottom">
          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
            <Header
              onClick={() => handleProductClick(product.sku)}
              color="blue"
              textAlign="center"
              className={`card-title-cont fontDecorationTitle ${getSizeClass(product.title.length)}`}
            >
              {controlStringLength(product.title, 38)}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding-bottom">
          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16} textAlign="center">
            <p className="fullBlackText fontSizeText no-margin">
              SKU:
              {' '}
              {product.sku}
            </p>
            {product.sku2 && (
              <p style={{ fontSize: 13 }}>
                SKU2:
                {' '}
                {product.sku2}
              </p>
            )}
          </Grid.Column>
        </Grid.Row>
        {
          isValidPrice
            ? (
              <Grid.Row className="no-padding-bottom">
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
                  <p className="fullBlackText" style={fontSizePromotion}>
                    <b><strike>{!Number(realPromotionPrice) ? '' : currencyFormat(product.price, product.currency)}</strike></b>
                  </p>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
                  <p style={fontSizePrice} className={`${!Number(realPromotionPrice) ? 'fullBlackText' : 'greenText'} `}>
                    <b>
                      {
                        !Number(realPromotionPrice)
                          ? currencyFormat(product.price, product.currency)
                          : currencyFormat(realPromotionPrice, product.currency)
                      }
                    </b>
                  </p>
                </Grid.Column>
              </Grid.Row>
            )
            : (
              <Grid.Row className="no-padding-bottom">
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
                  <p className="fullBlackText" style={fontSizePromotion}>
                    {t('products.invalidPrice')}
                  </p>
                </Grid.Column>
              </Grid.Row>
            )
        }
        {product.promotion && product.promotion.length > 0 && (
          <Grid.Row>
            <Grid.Column textAlign="left">
              <Message compact color="green">
                <Header as="h5">Este producto tiene descuento(s) aplicados:</Header>
                <List>
                  {product.promotion.map(item => (
                    <List.Item
                      icon="tag"
                      content={`Promoción ${item.code} descuento de ${item.quantity} ${item.discountType === 2 ? '%' : 'MXN'}`}
                    />
                  ))}
                </List>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row className="no-padding-bottom">
          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16} textAlign="center" className="no-padding">
            <div style={fontAvailability}>
              {t('products.available')}
              {' '}
              <Popup
                on="click"
                content={handleAvailability(product.sku)}
                trigger={(
                  <Header
                    style={fontAvailability}
                    color="blue"
                    className="titles-news-gothic"
                  >
                    {product.stock || t('products.notAvailable')}
                  </Header>
                )}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
            {(product.brand.toLowerCase() === 'autodesk' && product.category === 'Curso') || (product.brand.toLowerCase() === 'vmware') || product.isVirtual || (product.brand === 'Red Hat' && product.hasVariants) ? (
              <>
                <Button
                  primary
                  size="medium"
                  fluid
                  onClick={() => handleProductClick(product.sku)}
                >
                  {t('Ver más')}
                </Button>
                {(product.minQuantity > 0 || product.maxQuantity > 0) && (
                  <p style={{ fontSize: 12, textAlign: 'center' }}>{`* ${getQuantityLimitText()}`}</p>
                )}
              </>
            ) : (
              <>
                <Button
                  primary
                  size="medium"
                  fluid
                  loading={adding}
                  disabled={adding || (!isAvailableRedhat && product.brand === 'Red Hat')}
                  onClick={() => addItems(false)}
                >
                  {
                    !isAvailableRedhat && product.brand === 'Red Hat'
                      ? t('productDetails.invalidRedHatCustomer')
                      : t('productDetails.addToCart')
                  }
                </Button>
                {(product.minQuantity > 0 || product.maxQuantity > 0) && (
                  <p style={{ fontSize: 12, textAlign: 'center' }}>{`* ${getQuantityLimitText()}`}</p>
                )}
              </>
            )}
          </Grid.Column>
        </Grid.Row>
        {hasOutlet && (
          <Grid.Row className="no-padding-top" textAlign="center">
            <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16} textAlign="center">
              <Accordion styled>
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleAccordionClick}
                >
                  <Icon name="dropdown" color="red" />
                  <p className="redText display-inline">
                    {t('products.outletPrice')}
                  </p>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <Grid padded>
                    <Grid.Row className="no-padding">
                      <Grid.Column width={16} className="no-padding" textAlign="center">
                        <p style={fontSizePrice} className="redText">
                          {currencyFormat(product.outletPrice, product.currency)}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="no-padding-bottom">
                      <Grid.Column width={16} className="no-padding" textAlign="center">
                        <div style={fontAvailability}>
                          {`${t('products.outletAvailable')} `}
                          <Header
                            style={fontAvailability}
                            color="redText"
                            className="titles-news-gothic"
                          >
                            {product.outletStock || t('products.notAvailable')}
                          </Header>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="no-padding-bottom" textAlign="center">
                      <Grid.Column width={16} className="no-padding">
                        <Button
                          color="red"
                          size="medium"
                          fluid
                          basic
                          loading={adding}
                          disabled={adding}
                          onClick={() => addItems(true)}
                        >
                          {t('products.addOutlet')}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Accordion.Content>
              </Accordion>
            </Grid.Column>
          </Grid.Row>
        )}
        <Modal size="fullscreen" open={activeDetail} onClose={() => setActiveDetail(false)} closeIcon>
          <Modal.Header as="header" color="blue">{t('productDetails.title')}</Modal.Header>
          <Modal.Content>
            <ProductDetail
              sku={product.sku}
              sku2={product.sku2}
              t={t}
              match={match}
              updateCartCount={updateCartCount}
              addItems={addItems}
              adding={adding}
              name={product.title}
              price={product.price}
              image={product.image}
              currencyPrice={product.currency}
              promotionPrice={product.promotionPrice}
              maker={product.brand}
              isVirtual={product.isVirtual}
            />
          </Modal.Content>
        </Modal>
      </Grid>
    );
  };

  return (
    <Card
      fluid
      content={extra()}
      className="simple-card"
    />
  );
}

ProductCardGrid.defaultProps = {
  cardSelected: () => { },
  t: () => { },
  isAvailableRedhat: false,
  makersPercents: {},
};

ProductCardGrid.propTypes = {
  cardSelected: PropTypes.func,
  makersPercents: PropTypes.shape({}),
  product: PropTypes.shape({
    currency: PropTypes.string,
    image: PropTypes.string,
    isVirtual: PropTypes.bool,
    brand: PropTypes.string,
    brandLogo: PropTypes.string,
    title: PropTypes.string,
    category: PropTypes.string,
    outletStock: PropTypes.number,
    stock: PropTypes.number,
    price: PropTypes.number,
    promotionPrice: PropTypes.number,
    outletPrice: PropTypes.number,
    sku: PropTypes.string,
    sku2: PropTypes.string,
    minQuantity: PropTypes.number,
    maxQuantity: PropTypes.number,
    promotion: PropTypes.arrayOf(PropTypes.shape({})),
    hasVariants: PropTypes.bool,
  }).isRequired,
  t: PropTypes.func,
  isAvailableRedhat: PropTypes.bool,
};

export default translate('common', { wait: true })(ProductCardGrid);
